<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 795.07 795.04">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path [attr.fill]="'var(--ion-color-secondary-v1)'"
            d="M795.07,495.93V790.54H614.36v-59.8C541.86,778,452.8,802.32,357.84,793.12,176.22,775.45,27.35,632.29,3.61,451.4-5,386.25,2.13,323.32,22.19,266.46c14.54,194.5,177,347.79,375.4,347.79A215.51,215.51,0,0,0,517.2,578.18H397.59V397.48H696.44A98.6,98.6,0,0,1,795.07,495.93Z"/>
      <path [attr.fill]="varColour ? 'var(--ion-color-white-contrast-v1)' : 'white'"
            d="M773,266.46a387.59,387.59,0,0,1,15.09,57H625c-14,0-26.32-8.28-32.58-21l-.18-.19a218.43,218.43,0,0,0-11.22-20.24C542.6,221.19,474.88,180.71,397.59,180.71a216.74,216.74,0,0,0-157.7,365.46C127.82,488.94,51.27,372.27,51.27,237.94A353.91,353.91,0,0,1,53.48,198C122.48,79.5,250.56,0,397.59,0,544.44,0,672.7,79.5,741.52,198A391.76,391.76,0,0,1,773,266.46Z"/>
    </g>
  </g>
</svg>
