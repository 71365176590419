import {Component, Input} from '@angular/core';
import {FilterOperations, IFilter, IFilterGroup} from '../../../shared-models/filter-groups/filter-groups';
import {ColDef} from 'ag-grid-community';
import {Store} from '@ngrx/store';
import {
  addFilterAndGroupToSelectedAdvancedFilterGroup,
  addFilterToSelectedAdvancedFilterGroup,
  addGroupToSelectedAdvancedFilterGroup,
  deleteAdvancedFilterGroupGroup,
  setSelectedAdvancedFilterGroupOperator
} from '../../../../features/core/store-shared-filter/shared-filter.actions';

@Component({
  selector: 'app-shared-modal-filters-advanced-group',
  templateUrl: './shared-modal-filters-advanced-group.component.html',
  styleUrls: ['./shared-modal-filters-advanced-group.component.scss'],
})
export class SharedModalFiltersAdvancedGroupComponent {

  colDefs: ColDef[];
  filterGroup: IFilterGroup;
  fgOperator: FilterOperations;

  @Input() predecessorID: number;

  constructor(
    private readonly store: Store
  ) {
  }

  @Input() set setColDef(colDefs: ColDef[]) {
    this.colDefs = colDefs;
  }

  @Input() set setFilterGroups(fgInput: IFilterGroup | IFilter) {
    const filterGroup = fgInput as IFilterGroup;
    if (filterGroup) {
      this.filterGroup = filterGroup;
      this.fgOperator = filterGroup.operator;
      this.sortFilterGroups();
    }
  }

  getObjectHasProperty(obj: IFilter | IFilterGroup, property: string): boolean {
    return obj.hasOwnProperty(property);
  }

  onAddFilterClick(): void {
    if (!this.filterGroup?.filtersAndGroups) {
      this.store.dispatch(addFilterAndGroupToSelectedAdvancedFilterGroup({
        predecessorID: this.predecessorID,
        shouldAddFilter: true
      }));
    } else {
      this.store.dispatch(addFilterToSelectedAdvancedFilterGroup({
        predecessorID: this.predecessorID
      }));
    }
  }

  onAddGroupClick(): void {
    this.store.dispatch(addGroupToSelectedAdvancedFilterGroup({
      predecessorID: this.predecessorID
    }));
  }

  onDeleteItemClick(): void {
    this.store.dispatch(deleteAdvancedFilterGroupGroup({filterGroup: this.filterGroup}));
  }

  onToggleOperator(operator: FilterOperations): void {
    this.filterGroup.operator = operator;
    this.store.dispatch(setSelectedAdvancedFilterGroupOperator({operator, filterGroupId: this.filterGroup.id}))
  }

  trackByFunction(index: number): number {
    return index;
  }

  private sortFilterGroups(): void {
    const filters: IFilter[] = [];
    const groups: IFilterGroup[] = [];
    this.filterGroup?.filtersAndGroups?.forEach((item: IFilter | IFilterGroup) => {
      if (item.hasOwnProperty('operator')) {
        groups.push(item as IFilterGroup);
      } else {
        filters.push(item as IFilter);
      }
    });
    this.filterGroup = {
      id: this.filterGroup.id,
      operator: this.filterGroup.operator,
      filtersAndGroups: [...filters, ...groups]
    };
  }

}
