<app-modal-header title="{{storeName}} Stock History Search" [close]="onClose"></app-modal-header>

<ion-content class="special-no-scroll">
  <div class="search-container">
    <ion-button
      (click)="onSelectQueryType($event)"
      color="light">
      Select Query Type
    </ion-button>
    <ng-container *ngIf="queryType === 'dates'; else email">
      <ion-input
        placeholder="Search Dates..."
        [(ngModel)]="searchedDate"
        (click)="onOpenDatePicker($event)">
        <ion-icon slot="start" name="search-circle-outline"></ion-icon>
      </ion-input>
      <ion-button
        (click)="onSubmit()"
        color="light"
        [disabled]="searchedDate === undefined">
        Search Dates
      </ion-button>
    </ng-container>

    <ng-template #email>
      <ng-container  *ngIf="queryType === 'email'; else code">
        <ion-input placeholder="Search Email Address..." [(ngModel)]="codeHistoryList">
          <ion-icon slot="start" name="search-circle-outline"></ion-icon>
        </ion-input>
        <ion-button
          (click)="onSubmit()"
          [disabled]="codeHistoryList === ''"
          color="light">
          Search Email
        </ion-button>
      </ng-container>
    </ng-template>

    <ng-template #code>
      <ng-container *ngIf="queryType === 'code'">
        <ion-input placeholder="Search Stock Code..." [(ngModel)]="codeHistoryList">
          <ion-icon slot="start" name="search-circle-outline"></ion-icon>
        </ion-input>
        <ion-button
          (click)="onSubmit()"
          [disabled]="codeHistoryList === ''"
          color="light">
          Search Stock
        </ion-button>
      </ng-container>
    </ng-template>
  </div>

  <div class="special-scroller" *ngIf=" Object.keys(apiEvent).length > 0 ; else noHistory">
    <ion-grid>
      <ion-row>
        <ion-col> <h5> Date Of Edit </h5></ion-col>
        <ion-col> <h5> User</h5> </ion-col>
      </ion-row>

      <ng-container *ngFor="let line of apiEvent; let i = index">
        <ion-row class="scrollable-body">
          <ion-col (click)="onExpandedRowID(i)" class="collapsed-row">
            {{ getFormatedDate(line.creationDate) }}
          </ion-col>
          <ion-col>
            <ng-container *ngIf="line.userId === userId.id; else colleague">
              {{userId.userName}}
              <ion-icon
                name="search-outline"
                size="small"
                matTooltip="check stock history"
                class="pointer"
                (click)="onSearchUser(line.userId)" *ngIf="queryType !== 'email'"></ion-icon>
            </ng-container>
            <ng-template #colleague>
              {{colleagues?.users?.[line.userId] ? colleagues.users[line.userId].name : line.userId}}
              <ion-icon
                *ngIf="queryType !=='email'"
                name="search-outline"
                size="small"
                matTooltip="check stock history" style="cursor: pointer"
                (click)="onSearchUser(line.userId)">

              </ion-icon>
            </ng-template>

          </ion-col>
        </ion-row>
        <ion-row *ngIf="expandedRowID === i" class="expanded-details">
          <ion-col size="12">
            <app-stock-updates-viewer
              [apiEvent]="line" [storeId]="storeId"
              [isEmbedded]="true"
              [highlightedCode]="codeHistoryList"
              class="wide"
              (codeUpdated)="onUpdateCodeHistoryList($event)"
              (queryTypeUpdate)="onUpdateQueryType($event)"
            ></app-stock-updates-viewer>
          </ion-col>
        </ion-row>
      </ng-container>
    </ion-grid>
  </div>
  <ng-template #noHistory>
    <ion-grid *ngIf="alreadySearched && !loading; else pleaseSearch">
      <ion-row>
        <ion-col size="12" class="no-history-content">
          <div>
            <ion-icon name="information-circle-outline" size="large"></ion-icon>
            <h3>No History Found</h3>
            <p>
              No matching items were found. This may be because there have been no previous edits.
              Please ensure the details you entered are correct, or try a different search query.
            </p>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-template>

  <ng-template #pleaseSearch id="pleaseSearch">
    <ion-grid *ngIf="!loading; else Loading">
      <ion-row>
        <ion-col size="12">
          <div class="empty-state">
            <ion-icon name="search-outline" size="large"></ion-icon>
            <h3>No Stock Updates Yet</h3>
            <p>
              This modal provides a comprehensive view of all changes made to stock items. Here, you can track every
              edit, including when it was made and by whom. If multiple items were updated simultaneously,
              you'll see those changes grouped together, offering a complete picture of stock movements and adjustments.
            </p>
            <p>
              Start by performing a search.
            </p>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-template>

  <ng-template #Loading id="loading">
    <div>
      <ion-spinner></ion-spinner>
    </div>
  </ng-template>

</ion-content>
