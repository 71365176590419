import {Component, Input, Optional} from '@angular/core';
import {IonRouterOutlet, ModalController, PopoverController} from '@ionic/angular';
import {IUser} from '../../../../shared/shared-models/user-access/user';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {MenuPopupComponent} from '../../../../shared-modules/shared-module/components/menu-popup/menu-popup.component';
import {
  MessagesModalPage
} from '../../../../shared-modules/shared-module/components/messages-modal/messages-modal.page';
import {
  NavigableModalComponent
} from '../../../../shared-modules/shared-module/components/navigable-modal/navigable-modal.component';
import {
  ApplicationSettingsComponent
} from '../../../../shared-modules/shared-module/components/application-settings/application-settings.component';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {

  @Input() title = '';
  @Input() user: IUser;

  icons: typeof Icons = Icons;
  messagesCount = 0;

  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController,
    @Optional() private ionRouterOutlet: IonRouterOutlet,
  ) {
  }

  get getFirstLetter(): string {
    return this.user.userName ? this.getUserName.charAt(0).toUpperCase() : 'M';
  }

  get getProfilePicture(): string {
    return this.user?.pp ? this.user?.pp : null;
  }

  get getUserName(): string {
    return this.user?.userName ? this.user.userName : null;
  }

  @Input() set setUserMessageCount(count: number) {
    this.messagesCount = count;
  }

  async showMessages(): Promise<void> {
    const modal = await this.modalController.create({component: MessagesModalPage});
    await modal.present();
  }

  async onOpenMenuClick(): Promise<void> {
    const pp = [
      this.user?.pp ? 'picture' : 'letter',
      this.getProfilePicture
    ]
    const pc = await this.popoverController.create({
      component: MenuPopupComponent,
      componentProps: {user: this.user, pp,},
      cssClass: 'menu-popup',
      event
    });
    await pc.present();
    // Todo: Look at the deprecated event
  }

  async settings(): Promise<void> {
    const mc = await this.modalController.create({
      presentingElement: this.ionRouterOutlet == null ? await this.modalController.getTop() : this.ionRouterOutlet.nativeEl,
      component: NavigableModalComponent,
      componentProps: {root: ApplicationSettingsComponent},
      cssClass: 'settings-modal',
      backdropDismiss: false
    });
    await mc.present();
  }
}
