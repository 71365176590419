/* ------------------------------------------------------ GOOD ------------------------------------------------------ */

/* --------------------------------------------------- URL STUFF ---------------------------------------------------- */

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const __uriEncodeNotEscaped = {'!': '%21', '*': '%2A', '\'': '%27', '(': '%28', ')': '%29'};
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const __uriEncodeNotEscapedSub = (a: '!' | '*' | '\'' | '(' | ')') => __uriEncodeNotEscaped[a];

// TODO: look into this issue with some characters not being encoded correctly
export const superSafeURLEncode = (code: string): string => encodeURIComponent(code)
  .replace(/[!\*'\(\)]/g, __uriEncodeNotEscapedSub);
export const superSafeURLDecode = (id: string): string => decodeURIComponent(id);

/* ------------------------------------------------------------------------------------------------------------------ */

export const list2oxfordComma = (list: string[]): string => {
  let message = '';

  if (list.length > 2) {
    message += list.slice(0, list.length - 1).reduce((acc, current) => acc + ', ' + current);
    message += ', & ' + list[list.length - 1];
  } else if (list.length === 2) {
    message += list[0] + ' & ' + list[1];
  } else {
    message += list[0];
  }
  return message;
};

export const intersection = (arrays: any[][]) => {
  const [index, short] = arrays.reduce((acc, cur, idx) => acc && acc[1].length < cur.length ? acc : [idx, cur]);
  arrays = arrays.filter((v, i) => i !== index);
  return short.filter((v) => {
    for (const a of arrays) {
      if (!a.includes(v)) { return false; }
    }
    return true;
  });
};

export const leadingZero = (value: string | number, minLength: number = 2): string => {
  value = ''+value;

  for (let i = 0; i < Math.max(0, minLength - (value).length); i++) {
    value = '0' + value;
  }
  return value;
};

/**
 * Remove the lowest d digits from the integer of n. If 12345 is n, and d=2 integerSplit will return [12300, 45].
 * NOTE: n is floored in function. If decimals are required, integerSplit them before using function.
 *
 * @param n - an integer to be split
 * @param d - the number of digits to remove from the integer
 */
export const integerSplit = (n, d) => {
  n = Math.floor(n);
  const tens = Math.pow(10, d);
  const dec = n / tens;
  const f = Math.floor(dec);
  return [f * tens, Math.round((dec - f) * tens)];
};

export const EMAIL_REG = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@/.source +
  /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source)

export const validEmail = (email: string) => EMAIL_REG.test(email);

/* ------------------------------------------------------------------------------------------------------------------ */

const RE_THOUSANDS = /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g;
export const formatThousands = (num: number, decimals: number = 2, sep: string = ' ') =>
   num.toFixed(decimals).replace(RE_THOUSANDS, `$1${sep}`);

const RE_FLOOR_2_2_DEC = new RegExp('^-?\\d+(?:\.\\d{0,' + (2 || -1) + '})?');
export const floor2Dec = (v: number): number => +v.toString().match(RE_FLOOR_2_2_DEC)[0];

/* ------------------------------------------------------ EHM? ------------------------------------------------------ */

// TODO: When new NGP page is done I should remove this garbage
//  maybe move this module to /functions as well.

export const formatDate = (timestamp: string | number): string => {
  if (timestamp) {
    const ts = +timestamp;
    return new Date(ts).toLocaleDateString();
  }
  return null;
};

export const formatPrice = (value: string): string => {
  if (value) {
    const price = +value;

    if ( ! (isNaN(price) || price <= 0)) {
      return price.toFixed(2);
    }
  }
  return null;
};

export const formatReal = (value: string | number, decimals: number = 2): string => {
  if (value) {
    const unit = +value;

    if ( ! (isNaN(unit) || unit <= 0)) {
      return Math.round(unit) - unit !== 0 ? unit.toFixed(decimals) : ''+Math.round(unit);
    }
  }
  return null;
};

export const formatInt = (value: string): string => {
  if (value) {
    const int = + value;

    if (! isNaN(int)) {
      return ''+Math.round(int);
    }
    return null;
  }
};

export const formatDesc = (value: string): string => {
  if (value) {
    return value.toUpperCase();
  }
  return null;
};


/* ------------------------------------------------- API FORMATTING ------------------------------------------------- */

// export const apiProcessing = (updates: { [key: string]: any }, hit?: { [int: string]: any }, item?: StockItem):
//                               { processed: { [int: string]: any }; original: { [int: string]: any } } => {
//
//   if (! (hit || item)) {
//     throw Error('One of "hit", or "item" parameters must be provided.');
//   }
//   const processed = {};
//   const original = {};
//
//   for (const key of Object.keys(updates)) {
//     if (sItemKeyToInt.hasOwnProperty(key)) {
//       original[sItemKeyToInt[key]] = updates[key];
//     } else {
//       window.alert('StockItem value update not recognised. "' + key + '" is not a known dCode of StockItem.');
//     }
//
//     switch (key) {
//       case 'sellPriIncl1':
//         const vatR = hit ? hit[sItemKeyToInt.vatR] : item.vatR;
//
//         if (vatR === undefined) {
//           window.alert('Value required in calculation not in StockItem. \'vatR\'');
//           return null;
//         }
//         const sellPriIncl1 = +updates[key];
//         // delete updates[code][this.k2i[dCode]];
//         processed[sItemKeyToInt.sellPriIncl1] = sellPriIncl1;
//         processed[sItemKeyToInt.sellPriExcl1] = sellPriIncl1 / (vatR === 1 ? 1.15 : 1);
//         original[sItemKeyToInt.sellPriExcl1] = hit ? hit[sItemKeyToInt.sellPriExcl1] : item.sellPriExcl1;
//
//         if (vatR === original[sItemKeyToInt.sellPriExcl1]) {
//           window.alert('Value required in calculation not in StockItem. \'sellPriExcl1\'');
//           return null;
//         }
//         break;
//       default:
//         if (sItemKeyToInt.hasOwnProperty(key)) {
//           processed[sItemKeyToInt[key]] = updates[key];
//         }
//     }
//   }
//   return {processed, original};
// };
