<ion-header>
  <ion-toolbar>
    <div>
      <ion-title>{{ title }}</ion-title>
      <ion-title>
        <ion-icon (click)="close()" size="large" name="close-outline"></ion-icon>
      </ion-title>
    </div>
  </ion-toolbar>
</ion-header>
