<ion-header>
  <ion-toolbar>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <span *ngIf="getUserName" class="button-span">
        <ion-badge *ngIf="messagesCount > 0">{{ messagesCount }}</ion-badge>
        <app-icon-mail (click)="getUserName ? showMessages() : null" class="message-icon"></app-icon-mail>
        <app-icon-settings (click)="getUserName ? settings() : null" class="message-icon"></app-icon-settings>
      </span>
      <div class="separator"></div>
      <div class="user-menu" (click)="getUserName? onOpenMenuClick(): null">
        <div class="menu-custom">
          <div *ngIf="user?.pp; else Logo">
            <img
              [src]="getProfilePicture"
              alt="Profile Picture"
              class="profile-picture">
          </div>
          <ng-template #Logo>
            <app-logo-g [varColour]="true"></app-logo-g>
          </ng-template>
        </div>
        <div class="user-name" *ngIf="getUserName">
          {{ getUserName.length > 10 ? getUserName.substring(0, 10) + '...' : getUserName }}
          <app-icon-chevron-down class="chev-icon"></app-icon-chevron-down>
        </div>
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
