
/**
 * @interface FiltersAndTools
 * An object containing a filters and tools responsible for displaying data on the NGP Report Grid.
 *
 * @member {string} appliedFilters The applied advanced filter group on the grid.
 * @member {boolean} isApplyPriceBandingOn Is the toggle for applied price banding switched on.
 * @member {number} editedCount The amount of edited rows within the grid.
 * @member {boolean} isEditedItemsOn Is the toggle for edited items switched on.
 * @member {number} gpDelta The calculated GP Δ (delta) for the grid.
 * @member {number} gpDifferencePositive The amount of rows that have a positive gp difference within the grid.
 * @member {boolean} isGpDifferencePositiveOn Is the toggle for positive gp differences switched on.
 * @member {number} gpDifferenceNegative The amount of rows that have a negative gp difference within the grid.
 * @member {boolean} isGpDifferenceNegativeOn Is the toggle for negative gp differences switched on.
 * @member {number} newItemCount The amount of rows that are newly grv'ed items within the grid.
 * @member {number} total The total number of items within the grid.
 * @member {number} totalShowing The total number visible of items within the grid.
 * @member {boolean} isToggleAccordionSubHeaderOn Is the toggle for the sub header accordion on.
 *
 */
export interface FiltersAndTools {
  /** @member {string} appliedFilters The applied advanced filter group on the grid. */
  appliedFilters: string;
  /** @member {boolean} isApplyPriceBandingOn Is the toggle for applied price banding switched on. */
  isApplyPriceBandingOn: boolean;
  /** @member {number} editedCount The amount of edited rows within the grid. */
  editedCount: number;
  /** @member {boolean} isEditedItemsOn Is the toggle for edited items switched on. */
  isEditedItemsOn: boolean;
  /** @member {number} gpDelta The calculated GP Δ (delta) for the grid. */
  gpDelta: number;
  /** @member {number} gpDifferencePositive The amount of rows that have a positive gp difference within the grid. */
  gpDifferencePositive: number;
  /** @member {boolean} isGpDifferencePositiveOn Is the toggle for positive gp differences switched on. */
  isGpDifferencePositiveOn: boolean;
  /** @member {number} gpDifferenceNegative The amount of rows that have a negative gp difference within the grid. */
  gpDifferenceNegative: number;
  /** @member {boolean} isGpDifferenceNegativeOn Is the toggle for negative gp differences switched on. */
  isGpDifferenceNegativeOn: boolean;
  /** @member {number} newItemCount The amount of rows that are newly grv'ed items within the grid. */
  newItemCount: number;
  /** @member {number} total The total number of items within the grid. */
  total: number;
  /** @member {number} totalShowing The total number visible of items within the grid. */
  totalShowing: number;
  /** @member {boolean} isToggleAccordionSubHeaderOn Is the toggle for the sub header accordion on. */
  isToggleAccordionSubHeaderOn: boolean;
  /** @member {boolean} isToggleAccordionSubHeaderHidden Is the sub header accordion hidden. */
  isToggleAccordionSubHeaderHidden: boolean;
  /** @member {boolean} showSubmissionPreview Is the toggle for the sub header accordion on. */
  showSubmissionPreview: boolean;
}

export const filtersAndToolsDefault: FiltersAndTools = {
  appliedFilters: 'None',
  isApplyPriceBandingOn: false,
  gpDelta: 0,
  editedCount: 0,
  isEditedItemsOn: false,
  gpDifferencePositive: 0,
  isGpDifferencePositiveOn: true,
  gpDifferenceNegative: 0,
  isGpDifferenceNegativeOn: true,
  newItemCount: 0,
  total: 0,
  totalShowing: 0,
  isToggleAccordionSubHeaderOn: false,
  isToggleAccordionSubHeaderHidden: false,
  showSubmissionPreview: false,
};
