import {Component, Input, OnInit} from '@angular/core';
import {
  DisabledRulesSupp,
  onHoldDecode,
  StoreInfo,
  supplierOnHoldTypes,
} from '../../../../shared-utilities/models-old/datastructures';
import {ModalController, PopoverController} from '@ionic/angular';
import {SelectPopoverComponent} from '../select-popover/select-popover.component';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {
  CollectionStoresSettingsService,
} from '../../../../shared/shared-services/firebase/collection-stores-settings.service';
import {path_stores_storeId_settings_disable_supplier_rules} from '../../../../shared/shared-services/database-paths';

@Component({
  selector: 'app-disable-supplier-settings',
  templateUrl: './disable-supplier-settings.component.html',
  styleUrls: ['./disable-supplier-settings.component.scss'],
})
export class DisableSupplierSettingsComponent implements OnInit {

  @Input() storeId: string;
  @Input() storeInfo: StoreInfo;

  hide: { [what: string]: boolean } = {};
  saving = false;

  settings: DisabledRulesSupp;
  newSettings: DisabledRulesSupp = {};

  readonly onHoldKeys: string[];

  constructor(
    private modalController: ModalController,
    private popoverControl: PopoverController,
    private firebase: FirebaseService,
    private collectionStoresSettingsService: CollectionStoresSettingsService,
  ) {
    this.onHoldKeys = Object.keys(supplierOnHoldTypes).sort((a, b) => +a < +b ? -1 : 1);
  }

  ngOnInit(): void {
    void this.collectionStoresSettingsService.getDocument<DisabledRulesSupp>(path_stores_storeId_settings_disable_supplier_rules(this.storeId)).then((data) => {
      if (data) {
        if (data) {
          for (const i of Object.keys(data)) {
            this.newSettings[i] = {};
            for (const j of Object.keys(data[i])) {
              this.newSettings[i][j] = data[i][j];
            }
          }
        }
        this.settings = data;
      } else {
        this.settings = {};
      }

      if (!this.newSettings) {
        this.newSettings = {onHandZero: {}, onHandNotZero: {}};
      } else {
        if (!this.newSettings.onHandZero) {
          this.newSettings.onHandZero = {};
        }
        if (!this.newSettings.onHandNotZero) {
          this.newSettings.onHandNotZero = {};
        }
      }
    });
  }

  close = () => {
    this.modalController.dismiss().then();
  };

  async select(event, part: string) {
    const value = this.newSettings[part].onHoldCode ?
      onHoldDecode(+this.newSettings[part].onHoldCode, true) : null;
    const pop = await this.popoverControl.create({
      component: SelectPopoverComponent,
      componentProps: {
        title: 'On Hold Types',
        selection: supplierOnHoldTypes,
        order: this.onHoldKeys,
        value,
        multiple: true,
        selectAll: true,
        // selectModButtons: [{
        //   text: 'All but 'Invoices & CN'',
        //   handler: (checks: { [sKey: string]: boolean }) => {
        //
        //     for (const sKey of this.onHoldKeys) {
        //       checks[sKey] = true;
        //     }
        //     checks['1'] = false;
        //     checks['2'] = false;
        //     return checks;
        //   }
        // }]
      }, event,
    });
    pop.onDidDismiss().then(response => {
      const selection: string[] = response.data;

      if (selection) {
        if (selection.length) {
          this.newSettings[part].onHoldCode = selection.reduce((acc, v) => '' + (+acc + +v));
        } else {
          this.newSettings[part].onHoldCode = null;
        }
      }
    });
    await pop.present();
  }

  valid(): boolean {
    const s = this.newSettings;

    if (s.onHandZero) {
      if (s.onHandZero.descPrefix || s.onHandZero.descSuffix || s.onHandZero.onHoldCode) {
        return true;
      }
    }
    if (s.onHandNotZero) {
      if (s.onHandNotZero.descPrefix || s.onHandNotZero.descSuffix || s.onHandNotZero.onHoldCode) {
        return true;
      }
    }
    return false;
  }

  save() {
    if (this.valid()) {
      this.saving = true;
      for (const rule of Object.keys(this.newSettings)) {
        for (const vKey of Object.keys(this.newSettings[rule])) {
          if (!this.newSettings[rule][vKey]) {
            delete this.newSettings[rule][vKey];
          } else if ('onHoldCode' === vKey) {
            this.newSettings[rule][vKey] = +this.newSettings[rule][vKey];
          }
        }

        if (Object.keys(this.newSettings[rule]).length === 0) {
          delete this.newSettings[rule];
        }
      }
      const update = this.newSettings;

      this.collectionStoresSettingsService.setDocument<DisabledRulesSupp>(path_stores_storeId_settings_disable_supplier_rules(this.storeId), update).then((r) => {
        this.saving = false;
      }).catch((err) => {
        window.alert('ERROR:\n' + err.toString());
        this.saving = false;
      });
    }
  }

}
