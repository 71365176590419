import {Component, Input, OnInit} from '@angular/core';
import {
  CellFocusedEvent,
  CellKeyDownEvent,
  ColDef,
  Column,
  ColumnApi,
  ColumnMovedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from 'ag-grid-community';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {
  gridColumnTypes,
} from '../../../../shared-utilities/models-old/ngp-report-grid/defaults/column-types/column-types';
import {MenuUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/menu-utils';
import {HeaderMenuColumnData} from '../../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {
  keyboardBindings,
  KeyboardBindingsAvailable,
} from '../../../../shared-utilities/utils-old/shared-utils-old/key-codes';
import {GridNavigationUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-navigation-utils';
import {GridPreviousEditedCell} from '../../../../shared-utilities/models-old/ngp-report-grid/grid';
import {Store} from '@ngrx/store';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {selectTableNavSettings} from '../../store/shared-grid.selectors';
import {Observable} from 'rxjs';
import {StockItem, TableNavSettings} from '../../../../shared-utilities/models-old/datastructures';
import {IAgGridExportSettings} from '../../models/ag-grid-export-settings';
import {selectNgpReportAtStockID} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {
  updateSingleNGPReportWithStoreId,
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';
import {IStore} from '../../../../shared/shared-models/store/store';
import {INavigationPage} from '../../../../shared-utilities/models-old/page/page';
import {
  getPaginationResultsForStockItems,
  setPageSizeForSharedGrid,
} from '../../../../features/stock-manager/store/stock-manager.actions';
import {IPaginationData} from '../../../../shared/shared-models/pagination/pagination-data';
import {
  selectCurrentPageAndTabObject,
  selectSelectedUserStore,
} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {getTableNavSettings} from '../../store/shared-grid.actions';

@Component({
  selector: 'app-shared-grid',
  templateUrl: './shared-grid.component.html',
  styleUrls: ['./shared-grid.component.scss'],
})
export class SharedGridComponent implements OnInit {

  @Input() isLoading: boolean;

  currentTablePage: number;
  itemsPerPage: number;
  totalPages: number;
  totalItems: number;
  pageSizeOptions: number[];
  currentSearch: string;
  isStockManager: boolean;

  gridApi: GridApi;
  columnApi: ColumnApi;
  rowData: (NGPReport | StockItem)[] = [];
  allData: (NGPReport[] | StockItem[]) = [];
  colDefs: ColDef[] = [];
  colDefsDefaults: ColDef = {};
  gridOptions: GridOptions = {};
  columnTypes = gridColumnTypes;
  tableNavSettings: TableNavSettings = {tabEnd: null, enterEnd: null};
  currentStore: IStore;
  currentPage: INavigationPage;
  originalColDefs: { [key: string]: number } = {};
  isPreview: boolean;
  private onGridReadyCallback: (gridReadyEvent: GridReadyEvent) => void;
  private previousEditedCell: GridPreviousEditedCell;
  private tableNavSettings$: Observable<TableNavSettings>;
  private currentStore$: Observable<IStore>;
  private currentPage$: Observable<{ currentSelectedPage: INavigationPage }>;
  private showAll: boolean;

  constructor(
    private readonly store: Store,
  ) {
  }

  get overlayTemplate(): string {
    let template = '';
    if (this.isLoading) {
      template = '<span class="ag-overlay-no-rows-center"><ion-spinner name="dots"></ion-spinner></span>';
    } else {
      template = '<span class="ag-overlay-no-rows-center">No Items To Display</span>';
    }
    return template;
  }

  @Input() set setColDefs(columns: ColDef[]) {
    this.colDefs = GridUtils.verifyArrayDataExists<ColDef>(columns);
    if (this.gridApi) {
      this.gridApi.setGridOption('columnDefs', [...this.colDefs]);
      this.gridApi.refreshHeader();
    }
  }

  @Input() set setColDefDefaults(columns: ColDef) {
    this.colDefsDefaults = columns;
  }

  @Input() set setRowData(data: (NGPReport[] | StockItem[])) {
    if (this.isNGPReportArray(data)) {
      this.rowData = [...GridUtils.verifyArrayDataExists(data)];
      this.allData = [...GridUtils.verifyArrayDataExists(data)];
    } else {
      this.rowData = [...GridUtils.verifyArrayDataExists(data)];
      this.allData = [...GridUtils.verifyArrayDataExists(data)];
    }
  }

  @Input() set setPaginationData(data: IPaginationData) {
    if (data) {
      this.currentTablePage = data.currentPage;
      this.itemsPerPage = data.pageSize;
      this.totalItems = data.totalItems;
      this.currentSearch = data.searchedValue;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      this.pageSizeOptions = this.gridOptions.paginationPageSizeSelector as number[];
    }
  }

  @Input() set setGridOptions(options: GridOptions) {
    this.gridOptions = {
      ...options,
      onCellKeyDown: (event: CellKeyDownEvent): void => {
        this.onCellKeyDownCustom(event);
      },
      onCellFocused: (event: CellFocusedEvent): void => {
        this.onCellFocusedCustom(event);
      },
      onColumnMoved: (event: ColumnMovedEvent): void => {
        if (event.finished) {
          this.onColumnMoved(event);
        }
      },
    };
  }


  @Input() set setOnGridReady(callback: () => void) {
    this.onGridReadyCallback = callback;
  }

  @Input() set setUpdateColumns(headerMenuData: HeaderMenuColumnData) {
    if (this.colDefs?.length > 0 && this.gridApi && headerMenuData) {
      this.switchMenuAction(headerMenuData);
    }
  }

  @Input() set setExportToCSVSettings(exportSettings: IAgGridExportSettings) {
    if (exportSettings && this.gridApi) {
      const excludedFields = ['isSelected', 'icons'];

      const visibleColKeys = this.gridApi
        .getColumnDefs()
        .filter(
          (colDef: ColDef) =>
            !colDef.hide &&
            colDef.field &&
            !excludedFields.includes(colDef.field),
        )
        .map((colDef: ColDef) => colDef.field);

      if (visibleColKeys.length === 0) {
        console.warn('No visible columns to export.');
        return;
      }

      const updatedExportSettings = {
        ...exportSettings,
        columnKeys: visibleColKeys,
      };

      this.gridApi.exportDataAsCsv(updatedExportSettings);
    }
  }


  @Input() set showAllColDef(show: boolean) {
    this.setFitToGrid(show);
  }

  @Input() set visibleFields(visibleFields: { [p: string]: boolean }) {
    this.setVisibleFields(visibleFields);
  }

  /**
   * A function that is triggered by AG Grid when the grid is ready and loaded.
   *
   * @member {GridReadyEvent} gridReadyEvent The data object the grid returns when it is ready and loaded.
   */
  onGridReady(gridReadyEvent: GridReadyEvent): void {
    this.gridApi = gridReadyEvent.api;
    this.columnApi = gridReadyEvent.columnApi;
    if (this.onGridReadyCallback) {
      this.onGridReadyCallback(gridReadyEvent);
    }
    gridReadyEvent.api.showLoadingOverlay();
    this.getColDefsFromLocalStorage(this.colDefs);
    this.colDefs.forEach((column: ColDef) => {
      this.originalColDefs[column.field] = column.width;
    });
  }

  ngOnInit(): void {
    this.store.dispatch(getTableNavSettings());
    this.tableNavSettings$ = this.store.select(selectTableNavSettings);
    this.tableNavSettings$.subscribe((tableNavSettings: TableNavSettings) => {
      this.tableNavSettings = tableNavSettings;
    });
    this.currentStore$ = this.store.select(selectSelectedUserStore);
    this.currentStore$.subscribe((selectedUserStores: IStore) => {
      this.currentStore = selectedUserStores;
    });

    this.currentPage$ = this.store.select(selectCurrentPageAndTabObject);
    this.currentPage$.subscribe((currentPage: { currentSelectedPage: INavigationPage }) => {
      this.currentPage = currentPage.currentSelectedPage;
      this.isStockManager = this.currentPage.currentPage === 'stock-manager';
      this.isPreview = this.currentPage.currentTab === 'preview';
    });
  }

  onColumnMoved(event: ColumnMovedEvent): void {
    if (event.finished) {
      const updatedOrder = event.api.getAllDisplayedColumns().map((col: Column) => col.getColId());

      const reorderedColDefs = updatedOrder.map((colId: string) =>
        this.colDefs.find((colDef: ColDef) => colDef.field === colId),
      );

      const remainingColDefs = this.colDefs.filter(
        (colDef: ColDef) => !updatedOrder.includes(colDef.field),
      );

      this.colDefs = [...reorderedColDefs, ...remainingColDefs];
      const storageKey = this.isStockManager
        ? 'stock-manager-col-def-order'
        : 'ngp-report-col-def-order';
      localStorage.setItem(storageKey, JSON.stringify(updatedOrder));
      this.gridApi.setGridOption('columnDefs', [...this.colDefs]);
    }
  }

  setFitToGrid(show: boolean): void {
    if (show && this.gridApi) {
      if (Object.keys(this.originalColDefs).length === 0) {
        this.colDefs.forEach((column: ColDef) => {
          this.originalColDefs[column.field] = column.width;
        });
      }
      this.gridApi.sizeColumnsToFit();
      const allColumns = this.gridApi.getColumnDefs();
      if (allColumns) {
        this.colDefs = allColumns.map((column: ColDef) => ({
          ...column,
          width: this.gridApi.getColumn(column.field)?.getActualWidth() || column.width,
        }));
      }
    } else if (this.gridApi) {
      this.colDefs.forEach((column: ColDef) => {
        column.width = this.originalColDefs[column.field];
      });
      this.gridApi.setGridOption('columnDefs', [...this.colDefs]);
    }
    this.showAll = show;
  }

  getColDefsFromLocalStorage(columns: ColDef[]): void {
    const storageKey = this.isStockManager
      ? 'stock-manager-col-def-order'
      : 'ngp-report-col-def-order';
    this.colDefs = GridUtils.getColumnDefsFromLocalStorage(columns, storageKey);
    if (this.gridApi) {
      this.gridApi.setGridOption('columnDefs', [...this.colDefs]);
      this.gridApi.refreshHeader();
    }
  }

  setVisibleFields(visibleFields: { [p: string]: boolean }): void {
    let actionOccurred = false;
    this.getColDefsFromLocalStorage(this.colDefs);
    Object.keys(visibleFields).forEach((key: string) => {
      const hideIndex = this.colDefs.findIndex((c: ColDef) => c.field === key);
      if (hideIndex > -1) {
        this.colDefs[hideIndex] = {...this.colDefs[hideIndex]};
        this.colDefs[hideIndex].hide = !visibleFields[key];
        actionOccurred = true;
      }
    });
    if (actionOccurred && this.gridApi) {
      this.gridApi.setGridOption('columnDefs', this.colDefs);
      this.setFitToGrid(this.showAll);
    }
  }

  nextPage(): void {
    if (this.currentTablePage < this.totalPages) {
      this.currentTablePage++;
      this.loadPage();
    }
  }

  onGoToFirstPageClick(): void {
    this.currentTablePage = 1;
    this.loadPage();
    this.gridApi.paginationGoToFirstPage();
  }

  onGoToLastPageClick(): void {
    this.currentTablePage = this.totalPages;
    this.loadPage();
    this.gridApi.paginationGoToLastPage();
  }

  prevPage(): void {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.loadPage();
      this.gridApi.paginationGoToPreviousPage();
    }
  }

  onPageSizeChange(event: Event): void {
    const newPageSize = (event.target as HTMLSelectElement).value;
    this.itemsPerPage = +newPageSize;
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.currentTablePage = 1;
    this.gridApi.setGridOption('paginationPageSize', this.itemsPerPage);
    this.store.dispatch(setPageSizeForSharedGrid({pageSize: this.itemsPerPage}));
    this.loadPage();
  }

  trackByFunction(index: number): number {
    return index;
  }

  loadPage(): void {
    const currentPageForIndex = this.gridApi.paginationGetCurrentPage();
    this.gridApi.ensureIndexVisible(currentPageForIndex * this.itemsPerPage);
    this.store.dispatch(getPaginationResultsForStockItems({pageNumber: this.currentTablePage}));
  }

  isFirstPageDisabled(): boolean {
    return this.currentTablePage === 1;
  }

  isLastPageDisabled(): boolean {
    return this.currentTablePage === this.totalPages;
  }

  private isNGPReportArray(data: any): data is NGPReport[] {
    return data && data.length > 0 && 'isSelected' in data[0];
  }

  private onCellKeyDownCustom(cellKeyDownEvent: CellKeyDownEvent): void {
    const gridApi: GridApi = cellKeyDownEvent.api;
    switch ((cellKeyDownEvent.event as KeyboardEvent).key) {
      case keyboardBindings[KeyboardBindingsAvailable.enter].code:
        this.handleEnterKeyDown(gridApi, cellKeyDownEvent);
        break;
      case keyboardBindings[KeyboardBindingsAvailable.tab].code:
        this.handleTabKeyDown(gridApi, cellKeyDownEvent);
        break;
      default:
        break;
    }
  }

  private async navigateToNextEnabledCell(
    cellKeyDownEvent: CellKeyDownEvent,
    gridApi: GridApi,
    navigationFn: Function | null,
    defaultNavigationFn: () => void,
  ): Promise<void> {
    const column: Column | string = cellKeyDownEvent.column;
    let rowIndex = cellKeyDownEvent.rowIndex;

    const maxIterations = 50; // Prevent infinite loops
    let iterations = 0;
    let foundNextEnabledCell = false;

    while (iterations < maxIterations) {
      const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);

      if (rowNode && rowIndex !== cellKeyDownEvent.rowIndex && !rowNode.data.disabled) {
        foundNextEnabledCell = true;
        break;
      }
      iterations++;
      rowIndex++;
      if (this.isStockManager && this.itemsPerPage && rowIndex > this.itemsPerPage) {
        rowIndex = 0;
        this.previousEditedCell = navigationFn(gridApi, cellKeyDownEvent, this.previousEditedCell, this.isStockManager, this.store);

      }
      if (this.itemsPerPage && iterations > this.itemsPerPage + 10) {
        return;
      }
      await new Promise(resolve => setTimeout(resolve, 0));
    }

    let isLastRowInPage: boolean;
    if (this.isStockManager) {
      isLastRowInPage = rowIndex > this.itemsPerPage;
    } else {
      isLastRowInPage = rowIndex > (gridApi.paginationGetPageSize() * (gridApi.paginationGetCurrentPage() + 1)) - 1;
    }

    if (foundNextEnabledCell && !isLastRowInPage) {
      gridApi.startEditingCell({
        rowIndex,
        colKey: column.getColId(),
      });


    } else {
      const isLastCellInColumn = GridNavigationUtils.isLastVisibleCellInColumn(gridApi, cellKeyDownEvent);

      if (isLastCellInColumn || isLastRowInPage) {
        if (navigationFn) {
          this.previousEditedCell = navigationFn(gridApi, cellKeyDownEvent, this.previousEditedCell, this.isStockManager, this.store);

        } else {
          this.previousEditedCell = GridNavigationUtils.jumpBackToFirstFieldOnCurrentPage(
            gridApi,
            cellKeyDownEvent,
            this.previousEditedCell,
            this.isStockManager,
          );
        }
      } else {
        defaultNavigationFn();
      }
    }
    if (rowIndex - 1 === (gridApi.paginationGetPageSize() * (gridApi.paginationGetCurrentPage() + 1)) - 1 || isLastRowInPage) {
      this.previousEditedCell.rowIndex--;
      this.previousEditedCell = navigationFn(gridApi, cellKeyDownEvent, this.previousEditedCell, this.isStockManager, this.store);
      gridApi.startEditingCell({
        rowIndex,
        colKey: column.getColId(),
      });
    }

    if (iterations >= maxIterations) {
      console.warn('Reached maximum iterations while navigating to the next enabled cell.');
    }
  }

  private handleEnterKeyDown(gridApi: GridApi, cellKeyDownEvent: CellKeyDownEvent): void {
    const enterEndWithRegex = this.tableNavSettings.enterEnd.replace(/-/g, '_');
    // Define navigation rules based on the enter key behavior
    const navigationHandlers: { [key: string]: Function } = {
      restart_page: GridNavigationUtils.jumpBackToFirstFieldOnCurrentPage,
      restart_column: GridNavigationUtils.restartAtTheTopOfTheColumn,
      next_column: GridNavigationUtils.startTheNextColumn,
      next_page: GridNavigationUtils.goToFirstFieldOnNextPage,
      next_page_same_column: GridNavigationUtils.continueTheColumnOnNextPage,
    };

    if (!this.isStockManager) {
      let mostRecentNgpItem: NGPReport;
      this.store.select(selectNgpReportAtStockID(cellKeyDownEvent.node.data.stockId as string))
        .subscribe((ngp: NGPReport) => {
          mostRecentNgpItem = ngp;
        });
      const updatedNgpItem: NGPReport = {
        ...mostRecentNgpItem, isSelected: true,
      };
      this.store.dispatch(updateSingleNGPReportWithStoreId({
        ngpReport: updatedNgpItem,
        storeId: this.currentStore.storeId,
      }));
    }

    setTimeout(() => {
      const navigationFn = navigationHandlers[enterEndWithRegex] || null;
      if (GridNavigationUtils.isLastVisibleCellInColumn(gridApi, cellKeyDownEvent)) {

      }
      void this.navigateToNextEnabledCell(
        cellKeyDownEvent,
        gridApi,
        navigationFn,
        () => {
          this.previousEditedCell = GridNavigationUtils.gridNavigateToNextRowCell(
            gridApi,
            cellKeyDownEvent,
            this.previousEditedCell,
            this.isStockManager,
            this.store,
          );
        },
      );
    }, 0);
  }

  private handleTabKeyDown(gridApi: GridApi, cellKeyDownEvent: CellKeyDownEvent): void {
    const tabEndWithRegex = this.tableNavSettings.tabEnd.replace(/-/g, '_');
    const navigationHandlers: { [key: string]: Function } = {
      restart_page: GridNavigationUtils.jumpBackToFirstFieldOnCurrentPage,
      next_page: GridNavigationUtils.goToFirstFieldOnNextPage,
    };
    const navigationFn = navigationHandlers[tabEndWithRegex] || null;
    const editingCol = GridNavigationUtils.getEditingColumns(gridApi);
    if (GridNavigationUtils.isLastVisibleCellInColumn(gridApi, cellKeyDownEvent) &&
      editingCol[editingCol.length - 1]['colId'] === cellKeyDownEvent.column.getColId()) {

      if (tabEndWithRegex === 'restart_page') {
        GridNavigationUtils.startEditingCellInFirstEditableRow(gridApi, GridNavigationUtils.firstEnabledRowIndexOnCurrentPage(gridApi));
        return;
      }
      if (tabEndWithRegex === 'next_page') {
        const keyDownCopy = {
          ...cellKeyDownEvent,
          column: Object.assign(Object.create(Object.getPrototypeOf(cellKeyDownEvent.column)), {
            ...cellKeyDownEvent.column,
            colId: editingCol[0]['colId'],
          }),
        };

        void this.navigateToNextEnabledCell(
          keyDownCopy,
          gridApi,
          navigationFn,
          () => {
            this.previousEditedCell = GridNavigationUtils.gridNavigateToNextRowCell(
              gridApi,
              cellKeyDownEvent,
              this.previousEditedCell,
              this.isStockManager,
              this.store,
            );
          },
        );
        return;
      }
      if (tabEndWithRegex === 'restart_row') {
        gridApi.startEditingCell({
          rowIndex: cellKeyDownEvent.rowIndex,
          colKey: editingCol[0]['colId'],
        });
      }

    } else if (editingCol[editingCol.length - 1]['colId'] === cellKeyDownEvent.column.getColId() && tabEndWithRegex === 'restart_row') {
      gridApi.startEditingCell({
        rowIndex: cellKeyDownEvent.rowIndex,
        colKey: editingCol[0]['colId'],
      });
    }
  }

  /**
   * A method to handle custom on cell focused events for the grid.
   *
   * @param cellFocusedEvent An on cell focused triggered event.
   */
  private onCellFocusedCustom(cellFocusedEvent: CellFocusedEvent): void {
    const column: Column | string = cellFocusedEvent.column;
    let rowIndex = cellFocusedEvent.rowIndex;
    if (this.previousEditedCell) {
      const rowIndexMatch = this.previousEditedCell.rowIndex === rowIndex;
      const colIndexMatch = this.previousEditedCell.columnIndex === (column as Column).getColId();
      if (rowIndexMatch && colIndexMatch) {
        return;
      }
    }
  }

  /**
   * A method that is used to perform actions on the custom header menu within the grid.
   *
   * @param menuAction A menu action that is triggered on the column header menu.
   */
  private switchMenuAction(menuAction: HeaderMenuColumnData): void {
    let actionOccurred = false;
    Object.keys(menuAction).forEach((key: string) => {
      const columnData = menuAction[key] as HeaderMenuColumnData;
      switch (columnData?.isEditing) {
        case true:
          const editStartIndex = this.colDefs.findIndex((c: ColDef) => c.field === key);
          if (editStartIndex > -1) {
            this.colDefs[editStartIndex] = MenuUtils.onMenuActionEdit(this.colDefs[editStartIndex], true);
            actionOccurred = true;
          }
          setTimeout(() => {
            this.setFitToGrid(this.showAll);
          });
          break;
        case false:
          const editStopIndex = this.colDefs.findIndex((c) => c.field === key);
          if (editStopIndex > -1) {
            this.colDefs[editStopIndex] = MenuUtils.onMenuActionEdit(this.colDefs[editStopIndex], false);
            actionOccurred = true;
          }
          setTimeout(() => {
            this.setFitToGrid(this.showAll);
          });
          break;
        default:
          break;
      }

      switch (columnData?.sortType) {
        case 'sort-asc':
        case 'sort-desc':
        case 'sort-unsort':
          const sortType = columnData?.sortType.substring(5);
          const sort = sortType === 'asc'
            ? 'asc'
            : sortType === 'desc' ? 'desc' : null;
          this.gridApi.resetColumnState();
          this.gridApi.applyColumnState({
            state: [{colId: key, sort}],
            applyOrder: false,
          });
          break;
        default:
          break;
      }
    });

    if (actionOccurred) {
      this.gridApi.setGridOption('columnDefs', this.colDefs);
    }
  }
}
