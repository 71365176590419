import {Component, Input} from '@angular/core';

/**
 * A component that renders an SVG icon for "Today"
 *
 * @member {string} tooltip ? The tooltip that needs to be displayed on this icon.
 */
@Component({
  selector: 'app-icon-today',
  templateUrl: './icon-today.component.html',
  styleUrls: ['./icon-today.component.scss'],
})
export class IconTodayComponent {

  /**
   * Assign a tooltip that needs to be displayed on this icon as soon as the user hovers on it.
   *
   * @param tooltip ? The tooltip that needs to be displayed on this icon.
   */
  @Input() tooltip?: string;
}
