<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4062.68 795.04">
  <defs>
    <style>.cls-1 {
      fill: var(--ion-color-secondary-v1);
    }

    .cls-2 {
      fill: var(--ion-color-constant-white);
    }</style>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1"
            d="M4045.38,12.88l-120,165.43a142.36,142.36,0,0,1-115.56,59.07H3652.13l102.5-150.16A170.48,170.48,0,0,1,3895.4,12.88Z"/>
      <path class="cls-1"
            d="M795.07,495.93V790.54H614.36v-59.8C541.86,778,452.8,802.32,357.84,793.12,176.22,775.45,27.35,632.29,3.61,451.4-5,386.25,2.13,323.32,22.19,266.46c14.54,194.5,177,347.79,375.4,347.79A215.51,215.51,0,0,0,517.2,578.18H397.59V397.48H696.44A98.6,98.6,0,0,1,795.07,495.93Z"/>
      <path class="cls-2"
            d="M773,266.46a387.59,387.59,0,0,1,15.09,57H625c-14,0-26.32-8.28-32.58-21l-.18-.19a218.43,218.43,0,0,0-11.22-20.24C542.6,221.19,474.88,180.71,397.59,180.71a216.74,216.74,0,0,0-157.7,365.46C127.82,488.94,51.27,372.27,51.27,237.94A353.91,353.91,0,0,1,53.48,198C122.48,79.5,250.56,0,397.59,0,544.44,0,672.7,79.5,741.52,198A391.76,391.76,0,0,1,773,266.46Z"/>
      <path class="cls-2"
            d="M2328.86,744.54v46H2075.47c-187,0-338.6-151.63-338.6-338.78V12.88h70.3A150.08,150.08,0,0,1,1957.33,163V449.37c0,91.83,74.34,166.17,166,166.17h76.55A129,129,0,0,1,2328.86,744.54Z"/>
      <path class="cls-2"
            d="M2914.59,744.54v46h-253.4c-187,0-338.59-151.63-338.59-338.78V12.88h70.3A150.08,150.08,0,0,1,2543.06,163V449.37c0,91.83,74.34,166.17,166,166.17h76.55A129,129,0,0,1,2914.59,744.54Z"/>
      <path class="cls-2"
            d="M3171.11,159.36V790.54h-43.43c-97.71,0-177-79.31-177-177V12.88h74A146.39,146.39,0,0,1,3171.11,159.36Z"/>
      <path class="cls-2"
            d="M4062.68,790.54H3909.76a190.06,190.06,0,0,1-158.44-85.2l-103.6-156.78L3544.3,705.16a189.89,189.89,0,0,1-158.63,85.38H3237.35l283-393.24L3248,12.88h149a189.85,189.85,0,0,1,156.23,81.89l98.82,142.61,121.09,151.27Z"/>
      <path class="cls-2"
            d="M1426.43,111.51l302,679H1597.76a145.13,145.13,0,0,1-134.89-91.46L1439,639.28h-.19L1374,477.34l-87.78-219.71c-4.23-10.49-19.13-10.68-23.37-.19L1173,477.34h1.29L1109,639.28h-.73l-23.93,59.8a145.1,145.1,0,0,1-134.88,91.46H823L1122.8,111.88a166,166,0,0,1,303.63-.37Z"/>
      <rect class="cls-2" x="1014.42" y="474.36" width="460.41" height="171.69"/>
    </g>
  </g>
</svg>
