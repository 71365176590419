<ion-header>
  <ion-toolbar>
    <ion-title [innerHTML]="options.header"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div id="container">
    <div id="subHeader" *ngIf="options.subHeader" [innerHTML]="options.subHeader">
    </div>
    <div id="message" *ngIf="options.message" [innerHTML]="options.message">
    </div>
    <div id="buttons" *ngIf="options.buttons">
      <button *ngFor="let button of buttons" (click)="close(button.roll)">
        {{ button.text }}
      </button>
    </div>
  </div>
</ion-content>
