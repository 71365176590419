import {Component, OnInit} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Icons} from '../../../shared-icons/icons';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-cell-renderer-selected',
  templateUrl: './cell-renderer-selected.component.html',
  styleUrls: ['./cell-renderer-selected.component.scss'],
})
export class CellRendererSelectedComponent implements ICellRendererAngularComp, OnInit {

  value: boolean;
  params: ICellRendererParams;
  colDefField: string;
  colDefFieldType: string;

  readonly icons: typeof Icons = Icons;

  constructor(private readonly store: Store) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
    if (this.params.data.isSelected === true && !this.params.node.isSelected()) {
      this.params.node.setSelected(true);
    }

    if (this.params.node.isSelected() && this.params.data.isSelected === false) {
      this.params.data.isSelected = false;
      this.params.node.setSelected(false);
    }
  }

  ngOnInit(): void {
    this.colDefFieldType = typeof this.params.value;
    this.value = this.value as typeof this.params.value;
  }

  getValue(): string | number | boolean {
    return this.value;
  }

  onCheckBoxClick(): void {
    this.params.node.setSelected(!this.params.node.isSelected());
    this.value = !this.value;
    GridUtils.updateIsSelectedForSingleNgpItem(this.params.data, this.value, this.store);
  }

  refresh(params: ICellRendererParams): boolean {
    return this.params.data.isSelected;
  }

}
