import {ColDef} from 'ag-grid-community';
import {GridUtils} from './grid-utils';
import {StoreHeaderMenuData} from '../../models-old/ngp-report-grid/header-menu-data';

/**
 * @class MenuUtils
 * Utilities for manipulating and displaying the user menu on the Grid.
 *
 * @member onMenuActionEdit This is the action that is triggered when the used selects Edit on the menu.
 */
export class MenuUtils {

  /**
   * This is the action that is triggered when the user selects Edit on the menu.
   *
   * @param colDef The column definition triggering the action.
   * @param isEditing Is the user busy editing the cell or not?
   * @returns The column definition after the action has occurred.
   */
  static onMenuActionEdit(colDef: ColDef, isEditing: boolean): ColDef {
    colDef.editable = isEditing;
    if (isEditing) {
      colDef.type = GridUtils.addColumnTypeCellEdit(colDef);
    } else {
      colDef.type = GridUtils.changeColumnDefinitionType(colDef, 'cellEdit', 'cellNormal');
    }
    return colDef;
  }

  static updateSortTypeForAllColumns(
    headerMenuState: StoreHeaderMenuData,
    colId: string,
    newSortType: any,
    storeId: string
  ): StoreHeaderMenuData {
    const updatedState = { ...headerMenuState };
    updatedState[storeId] = { ...headerMenuState[storeId] };
    updatedState[storeId][colId] = {
      ...updatedState[storeId][colId],
      sortType: newSortType
    };

    for (const key in updatedState[storeId]) {
      if (updatedState[storeId].hasOwnProperty(key)) {
        if (key !== colId && updatedState[storeId]?.[key]?.sortType !== null) {
          updatedState[storeId][key] = {
            ...updatedState[storeId][key],
            sortType: 'sort-unsort'
          };
        }
      }
    }

    return updatedState;
  }


}
