<ion-content>
  <ion-grid *ngIf="settingsFG" [formGroup]="settingsFG">

    <ion-row>
      <ion-col class="text-select">
        <span>When on the last cell of a page and&nbsp;</span><b><i>tab</i></b><span>&nbsp;is pressed:&nbsp;</span>
        <button type="button" [disabled]="saving || disabledOpts.tabEnd" (click)="selectEnd($event, 'tab')">
          <span>
            {{ endSelection[settingsFG.get('tabEnd').value] }}
          </span>
          <app-icon-chevron-down></app-icon-chevron-down>
        </button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="text-select">
        <span>When on the last cell of a column and&nbsp;</span><b><i>enter</i></b><span>&nbsp;is pressed:&nbsp;</span>
        <button type="button" [disabled]="saving || disabledOpts.enterEnd" (click)="selectEnd($event, 'enter')">
          <span>
            {{ endSelection[settingsFG.get('enterEnd').value] }}
          </span>
          <app-icon-chevron-down></app-icon-chevron-down>
        </button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <div class="sexy-modal-main-buttons">
          <button type="button" mat-button [disabled]="saving" (click)="onClose()">Cancel</button>
          <button type="button" mat-button [disabled]="saving" (click)="onSave()">Save Settings</button>
        </div>
      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>
