import {Component} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';

@Component({
  selector: 'app-price-cell-editor',
  templateUrl: './price-cell-editor.component.html',
  styleUrls: ['./price-cell-editor.component.scss']
})
export class PriceCellEditorComponent implements ICellEditorAngularComp {

  value: any;

  private params: ICellEditorParams;

  agInit(params: ICellEditorParams): void {
    this.params = params;

    this.value = this.params.value;
  }

  getValue(): number | string {
    this.params.value = this.value;
    return this.value;
  }

  onValueChanged(newValue: number): void {
    this.value = newValue;
    this.params.value = newValue;
    this.params.data.new = newValue;
  }

  onCustomButtonClick(): void {
    this.value = this.params.data.original;
    this.params.value = this.params.data.original;
    this.params.data.new = this.params.data.original;
    this.params.api.stopEditing();
  }
}
