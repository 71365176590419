<ng-container *ngIf="!isEmbedded; else contentTemplate">
  <app-modal-header title="Stock Update Viewer" [close]="close"></app-modal-header>
  <ion-content class="special-no-scroll">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    <div class="count">{{ stockIds.length }} Item{{ stockIds.length > 1 ? 's' : '' }} Changed</div>
  </ion-content>
</ng-container>

<ng-template #contentTemplate>
  <div class="options">
    <span class="checkboxes">
      <label>
        <input type="checkbox" [(ngModel)]="showOriginal" (change)="changeSpan()"> Original
      </label>
      <label>
        <input type="checkbox" [(ngModel)]="showNew" (change)="changeSpan()"> New
      </label>
    </span>
    <ion-button tabindex="-1" size="small" color="light" (click)="selectFields($event)">
      Visible Fields
    </ion-button>
  </div>
  <div class="table-scroll-container">
    <table class="table">
      <thead>
      <tr>
        <th resizable>Code</th>
        <th *ngFor="let key of visibleFields; let i = index"
            [id]="'col-' + key"
            [class]="'report-' + key"
            class="header-cells"
            draggable="true" resizable
            (dragstart)="tableColumns.dragStart(key, $event)"
            (dragend)="tableColumns.dragEnd($event)"
            (dragover)="$event.preventDefault()"
            (dragenter)="tableColumns.dragEnter($event)"
            (dragleave)="tableColumns.dragLeave($event)"
            (drop)="tableColumns.drop(key, $event)"
        >{{ keyToTitle[key]}}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let sID of stockIds; let i = index">
        <tr *ngIf="stockInfo[sID]">
          <td [rowSpan]="rowNum"
              style="vertical-align: middle;"
              [class]="getRowClass(i)"
              [ngClass]="{'highlighted': isHighlighted(sID)}"
          >{{ stockInfo[sID] ? stockInfo[sID].code : sID }}
          <ion-icon name="search-outline" (click)="queryHistory(sID)" *ngIf="sID"></ion-icon></td>
          <td *ngFor="let key of visibleFields" [class]="getRowClass(i)">
            <div *ngIf="showOriginal">
              {{ getOriginalValue(sID, key) }}
            </div>
          </td>
        </tr>
        <tr *ngIf="showNew" class="item-changes">
          <td *ngFor="let key of visibleFields" [class]="getRowClass(i)">
            {{ apiEvent.data[sID].n[k2i[key]] }}
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>
