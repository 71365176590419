import {createFeatureSelector, createSelector, MemoizedSelector, Selector} from '@ngrx/store';
import {NGPReportState} from './ngp-report.reducer';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {FiltersAndTools} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {StoreHeaderMenuData} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {selectSelectedUserStore} from '../../feature-core/store/core.selectors';
import {IStore} from '../../../shared/shared-models/store/store';
import {
  applyAdvancedFilterOnNGPReports,
  getSelectNGPReportsFiltered,
  selectGetSelectNGPReportsFiltered
} from './ngp-report.selectors.utils';
import {LineColour} from '../../../shared-utilities/models-old/datastructures';
import {IDepartment} from '../../../shared/shared-models/stock/departments';
import {selectAppliedAdvancedFilterGroup} from '../../../features/core/store-shared-filter/shared-filter.selectors';
import {IAdvancedFilterGroup} from '../../../shared/shared-models/filter-groups/filter-groups';

export const selectNGPState = createFeatureSelector<NGPReportState>('ngp-report');

// ====================================================================================================
// Set Filters and Tools
// ====================================================================================================
export const selectFiltersAndTools = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.filtersAndTools,
);

export const selectFiltersAndToolsWithAppliedFilters = createSelector(
  selectNGPState,
  selectAppliedAdvancedFilterGroup,
  (state: NGPReportState, appliedFilterGroup: IAdvancedFilterGroup) => {
    return {
      ...state.filtersAndTools,
      appliedFilters: appliedFilterGroup?.name ? appliedFilterGroup.name : 'None'
    } as FiltersAndTools
  }
);

export const selectFiltersAndToolsProperty = <PropertyReturnType>(
  property: string,
): Selector<object, PropertyReturnType> =>
  createSelector(
    selectFiltersAndTools,
    (filtersAndTools: FiltersAndTools): PropertyReturnType => filtersAndTools[property] as PropertyReturnType,
  );

// ====================================================================================================
// Get NGP Reports
// ====================================================================================================
export const selectNGPReports = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.ngpReports,
);

export const selectIsStockItemsLoading = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.isStockItemsLoading,
);

export const selectNGPReportsFiltered = createSelector(
  selectNGPReports,
  selectFiltersAndTools,
  selectSelectedUserStore,
  selectAppliedAdvancedFilterGroup,
  (
    allNGPReports: { [storeId: string]: { [stockId: string]: NGPReport } },
    filtersAndTools: FiltersAndTools,
    store: IStore,
    appliedFilter: IAdvancedFilterGroup,
  ): NGPReport[] => {
    const ngpReportsForStore = allNGPReports[store.storeId];
    const ngpReportsArray = ngpReportsForStore ? Object.values(ngpReportsForStore) : [];
    const selectedNGPReportsFiltered = getSelectNGPReportsFiltered(ngpReportsArray, filtersAndTools);
    return applyAdvancedFilterOnNGPReports(selectedNGPReportsFiltered, appliedFilter);
  },
);

export const selectNgpReportAtStockID = (
  stockId: string,
): MemoizedSelector<object, NGPReport | undefined> =>
  createSelector(
    selectNGPReports,
    selectSelectedUserStore,
    (
      ngpReports: { [storeId: string]: { [stockId: string]: NGPReport } },
      selectedStore: IStore,
    ): NGPReport | undefined => {
      const reportsForStore = ngpReports[selectedStore.storeId];
      if (reportsForStore) {
        return reportsForStore[stockId];
      }
      return undefined;
    },
  );

// ====================================================================================================
// Set Grid Column Definitions
// ====================================================================================================
export const selectNGPReportGridColDefs = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.ngpReportGridColDefs,
);

// ====================================================================================================
// Get Grid Menu Data
// ====================================================================================================
export const selectNGPReportsMenuData = createSelector(
  selectNGPState,
  (state: NGPReportState) => state ? state.headerMenuData : {},
);

export const selectNGPReportsMenuDataByStoreId = createSelector(
  selectNGPReportsMenuData,
  selectSelectedUserStore,
  (headerMenuData: StoreHeaderMenuData, selectedStore: IStore) => {
    if (!selectedStore?.storeId || !headerMenuData[selectedStore.storeId]) {
      return {} as StoreHeaderMenuData;
    } else {
      return headerMenuData[selectedStore.storeId];
    }
  },
);

// ====================================================================================================
// Get Items Disabling Rules
// ====================================================================================================
export const selectItemDisablingRules = createSelector(
  selectNGPState,
  selectSelectedUserStore,
  (state: NGPReportState, store: IStore) => state.disabledRules[store.storeId],
);

// ====================================================================================================
// Get Sorted and Filtered NGPReport
// ====================================================================================================
export const selectNGPReportsFilteredWithSort = createSelector(
  selectNGPReportsFiltered,
  selectNGPReportsMenuData,
  (ngpReports: NGPReport[], headerMenuData: StoreHeaderMenuData): NGPReport[] => {
    return selectGetSelectNGPReportsFiltered(ngpReports, headerMenuData);
  },
);

// ====================================================================================================
// Get Is Ngp Reports Loading
// ====================================================================================================
export const selectIsNGPReportsLoading = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.isNGPReportsLoading,
);

// ====================================================================================================
// Get Visible Fields For NGP Reports
// ====================================================================================================
export const selectNGPVisibleFields = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.visibleFields,
);
export const selectNGPPreviewColumns = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.previewColumnData
);

// ====================================================================================================
// Get Line Colours For Selected Store
// ====================================================================================================
export const selectLineColours = createSelector(
  selectNGPState,
  selectSelectedUserStore,
  (ngpState: NGPReportState, selectedStore: IStore): LineColour =>
    ngpState.lineColours ? ngpState.lineColours[selectedStore.storeId] : null,
);

// ===================================================================================================
// Get Store Departments
// ===================================================================================================
export const selectIsStoreDepartmentsLoading = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.isDepartmentsLoading,
);

export const selectAllStoreDepartments = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.departments,
);

export const selectDepartmentsForSelectedStore = createSelector(
  selectAllStoreDepartments,
  selectSelectedUserStore,
  (departments: { [key: string]: IDepartment[] }, selectedUserStore: IStore) => {
    if (!selectedUserStore || !departments) {
      return [];
    }
    return departments[selectedUserStore.storeId] || ([] as IDepartment[]);
  },
);

// ===================================================================================================
// Get Store Suppliers
// ===================================================================================================
export const selectAllStoresSuppliers = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.storeSuppliers,
);

export const selectSuppliersByUserSelectedStore = createSelector(
  selectNGPState,
  selectSelectedUserStore,
  (state: NGPReportState, selectedUserStore: IStore) => state.storeSuppliers[selectedUserStore.storeId] || {},
);

// ===================================================================================================
// Get Vat Rate For StoreID
// ===================================================================================================
export const selectAllVatRates = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.vatRates,
);

export const selectVatRateBySelectedStore = createSelector(
  selectNGPState,
  selectSelectedUserStore,
  (state: NGPReportState, selectedUserStore: IStore) => state.vatRates[selectedUserStore.storeId] || {},
);
