import {Injectable} from '@angular/core';
import {AngularFirestore, CollectionReference, DocumentChangeAction} from '@angular/fire/compat/firestore';
import {combineLatest, Observable, of} from 'rxjs';
import {IStore} from '../../shared-models/store/store';
import {
  path_operational_stores_data_auto_ordering_auto_orders_GET,
  path_operational_stores_data_auto_ordering_auto_orders_order_items_GET,
} from '../database-paths';
import {
  IAutoOrderOrderFirestore,
  IAutoOrderOrderItemFirestore,
} from '../../shared-models/auto-ordering/auto-order-order';
import {catchError, map} from 'rxjs/operators';
import {ISupplierEmailsDoc} from '../../shared-models/auto-ordering/auto-order-supplier-email-doc';

@Injectable({
  providedIn: 'root',
})
export class CollectionOperationalAutoOrderingService {

  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  getAutoOrders(store: IStore): Observable<IAutoOrderOrderFirestore[]> {
    return this.angularFirestore
      .collection(path_operational_stores_data_auto_ordering_auto_orders_GET(store.storeId))
      .snapshotChanges()
      .pipe(map((changes) => {
        const autoOrderOrders: IAutoOrderOrderFirestore[] = [];
        changes.forEach((change): void => {
          const doc = change.payload.doc;
          const data = doc.data() as IAutoOrderOrderFirestore;
          // Firestore Timestamp Reference
          const dataGenerate: { seconds: number; nanoseconds: number } = data.generated as unknown as {
            seconds: number;
            nanoseconds: number
          };
          autoOrderOrders.push({
            ...data,
            generated: new Date(dataGenerate.seconds * 1000 + dataGenerate.nanoseconds / 1000000)
          });
        });
        return autoOrderOrders;
      }));
  }

  getAutoOrderOrderItems(store: IStore, orderId: string): Observable<IAutoOrderOrderItemFirestore[]> {
    return this.angularFirestore
      .collection(path_operational_stores_data_auto_ordering_auto_orders_order_items_GET(store.storeId, orderId))
      .snapshotChanges()
      .pipe(
        map((changes: DocumentChangeAction<unknown>[]) => {
          const autoOrderOrders: IAutoOrderOrderItemFirestore[] = [];
          changes.forEach((change): void => {
            const doc = change.payload.doc;
            const data = doc.data() as IAutoOrderOrderItemFirestore;
            autoOrderOrders.push({
              ...data,
              itemId: doc.id,
            });
          });
          return autoOrderOrders;
        }),
      );
  }


  getSupplierEmails(
    storeId: string,
    supplierIds: string[],
  ): Observable<{ [suppId: string]: string[] }> {
    if (supplierIds.length === 0) {
      return of({});
    }
    const validSupplierIds = supplierIds.filter((id: string) => id);
    const queries: Observable<ISupplierEmailsDoc[]>[] = [];
    const collectionPath = `operational/stores_data/${storeId}/data/auto_ordering/supplier_emails/emails`;
    while (validSupplierIds.length) {
      // Firestore 'in' queries support up to 10 elements
      const chunk = validSupplierIds.splice(0, 10);
      const query = this.angularFirestore
        .collection<ISupplierEmailsDoc>(collectionPath, (ref: CollectionReference) =>
          ref.where('__name__', 'in', chunk),
        )
        .valueChanges({idField: 'id'});
      queries.push(query);
    }
    return combineLatest(queries).pipe(
      map((results: ISupplierEmailsDoc[][]) => {
        const allDocs = ([] as ISupplierEmailsDoc[]).concat(...results);
        const supplierEmails: { [suppId: string]: string[] } = {};
        allDocs.forEach((doc: ISupplierEmailsDoc) => {
          supplierEmails[doc.id] = doc.emails || [];
        });
        supplierIds.forEach((id: string) => {
          if (!supplierEmails.hasOwnProperty(id)) {
            supplierEmails[id] = [];
          }
        });
        return supplierEmails;
      }),
      catchError((error) => {
        console.error('Error fetching supplier emails:', error);
        return of({});
      }),
    );
  }

}
