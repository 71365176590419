<app-modal-header title="Application Settings" [close]="close"></app-modal-header>

<ion-content>
  <ion-grid id="component-selection">
    <ion-row *ngFor="let opt of menuOptions">
      <ion-col [class]="opt.disabled || !opt.component ? 'disabled' : ''" (click)="selectSettings(opt)">
        {{ opt.text }}
      </ion-col>
    </ion-row>
  </ion-grid>
<!--  <div *ngIf="!settingsWindow; else settingsTemplate">-->
<!--    <ion-grid id="component-selection">-->
<!--      <ion-row *ngFor="let opt of menuOptions">-->
<!--        <ion-col [class]="opt.disabled || !opt.path ? 'disabled' : ''" (click)="selectSettings(opt)">-->
<!--          {{ opt.text }}-->
<!--        </ion-col>-->
<!--      </ion-row>-->
<!--    </ion-grid>-->
<!--  </div>-->
<!--  <ng-template #settingsTemplate>-->
<!--    <div id="settings-container">-->
<!--      <ion-router-outlet></ion-router-outlet>-->
<!--    </div>-->
<!--  </ng-template>-->
</ion-content>
