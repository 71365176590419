<app-modal-header title="Store Information" [close]="close"></app-modal-header>

<ion-content class="special-no-scroll">

  <input type="file" accept="image/png, image/jpeg" style="display: none;" (change)="selectOverrideLogo($event)" #fileInput>

  <div class="special-scroller">
    <ion-grid [formGroup]="formGroup" *ngIf="formGroup; else loadingTemplate">

      <ion-row class="logos">
        <ion-accordion-group value="logos">
          <ion-accordion value="logos">
            <ion-item slot="header" color="none">
              <ion-label>Store Logo</ion-label>
            </ion-item>
            <div slot="content" class="accordion-content">
              <div class="iq-logo">
                <div class="text-select-no">
                  <label>Logo <ng-container *ngTemplateOutlet="iqSupperTemplate"></ng-container></label>
                </div>
                <img [src]="formGroup.get('logo').value" [alt]="'logo'">
              </div>
              <div class="override-logo">
                <div class="text-select-no">
                  <label>
                    Logo, override
                    <ion-icon name="help-circle-outline" matTooltip="Logo to use in PDF (purchase orders etc.) instead of the one on IQ"></ion-icon>
                  </label>
                </div>
                <img [src]="formGroup.get('logoOverride').value" *ngIf="formGroup.get('logoOverride').value" [alt]="'logoOverride'">
                <div class="buttons">
                  <button mat-icon-button [disabled]="!formGroup.get('logoOverride').value" (click)="deleteOverrideLogo()">
                    <ion-icon name="trash-outline"></ion-icon>
                  </button>
                  <button mat-icon-button (click)="fileInput.click()">
                    <ion-icon name="cloud-upload-outline"></ion-icon>
                  </button>
                </div>
              </div>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-row>

      <ion-row *ngFor="let key of keyOrderNoLogo">
        <ion-col size="4">
          <label>
            {{ keyLabel[key] }}
            <ng-container *ngIf="isIQKey(key)">
              <ng-container *ngTemplateOutlet="iqSupperTemplate"></ng-container>
            </ng-container>
          </label>
        </ion-col>
        <ion-col>
          <ion-input [formControlName]="key"></ion-input>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="bottom-buttons">
    <button mat-raised-button>Cancel</button>
    <button mat-raised-button (click)="save()">Save</button>
  </div>
</ion-content>

<ng-template #iqSupperTemplate>
  <sup class="iq-supper-text" matTooltip="This value is populated by the company details found on IQ">
    IQ
  </sup>
</ng-template>

<ng-template #loadingTemplate>
  <div id="loading-iq-info">
    <ion-spinner></ion-spinner>
    <i>fetching store information loading</i>
  </div>
</ng-template>
