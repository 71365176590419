import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  DocumentData
} from '@angular/fire/compat/firestore';
import {path_shared_store_data_stock_GET} from '../database-paths';
import {generateFirebaseCollectionReference} from '../../shared-utils/firestore/firestore.utils';
import {IFirebaseQuery} from '../../shared-models/firebase/firebase-queries';
import {IStore} from '../../shared-models/store/store';
import {Observable} from 'rxjs';
import {StockItem} from '../../../shared-utilities/models-old/datastructures';
import {map} from 'rxjs/operators';
import {castObjectToType} from '../../shared-utils/object/object.utils';

@Injectable({
  providedIn: 'root',
})
export class CollectionSharedStockService {

  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  // ====================================================================================================
  // Stock
  // ====================================================================================================

  getStockItemsCast(
    store: IStore,
    firebaseQueries: IFirebaseQuery[],
    idField: string
  ): Observable<StockItem[]> {
    return this.getStockItemsCollection(store, firebaseQueries)
      .valueChanges({idField})
      .pipe(
        map((documents: DocumentData[]) => {
          return documents.map((doc: DocumentData) => castObjectToType<StockItem, DocumentData>(doc));
        })
      );
  }

  getStockItemsCollection(store: IStore, firebaseQueries: IFirebaseQuery[]): AngularFirestoreCollection {
    return this.angularFirestore.collection(
      path_shared_store_data_stock_GET(store.storeId),
      (reference: CollectionReference) => {
        return generateFirebaseCollectionReference(firebaseQueries, reference);
      }
    )
  }


}
