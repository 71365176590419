<div>

  <ng-container class="icon-container" *ngIf="additionalData.isErrorIconVisible">
    <ng-container *ngIf="additionalData.error || params.data['isError'] === true">
      <app-display-icon
        [colour]="'red'"
        [border]="false"
        [icon]="icons.bugOutline"
        [tooltip]="additionalData?.itemErrorIconTooltip">
      </app-display-icon>
    </ng-container>
  </ng-container>

  <ng-container class="icon-container" *ngIf="additionalData.isCostMissIconVisible">
    <ng-container *ngIf="additionalData.costMiss">
      <app-display-icon
        [colour]="'yellow'"
        [border]="false"
        [icon]="icons.notEqualVariant"
        [tooltip]="additionalData?.costMissIconTooltip">
      </app-display-icon>
    </ng-container>
  </ng-container>

  <ng-container class="icon-container" *ngIf="additionalData.isItemRecentlyCreatedIconVisible">
    <ng-container *ngIf="params.data?.['created'] >= params.data?.['suppUsedLastDate1'] && !isStockManager">
      <app-display-icon
        [colour]="'green'"
        [border]="false"
        [icon]="icons.newBox"
        [tooltip]="additionalData?.itemRecentlyCreatedIconTooltip">
      </app-display-icon>
    </ng-container>
  </ng-container>

  <ng-container class="icon-container" *ngIf="additionalData.isItemEditedIconVisible">
    <ng-container *ngIf="additionalData.isEdited || params.data['isEdited'] === true">
      <app-display-icon
        [colour]="'yellow'"
        [border]="false"
        [icon]="icons.pencil"
        [tooltip]="additionalData?.itemEditedIconTooltip">
      </app-display-icon>
    </ng-container>
  </ng-container>

    <ng-container *ngIf=" params.data['disabled'] === true">
      <app-display-icon
        [colour]="'red'"
        [border]="false"
        [icon]="icons.lockClosedOutline"
        [tooltip]="'This Item Is Disabled'">
      </app-display-icon>
    </ng-container>

</div>
