<div id="pagination">
  <span>
    <button mat-ripple tabindex="-1" (click)="paginate('backward')"
            [disabled]="pagination.page === 0"
    >
      <ion-icon name="chevron-back-outline"></ion-icon>
    </button>
    <button mat-ripple tabindex="-1" class="double-chevron" (click)="paginate('backward', true)"
            [disabled]="pagination.page === 0"
    >
      <ion-icon name="chevron-back-outline"></ion-icon><ion-icon name="chevron-back-outline"></ion-icon>
    </button>
  </span>

  <span class="results-text">
    <span>Page: {{ page }} of {{ pagination.nbPages }}</span>
    <span>Results: {{ pagination.nbHits }}</span>
  </span>


  <span>
    <button mat-ripple tabindex="-1" class="double-chevron"  (click)="paginate('forward', true)"
            [disabled]="page >= pagination.nbPages"
    >
      <ion-icon name="chevron-forward-outline"></ion-icon><ion-icon name="chevron-forward-outline"></ion-icon>
    </button>
    <button mat-ripple tabindex="-1" (click)="paginate('forward')"
            [disabled]="page >= pagination.nbPages"
    >
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </button>
  </span>
</div>
