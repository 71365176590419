<ion-header *ngIf="options">
    <ion-title>{{ options.header }}</ion-title>
</ion-header>

<ion-content *ngIf="options" class="special-no-scroll">
  <div class="special-scroller">
    <div class="sub-header" *ngIf="options.subHeader">
      {{ options.subHeader }}
    </div>
    <div class="message" *ngIf="options.message" [innerHTML]="options.message"></div>
    <div class="input" *ngFor="let control of emailFormArray.controls; let i = index">
      <label *ngIf="options.inputLabel">{{ options.inputLabel }}:</label>
      <ion-input [formControl]="getEmailFormControl(i)"></ion-input>
    </div>
    <div class="add-n-remove">
      <span>
        <ion-icon name="add-circle-outline" (click)="addEmail()" style="font-size: x-large;"></ion-icon>
      </span>
      <span *ngIf="emailFormArray.length !== 1">
        <ion-icon name="remove-circle-outline" (click)="removeEmail()" style="font-size: x-large;"></ion-icon>
      </span>

    </div>
  </div>
  <div class="buttons">
    <ion-button size="small" *ngIf="!options.required" (click)="cancel()" color="light">{{ options.cancelText }}</ion-button>
    <ion-button size="small" (click)="submit()" [disabled]="emailForm.invalid" color="light">{{ options.submitText }}</ion-button>
  </div>
</ion-content>
