import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {Message, stockCodeDecode, StoreInfo} from '../../../../shared-utilities/models-old/datastructures';
import {AoReattemptModalComponent, ReattemptInput} from '../ao-reattempt-modal/ao-reattempt-modal.component';
import {AoResponse} from '../../../../shared-utilities/services-old/message-service/messages.service';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {copyObj, objLen, updateObj} from '../../../../shared-utilities/functions-old/object-functions';

interface UseValue {
  msgID: string;
  message: Message;
  getStoreInfo: (storeId: string) => StoreInfo;
  timeStamp: string;
  restoreState?: { input?: { [orderId: string]: ReattemptInput }; checked?: { [orderId: string]: boolean } };
}

@Component({
  selector: 'app-msg-ao-response',
  templateUrl: './msg-ao-response.component.html',
  styleUrls: ['./msg-ao-response.component.scss'],
})
export class MsgAoResponseComponent implements OnInit {

  // This value will only be provided when component is embedded
  @Input() embeddedUseValue?: UseValue;
  @Output() onCheck: EventEmitter<{ orderId: string; value: { [orderId: string]: boolean } }> = new EventEmitter();

  ordersOrder: string[] = [];
  responses: { [orderId: string]: AoResponse };
  suppliersClean: { [orderId: string]: string } = {};
  succeeded: { [orderId: string]: boolean } = {};
  failures = 0;
  checked: { [orderId: string]: boolean } = {};
  numChecked = 0;
  isDeleted: { [orderId: string]: boolean } = {};
  imapErrors: { [orderId: string]: boolean } = {};
  // data: { [orderId: string]: any } = {};
  reattemptInput: { [orderId: string]: ReattemptInput };
  creatingModal: boolean;
  storeName: string;

  private compIsEmbedded: boolean;

  constructor(
    @Optional() @Inject('INPUT') protected useValue: UseValue,
    private firebase: FirebaseService,
    private alertControl: AlertController,
    private modalController: ModalController,
  ) {
  }

  get isEmbedded(): boolean {
    return this.compIsEmbedded;
  }

  async ngOnInit() {
    this.compIsEmbedded = !!this.embeddedUseValue;
    if (this.isEmbedded) {
      this.useValue = this.embeddedUseValue;
    }
    this.storeName = this.useValue.getStoreInfo(this.useValue.message.sender).name;

    if (this.useValue?.restoreState?.checked) {
      for (const orderId of Object.keys(this.useValue.restoreState.checked)) {
        this.checked[orderId] = this.useValue.restoreState.checked[orderId];
      }
    }
    if (this.useValue?.restoreState?.input) {
      this.reattemptInput = {};

      for (const orderId of Object.keys(this.useValue.restoreState.input)) {
        this.reattemptInput[orderId] = this.useValue.restoreState.input[orderId];
      }
    }

    this.ordersOrder = Object.keys(this.useValue.message.payload.data);
    this.responses = {};
    const storeId = this.useValue.message.sender;

    for (const orderId of this.ordersOrder) {
      this.firebase.getAutoOrder(storeId, orderId).then((order) => (this.isDeleted[orderId] = order === null));
      this.responses[orderId] = this.useValue.message.payload.data[orderId] as AoResponse;
      this.suppliersClean[orderId] = stockCodeDecode(this.responses[orderId].supplier);

      if (this.responses[orderId].reattempted) {
        this.responses[orderId].reattempted = (this.responses[orderId].reattempted as any).toDate();
      }

      if (!this.responses[orderId].success && this.responses[orderId].msg && !this.responses[orderId].errorObj) {
        try {
          this.responses[orderId].errorObj = JSON.parse(this.responses[orderId].msg);
        } catch (error) {
        }
      }
      this.succeeded[orderId] = this.responses[orderId].success;

      if (!this.succeeded[orderId]) {
        this.failures++;

        if (
          this.responses[orderId].pos === 'AO_EMAIL_FAIL' &&
          this.responses[orderId].msg &&
          this.responses[orderId].msg.toLowerCase().startsWith('imapissueerrorimap error')
        ) {
          this.imapErrors[orderId] = true;
        }
      }
    }
    this.ordersOrder.sort((a, b) => this.responses[a].supplier < this.responses[b].supplier ? 1 : -1);
  }

  checkClicked(orderId: string) {
    this.numChecked += this.checked[orderId] ? 1 : -1;

    if (this.isEmbedded) {
      this.onCheck.emit({orderId, value: this.checked});
    }
  }

  async errorMsg(orderId) {
    let message: string;

    const buttons: any[] = [];

    if (this.responses[orderId].errorObj) {
      const eo = this.responses[orderId].errorObj;
      message = `Error code ${eo.error}: '${eo.desc}'. Number of item errors: ` +
        (eo.itemErrors ? Object.keys(eo.itemErrors).length : 0);
      buttons.push({text: 'Print XML to Console', handler: () => console.warn(this.responses[orderId].msg)});
    } else {
      message = this.responses[orderId].msg ? this.responses[orderId].msg : 'No error message given';
    }
    buttons.push('Ok');
    const ac = await this.alertControl.create({
      header: `Supplier ${stockCodeDecode(this.responses[orderId].supplier)} Error`, cssClass: 'custom-alert', message,
      backdropDismiss: true, buttons,
    });
    await ac.present();
  }

  async imapError() {
    const ac = await this.alertControl.create({
      header: `IMAP Error`, subHeader: 'Don`t worry. The email was delivered', message: 'The email sent successfully ' +
        'and has been received by the supplier. Unfortunately the email failed to copy to your sent box, so you wont ' +
        'see it there. This could have been a one time issue. If the issue happens again please contact us, your I' +
        'MAP settings may be incorrect.',
      cssClass: 'custom-alert', backdropDismiss: true, buttons: ['ok'],
    });
    await ac.present();
  }

  async reattempt() {
    if (this.isEmbedded) {
      return;
    }
    this.creatingModal = true;
    const filteredOrders: { [i: number]: { [orderId: string]: AoResponse } } = {[0]: {}};

    for (const orderId of Object.keys(this.checked)) {
      if (this.checked[orderId]) {
        filteredOrders[0][orderId] = copyObj(this.responses[orderId]);
      }
    }

    if (!objLen(filteredOrders[0])) {
      this.creatingModal = false;
      return;
    }

    const storeNames = {0: this.storeName};
    const timeStamps = {0: {d: this.useValue.timeStamp, t: ''}};

    const modal = await this.modalController.create({
      component: AoReattemptModalComponent, componentProps: {
        responses: filteredOrders, storeNames,
        timeStamps
      }, cssClass: ['child-modal']
    });
    await modal.present();
    const {data: modalData} = await modal.onDidDismiss();
    this.creatingModal = false;
    if (!modalData) {
      return;
    }

    if (!this.reattemptInput) {
      this.reattemptInput = {};
    }
    updateObj(this.reattemptInput, modalData[0], true);
  }

  async submit() {
    if (this.isEmbedded) {
      return;
    }

    if (Object.keys(this.reattemptInput).length > 0) {
      const ac = await this.alertControl.create({
        header: 'Reattempt Auto Order?',
        subHeader: 'Are you sure you would like to reattempt the checked suppliers?',
        message: `This order is from ${this.useValue.message.timestamp.toLocaleDateString()}.`,
        cssClass: 'custom-alert',
        buttons: ['Cancel', {text: 'Reattempt', role: 'y'}],
      });
      await ac.present();
      const {role} = await ac.onDidDismiss();
      if (role === 'y') {
        // for (const orderId of Object.keys(data)) {
        // }
        this.firebase.reattemptAutoOrder(this.useValue.msgID, this.useValue.message.sender, this.reattemptInput)
          .then(docId => {
            for (const orderId of Object.keys(this.reattemptInput)) {
              this.responses[orderId].reattempted = new Date();
            }
          });
      }
    }
  }
}
