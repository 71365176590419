<div class="shared-modal-advanced-filter-container">

  <ion-header>
    <ion-toolbar>
      <ion-title>
        <div>
          <div>{{ modalTitle }}</div>
          <span></span>
          <ion-button *ngIf="buttonCloseCross" fill="clear" (click)="onButtonPress('button-close')">
            <app-display-icon *ngIf="buttonCloseCross" [icon]="icons.close"></app-display-icon>
          </ion-button>
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="context-container">

    <div class="list-container">
      <div class="list-names">
        <ion-button
          *ngIf="advancedFilterGroups?.length < 1"
          fill="clear"
          [disabled]="true">
          <div>
            <span>Please Create Filter</span>
          </div>
        </ion-button>
        <ion-button
          *ngFor="let group of advancedFilterGroups; trackBy: trackByFunction"
          [disabled]="getIsNewAdvancedFilterGroup || isAdvancedFiltersLoading"
          fill="clear"
          (click)="onSelectedNameClick(group.name)">
          <div
            [ngClass]="{
              'selected': selectedAdvancedFilterGroup.name === group.name
            }">
            <app-icon-radiobox-blank
              *ngIf="group.id !== appliedAdvancedFilterGroup?.id">
            </app-icon-radiobox-blank>
            <app-icon-radiobox-marked
              [tooltip]="'Applied filter group to the grid.'"
              *ngIf="group.id === appliedAdvancedFilterGroup?.id">
            </app-icon-radiobox-marked>
            <app-icon-new-box
              *ngIf="!group.saved"
              [tooltip]="'New Unsaved Filter Group'">
            </app-icon-new-box>
            <span>{{ group.name }}</span>
            <app-icon-chevron-right></app-icon-chevron-right>
          </div>
        </ion-button>
      </div>
      <ion-button
        class="add-new-button"
        [disabled]="getIsNewAdvancedFilterGroup"
        (click)="onNewItemClick()">
        Add New
      </ion-button>
    </div>

    <div class="no-select-text" *ngIf="advancedFilterGroups?.length < 1 && !isAdvancedFiltersLoading && !selectedAdvancedFilterGroup">
      <div>Advanced Filter Groups</div>
      <div class="divider"></div>
      <div>There are no saved Advanced Filter Groups saved.</div>
      <div>Please create one to start.</div>
    </div>

    <div class="no-select-text" *ngIf="advancedFilterGroups?.length > 0 && !isAdvancedFiltersLoading && !selectedAdvancedFilterGroup">
      <div>Advanced Filter Groups</div>
      <div>There is no selection made for Advanced Filter Groups.</div>
      <div>Please make a selection to continue.</div>
    </div>

    <div class="form-container" *ngIf="!isAdvancedFiltersLoading && selectedAdvancedFilterGroup; else FormContainerElse">

      <div class="form-heading">
        <ion-item>
          <ion-label position="stacked">Filter Group Name</ion-label>
          <ion-input
            placeholder="Enter filter group name"
            [(ngModel)]="selectedAdvancedFilterGroup.name"
            (ionChange)="onNameChange()">
          </ion-input>
        </ion-item>
      </div>

      <div class="form-container-actions">
        <ion-button
          *ngIf="selectedAdvancedFilterGroup?.id !== appliedAdvancedFilterGroup?.id"
          fill="clear"
          [disabled]="!selectedAdvancedFilterGroup.saved"
          (click)="onApplyFilterClick()">
          <app-icon-filter-variant-plus
            [tooltip]="'Apply ' + selectedAdvancedFilterGroup.name + ' to grid'">
          </app-icon-filter-variant-plus>
         Apply to Grid
        </ion-button>
        <ion-button *ngIf="selectedAdvancedFilterGroup?.id === appliedAdvancedFilterGroup?.id" fill="clear" (click)="onRemoveFilterClick()">
          <app-icon-filter-variant-remove
            [tooltip]="'Remove ' + selectedAdvancedFilterGroup.name + ' on grid'">
          </app-icon-filter-variant-remove>
          Remove from Grid
        </ion-button>
        <span></span>
        <ion-button *ngIf="!selectedAdvancedFilterGroup.saved" fill="clear" (click)="onSaveItemClick()">
          <app-icon-content-save-outline
            [tooltip]="'Save - ' + selectedAdvancedFilterGroup.name + ' - Filter Group'">
          </app-icon-content-save-outline>
        </ion-button>
        <ion-button *ngIf="!selectedAdvancedFilterGroup.saved" fill="clear" (click)="onCancelItemClick()">
          <app-icon-cancel
            [tooltip]="'Discard changes on - ' + selectedAdvancedFilterGroup.name">
          </app-icon-cancel>
        </ion-button>
        <ion-button fill="clear" (click)="onDeleteAdvancedFilterGroupClick()">
          <app-icon-delete-outline
            [tooltip]="'Delete - ' + selectedAdvancedFilterGroup.name + ' - Filter Group'">
          </app-icon-delete-outline>
        </ion-button>
        <ion-button fill="clear" [disabled]="!selectedAdvancedFilterGroup.saved" (click)="onCloneItemClick()">
          <app-icon-content-copy
            [tooltip]="'Clone - ' + selectedAdvancedFilterGroup.name + ' - Filter Group'">
          </app-icon-content-copy>
        </ion-button>
      </div>

      <div class="divider"></div>

      <div class="form-list">
        <app-shared-modal-filters-advanced-group
          [predecessorID]="selectedAdvancedFilterGroup.id"
          [setColDef]="colDefsNames"
          [setFilterGroups]="(selectedGroupData$ | ngrxPush)">
        </app-shared-modal-filters-advanced-group>
      </div>

    </div>

  </div>

</div>

<ng-template #FormContainerElse>
  <ng-container  *ngIf="isAdvancedFiltersLoading;">
    <div class="loading-indication-afg">
      <div>
        <app-shared-loading-indicator
          [animation]="animationLogo">
        </app-shared-loading-indicator>
      </div>
    </div>
  </ng-container>
</ng-template>

