import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {FireAuthService} from '../../../../shared-utilities/services-old/fire-auth.service';

@Component({
  selector: 'app-user-profile-config',
  templateUrl: './user-profile-config.component.html',
  styleUrls: ['./user-profile-config.component.scss'],
})
export class UserProfileConfigComponent implements OnInit {
  username: string;
  userId: string;
  isEditingUsername = false;
  usernameFormGroup: FormGroup;
  profilePictureUrl = '';

  constructor(
    private modalController: ModalController,
    private firebase: FirebaseService,
    private af: AngularFirestore,
    private fb: FormBuilder,
    private fireAuth: FireAuthService
  ) {
    this.usernameFormGroup = this.fb.group({
      username: [this.username, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]]
    });
    this.changeLargeModalClass('.larger-modal', 'larger-modal', 'card-modal');
  }

  ngOnInit(): void {
    this.firebase.userObj.subscribe(obj => {
      if (obj) {
        this.username = obj.userName;
        this.userId = obj.id;
        this.profilePictureUrl = obj.pp;
      }
    });
  }

  getUserNameInitials(): string {
    return this.username ? this.username.charAt(0).toUpperCase() : '';
  }

  onEditUserNameClick(): void {
    this.isEditingUsername = true;
  }

  async saveUsername(): Promise<void> {
    if (this.usernameFormGroup.valid) {
      this.isEditingUsername = true;
    }
  }

  async onResetPwdClick(): Promise<void>  {
    await this.fireAuth.sendPasswordResetEmail(this.userId);
  }

  onChangeUserNameClick(): void {
    const newName = this.usernameFormGroup.value.username;
    this.isEditingUsername = false;
    void this.af.doc(`users/${this.userId}`).update({userName: newName}).then();
  }

  onEditProfilePictureClick(): void {
    document.getElementById('profilePictureInput').click();
  }

  async onProfilePictureChange(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length > 0) {
      const file = input.files[0];
      try {
        const compressedBase64 = await this.compressImage(file, 120, 120, 0.5); // Compress the image
        await this.af.doc(`users/${this.userId}`)
          .update({pp: compressedBase64});
        this.profilePictureUrl = compressedBase64;
      } catch (error) {
        console.error(error);
      }
    }
  }

  onModalClose(): void {
    void this.modalController.dismiss().then();
  }

  private async compressImage(file: File, maxWidth: number, maxHeight: number, quality: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>): void => {
        const img = new Image();
        if (typeof event.target.result === "string") {
          img.src = event.target.result;
        }
        img.onload = (): void => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          const compressedBase64 = canvas.toDataURL('image/jpeg', quality);
          resolve(compressedBase64);
        };
        img.onerror = (error: string | Event): void => { reject(error); };
      };
      reader.onerror = (error: ProgressEvent<FileReader>): void => { reject(error); };
      reader.readAsDataURL(file);
    });
  }

  private changeLargeModalClass(modalSelector: string, removeClass: string, addClass: string): void {
    const modalQuery = document.querySelector(modalSelector);
    if (modalQuery) {
      modalQuery.classList.remove(removeClass);
      modalQuery.classList.add(addClass);
    }
  }
}
