<div class="item toggle">
    <span>Fit To Table</span>
    <app-display-icon
    [icon]="icons.helpCircleOutline"
    class="icon"
    matTooltip="When enabled, all table columns are resized to fit within the screen, making all columns visible but
    potentially squashed. When disabled, columns maintain their fixed width, and you can scroll horizontally to view
    those that dont fit on the screen.">
    </app-display-icon>
    <ion-toggle (click)="onToggleFitToTableClick()" [(ngModel)]="showAllColDefs"></ion-toggle>
</div>
