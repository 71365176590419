import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AlertController, IonAccordionGroup, ModalController, PopoverController, ToastController} from '@ionic/angular';
import {StoreInfo, TableNavSettings} from '../../../../../shared-utilities/models-old/datastructures';
import {FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {
  checkNgpReportSettings,
  DEFAULT_NG_REP_SETTINGS,
  NgpReportSettings,
} from '../../../../../shared-utilities/models-old/ngp-report/ngp-data-types';
import {FirebaseService} from '../../../../../shared-utilities/services-old/firebase.service';
import {StandardAlertsService} from '../../../../../shared-utilities/services-old/standard-alerts.service';
import {IStore} from '../../../../../shared/shared-models/store/store';
import {Store} from '@ngrx/store';
import {
  SelectPopoverComponent,
} from '../../../../../shared-modules/shared-module/components/select-popover/select-popover.component';
import {ColDef} from 'ag-grid-community';
import {GridUtils} from '../../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {
  ngpReportGridColDefs,
} from '../../../../../shared-utilities/models-old/ngp-report-grid/defaults/ngp-report/ngp-report-grid-col-defs';
import {setNGPReportColPreviewSettings} from '../../../store/settings.actions';

@Component({
  selector: 'app-setting-ngp-report',
  templateUrl: './settings-ngp-report.component.html',
  styleUrls: ['./settings-ngp-report.component.scss'],
})
export class SettingsNgpReportComponent implements OnInit {

  @Input() storeId?: string;
  @Input() storeInfo: StoreInfo = {name: ''};



  ngpSettings: NgpReportSettings;
  changed: { [key in keyof NgpReportSettings]: true };
  currentStore: IStore | null;


  ngpSettingsFG: UntypedFormGroup;
  navSettings: TableNavSettings;



  constructor(
    private firebase: FirebaseService,
    private stdAlerts: StandardAlertsService,
    private alertControl: AlertController,
    private modalController: ModalController,
    private toastControl: ToastController,
    private popoverController: PopoverController,
    private readonly store: Store,
  ) {
  }

  @Input() set storeDetails(store: IStore) {
    this.storeId = store.storeId;
    this.currentStore = store;
  };

  ngOnInit(): void {

    void this.stdAlerts.indeterminateProgress({}).then((finished) => {
      void this.firebase.getStoreDataDoc('general_ngp_settings', this.storeId, 'operational').then((data) => {

        this.ngpSettings = data ? data : DEFAULT_NG_REP_SETTINGS;
        this.ngpSettingsFG = new UntypedFormGroup({});
        this.ngpSettingsFG.setControl('itemExpDays', new FormControl<number>(this.ngpSettings.itemExpDays,
          [this.validateNgpReportSetting('itemExpDays')]));
        this.ngpSettingsFG.setControl('requestExpDays', new FormControl<number>(this.ngpSettings.requestExpDays,
          [this.validateNgpReportSetting('requestExpDays')]));

        Object.keys(this.ngpSettingsFG.controls).forEach((key) => {
          this.ngpSettingsFG.controls[key].setParent(this.ngpSettingsFG);
        });
        finished();
      });
    });

  }


  close = (): void => {
    void this.modalController.dismiss().then();
  };

  change(event: Event, key: keyof NgpReportSettings): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;



    if (inputValue !== '' + this.ngpSettings[key] &&
      !(inputValue === '' && [undefined, null].includes(this.ngpSettings[key]))) {
      if (!this.changed) {
        this.changed = {[key]: true} as never;
      } else {
        this.changed[key] = true;
      }
    } else if (this.changed) {
      delete this.changed[key];

      if (Object.keys(this.changed).length === 0) {
        this.changed = null;
      }
    }
  }




  checkRemoveValue(key: keyof NgpReportSettings, valueOnWhichToRemove: string | number): void {
    // @ts-ignore
    const v = this.ngpSettingsFG.get(key).value;

    if (v === valueOnWhichToRemove) {
      // @ts-ignore
      this.ngpSettingsFG.get(key).setValue(null);
    }
  }

  getErrors(key: keyof NgpReportSettings): string[] {
    const errors: string[] = [];
    // @ts-ignore
    const e = this.ngpSettingsFG.get(key).errors;
    Object.keys(e).forEach((k) => {
      errors.push(e[k] as string);
    });
    return errors;
  }

  async save(): Promise<void> {
    if (this.ngpSettingsFG.invalid) {
      return;
    }

    const value = this.ngpSettingsFG.value;

    const ac = await this.alertControl.create({
      header: 'Update General NGP Settings', subHeader: 'You are about to update the NGP Report settings for ' +
        this.storeInfo.name + '.', message: 'This will affect all users with access to Report for ' +
        this.storeInfo.name + '<br><br>Are you sure you want to continue?', cssClass: ['custom-alert', 'warn'],
      buttons: ['Cancel', {text: 'Yes', role: 'y'}],
    });
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role !== 'y') {
      return;
    }

    void this.stdAlerts.indeterminateProgress({}).then((loaded) => {
      this.firebase.updateStoreDataDoc('general_ngp_settings', this.storeId, value, 'operational')
        .then(async () => {
          loaded();
          const tc = await this.toastControl.create({
            header: 'Settings Saved', cssClass: 'custom-toast', duration: 1500, position: 'top',
          });
          await tc.present();
          this.changed = null;
        })
        .catch((error) => {
          loaded();
          void this.stdAlerts.errorAlert({
            message: error.message,
            type: (error.message.includes('Missing or insufficient permissions') ? 'PERMISSION' : null),
          });
        });
    });
  }

  private validateNgpReportSetting(key: keyof NgpReportSettings): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }
      const nrs: NgpReportSettings = control.parent.value;
      nrs[key] = control.value;
      return checkNgpReportSettings(nrs, key);
    };
  }

}
