<div id="select-store" *ngIf="stores.order.length > 1">
  Selected Store:
  <ion-select [(ngModel)]="selectedStore" class="ion-no-padding" placeholder="Select Store"
              (ionChange)="onChangeStoreSelect()"
  >
    <ion-select-option *ngFor="let storeId of stores.order" [value]="storeId">
      {{ stores.stores[storeId].name }}
    </ion-select-option>
  </ion-select>
</div>

<div *ngIf="selectedStore">
  <table class="threshold-table">
    <thead>
    <tr>
      <th>Description</th>
      <th>Value</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>Maximum Increase in Price</td>
      <td>
        <div class="input-percentage-wrapper">
          <input [ngModel]="thresholds[selectedStore].pos * 100" (keyup)="onValueChange(selectedStore, $event, 'pos')">
          <span class="percentage-symbol">%</span>
        </div>
      </td>
    </tr>
    <tr>
      <td>Maximum Decrease in Price</td>
      <td>
        <div class="input-percentage-wrapper">
          <input
            [ngModel]="thresholds[selectedStore].neg * 100"
            (keyup)="onValueChange(selectedStore, $event, 'neg')"
            min="0" max="100">
          <span class="percentage-symbol">%</span>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<button mat-raised-button *ngIf="selectedStore" style="float: right" (click)="onClickSave($event)">Save</button>
