<svg width="880" height="453" viewBox="0 0 880 453" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g >
    <path d="M162.044 433.246L480.081 1.99994L23.3423 1.99998C-38.9173 1.99998 -97.2006 32.5981 -132.556 83.8451L-426.721 510.231L9.61365 510.231C69.7661 510.231 126.342 481.657 162.044 433.246Z" fill="url(#paint0_linear_188_1191)" fill-opacity="0.15"/>
    <path d="M162.044 433.246L480.081 1.99994L23.3423 1.99998C-38.9173 1.99998 -97.2006 32.5981 -132.556 83.8451L-426.721 510.231L9.61365 510.231C69.7661 510.231 126.342 481.657 162.044 433.246Z" stroke="url(#paint1_linear_188_1191)" stroke-width="2.93734"/>
  </g>
  <g>
    <path d="M450.426 766.56L876.543 189.687L259.329 189.687C197.12 189.687 138.877 220.236 103.512 271.416L-291.755 843.428L298.082 843.428C358.183 843.428 414.717 814.903 450.426 766.56Z" fill="url(#paint2_linear_188_1191)" fill-opacity="0.1"/>
    <path d="M450.426 766.56L876.543 189.687L259.329 189.687C197.12 189.687 138.877 220.236 103.512 271.416L-291.755 843.428L298.082 843.428C358.183 843.428 414.717 814.903 450.426 766.56Z" stroke="url(#paint3_linear_188_1191)" stroke-width="2.93734"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_188_1191" x1="-207.401" y1="639.925" x2="266.034" y2="-121.367" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_188_1191" x1="-362.961" y1="596.829" x2="363.613" y2="3.38654" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint2_linear_188_1191" x1="132.122" y1="820.231" x2="526.476" y2="189.687" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint3_linear_188_1191" x1="-209.609" y1="954.82" x2="725.287" y2="190.001" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0.1"/>
    </linearGradient>
  </defs>
</svg>
