<ng-container *ngIf="!facets; else componentReady">
  <div id="initialising">
    <div>
      <div>
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </div>
      <div>
        <i>Initialising Search...</i>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #componentReady>
  <ng-container *ngIf="!modal; else modalStyle">
    <div>
      <ng-container *ngTemplateOutlet="searchbar"></ng-container>
    </div>
  </ng-container>
  <ng-template #modalStyle>
    <app-modal-header title="Item Search" [close]="close"></app-modal-header>

    <div id="modal-layout" class="scroll-pain">
      <div>
        <ng-container *ngTemplateOutlet="searchbar"></ng-container>
      </div>
      <div>
        <div class="main tweak" (click)="unhidden.tweaks = !unhidden.tweaks">
          Refine <ion-icon name="caret-{{ unhidden.tweaks ? 'up' : 'down' }}-outline"></ion-icon>
          <i class="truncate" *ngIf="!!refinementAppliedStr">
            ({{ refinementAppliedStr }})
          </i>
        </div>
        <div class="tweaks" [hidden]="!unhidden.tweaks">
          <!--    Search In    -->
          <div [class]="'tweak' + (refinementApplied.searchable ? ' active' : '')" (click)="clickTweak('search')">
            Searchable Fields
<!--            <ng-container *ngTemplateOutlet="refinementAppliedIndicator; context: {key: 'searchable'}"></ng-container>-->
            <ion-icon name="caret-{{ unhidden.search ? 'up' : 'down' }}-outline"></ion-icon>
          </div>
          <div *ngIf="unhidden.search" class="search-in">
            <div (click)="toggleSearch()">Toggle All</div>
            <div *ngFor="let key of searchable" (click)="toggleSearch(key)">
              <div>{{ key2Title[key] }}</div>
              <div>
                <ion-checkbox [checked]="searchSelection[key]"></ion-checkbox>
              </div>
            </div>
          </div>
          <!--    Visible    -->
          <div [class]="'tweak' + (refinementApplied.retrieve ? ' active' : '')" (click)="clickTweak('retrieve')">
            Shown Fields
            <ion-icon name="caret-{{ unhidden.retrieve ? 'up' : 'down' }}-outline"></ion-icon>
          </div>
          <div *ngIf="unhidden.retrieve" class="search-in">
            <div (click)="toggleRetrieve()">Toggle All</div>
            <div *ngFor="let key of searchable.slice(1)" (click)="toggleRetrieve(key)">
              <div>{{ key2Title[key] }}</div>
              <div>
                <ion-checkbox [checked]="retrieveSelection[key]"></ion-checkbox>
              </div>
            </div>
          </div>
          <!--    Department Facets    -->
          <div [class]="'tweak' + (refinementApplied.dep ? ' active' : '')" (click)="clickTweak('deps')">
            Departments
<!--            <ng-container *ngTemplateOutlet="refinementAppliedIndicator; context: {key: 'dep'}"></ng-container>-->
            <ion-icon name="caret-{{ unhidden.deps ? 'up' : 'down' }}-outline"></ion-icon>
          </div>
          <app-facet-list *ngIf="unhidden.deps" facetID="dep" [initFacets]="facets.dep" [names]="departments.dep" [checks]="facetChecks.dep" [check]="toggleFacet" bubbleColour="var(--ion-color-white)"></app-facet-list>
          <!--    Sub-Department Facets    -->
          <div [class]="'tweak' + (refinementApplied.subDep ? ' active' : '')" (click)="clickTweak('subDeps')">
            Sub Departments
<!--            <ng-container *ngTemplateOutlet="refinementAppliedIndicator; context: {key: 'subDeps'}"></ng-container>-->
            <ion-icon name="caret-{{ unhidden.subDeps ? 'up' : 'down' }}-outline"></ion-icon>
          </div>
          <app-facet-list *ngIf="unhidden.subDeps" facetID="subDep" [initFacets]="facets.subDep" [names]="departments.sub" [checks]="facetChecks.subDep" [check]="toggleFacet" bubbleColour=" var(--ion-color-medium)"></app-facet-list>
          <!--    Supplier Facets    -->
          <div [class]="'tweak' + (refinementApplied.regularSuppCode ? ' active' : '')" (click)="clickTweak('supps')">
            Suppliers
<!--            <ng-container *ngTemplateOutlet="refinementAppliedIndicator; context: {key: 'supps'}"></ng-container>-->
            <ion-icon name="caret-{{ unhidden.supps ? 'up' : 'down' }}-outline"></ion-icon>
          </div>
          <app-facet-list *ngIf="unhidden.supps" facetID="regularSuppCode" [initFacets]="facets.regularSuppCode" [names]="suppliers" [checks]="facetChecks.regularSuppCode" [check]="toggleFacet" bubbleColour="var(--ion-color-medium)"></app-facet-list>
          <!--    Filters    -->
          <div [class]="'tweak' + (refinementApplied.filters ? ' active' : '')" (click)="clickTweak('filters')">
            Filters {{ lockFilters ? '(locked)' : '' }}
<!--            <ng-container *ngTemplateOutlet="refinementAppliedIndicator; context: {key: 'filters'}"></ng-container>-->
            <ion-icon name="caret-{{ unhidden.filters ? 'up' : 'down' }}-outline"></ion-icon>
          </div>
          <app-stock-filters [hidden]="!unhidden.filters" [filters]="filters" [onApply]="applyFilters" [lock]="lockFilters"></app-stock-filters>
        </div>
      </div>
      <div class="table-scroll-container">
        <table *ngIf="hits">
          <thead>
          <tr>
            <th resizable *ngFor="let col of displayColumns">
              {{ key2Title[col] }}
            </th>
          </tr>
          </thead>
          <tbody class="selectable-list">
            <ng-container *ngFor="let item of hits">
              <tr *ngIf="!disabled.includes(item.stockId); else disabledItem"
                  [class]="'selectable-list-item' + (selected[item.stockId] ? ' selected' : '')"
                  (click)="select(item.stockId)">
                <ng-container *ngTemplateOutlet="colTemplate"></ng-container>
              </tr>
              <ng-template #disabledItem>
                <tr class="selectable-list-item disabled">
                  <ng-container *ngTemplateOutlet="colTemplate"></ng-container>
                </tr>
              </ng-template>
              <ng-template #colTemplate>
                <ng-container *ngFor="let col of displayColumns">
                  <td *ngIf="item.highlight && item.highlight[col]; else noHighlightTemplate" [innerHtml]="item.highlight[col].value">
                  </td>
                  <ng-template #noHighlightTemplate>
                    <td>{{ item[col] }}</td>
                  </ng-template>
                </ng-container>
              </ng-template>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="buttons">
<!--        <ion-button>butt</ion-button>-->
        <div>
          <app-simple-pagination [pagination]="pagination" (onchange)="changePage($event)"></app-simple-pagination>
        </div>
        <div>
          <span>
          <div *ngIf="multiple; else singleSelect" class="shrinkable">
            <div class="shrink-part">Multiple Select Active</div>
            <ion-icon size="large" name="checkmark-done-circle-outline"></ion-icon>
          </div>
          <ng-template #singleSelect>
            <div class="shrinkable inactive">
              <div class="shrink-part">Single Select</div>
              <ion-icon size="large" name="checkmark-done-circle-outline"></ion-icon>
            </div>
          </ng-template>
        </span>
          <div class="selected-number" *ngIf="multiple && numSelected">
            <i>Selected: <b>{{ numSelected }}</b></i>
          </div>
          <ion-button [disabled]="!numSelected" (click)="makeSelection()">Select</ion-button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #searchbar>
    <ion-input id="search-bar" type="search" class="search-bar" placeholder="Search" debounce="500" (ionChange)="searchEvent($event)"
      clear-input="true"
    >
      <ion-icon name="search-circle-outline"></ion-icon>
    </ion-input>
  </ng-template>
</ng-template>

<!--<ng-template #refinementAppliedIndicator let-key="key">-->
<!--  <ng-container *ngIf="refinementApplied[key]">-->
<!--    *-->
<!--  </ng-container>-->
<!--</ng-template>-->
