<ion-row>
  <ion-col><b>{{ storeName }} Auto Order</b></ion-col>
  <ion-col><i>{{ failures === 0 ? 'success' : (failures + ' issues') }}</i></ion-col>
</ion-row>
<ion-row *ngFor="let orderId of ordersOrder" class="order-response">
  <ion-col>{{ suppliersClean[orderId] }}</ion-col>

  <ng-container *ngIf="responses[orderId] && responses[orderId].success; else unsuccessful">
    <ion-col>
      <ion-icon name="checkmark-circle-outline" matTooltip="IQ Submitted"></ion-icon>
      <ion-icon name="checkmark-circle-outline" matTooltip="PDF Generated"></ion-icon>
      <ion-icon name="checkmark-circle-outline" matTooltip="Email Sent"></ion-icon>
    </ion-col>
  </ng-container>


  <ng-template #unsuccessful>
    <ion-col class="mark-reattempt" [matTooltip]=" responses[orderId].pos === 'AO_PDF_FAIL' ? 'Tricky, not yet supported (ask me)' : null">
      Mark for reattempt:
      <ion-checkbox color="success" [(ngModel)]="checked[orderId]" (ionChange)="checkClicked(orderId)"
                    [disabled]="!!responses[orderId].reattempted || isDeleted[orderId] || isDeleted[orderId] === undefined || imapErrors[orderId]"
                    [hidden]="reattemptInput?.[orderId]"
      >
      </ion-checkbox>
    </ion-col>
    <ion-col>
      <ng-container *ngIf="reattemptInput?.[orderId] && !responses[orderId].reattempted">
        <ion-icon name="checkmark-done-outline" matTooltip="Ready To Submit"></ion-icon>
        Ready to submit
      </ng-container>
      <ng-container *ngIf="imapErrors[orderId]; else reattempted" >
       <div style="color: var(--ion-color-warning); display: flex;">No need to reattempt</div>
      </ng-container>
    </ion-col>

    <ng-template #reattempted>
      <ng-container *ngIf="responses[orderId].reattempted; else deleted">
        <ng-container *ngIf="reattemptInput?.[orderId]">
          <ion-icon name="checkmark-done-outline" matTooltip="Ready To Submit"></ion-icon>
        </ng-container>
        Already reattempted on {{ responses[orderId].reattempted.toLocaleDateString() }}
      </ng-container>
    </ng-template>
    <ng-template #deleted>
      <div  *ngIf="isDeleted[orderId]" style="color: var(--ion-color-danger); display: flex">
        This order has already been deleted
      </div>
    </ng-template>
    <ng-container *ngIf="responses[orderId].pos === 'AO_SUBMISSION_FAIL'; else subSuccess">
      <ion-col>
        <ion-icon name="close-circle-outline" matTooltip="IQ Submitted" (click)="errorMsg(orderId)"></ion-icon>
        <ion-icon name="ellipse-outline" matTooltip="PDF Generated"></ion-icon>
        <ion-icon name="ellipse-outline" matTooltip="Email Sent"></ion-icon>
      </ion-col>
    </ng-container>
  </ng-template>
  <ng-template #subSuccess>
    <ng-container *ngIf="responses[orderId].pos === 'AO_PDF_FAIL'; else pdfSuccess">
      <ion-col>
        <ion-icon name="checkmark-circle-outline" matTooltip="IQ Submitted"></ion-icon>
        <ion-icon name="close-circle-outline" matTooltip="PDF Generated"></ion-icon>
        <ion-icon name="ellipse-outline" matTooltip="Email Sent"></ion-icon>
      </ion-col>
    </ng-container>
    <ng-template #pdfSuccess>
      <ng-container *ngIf="responses[orderId].pos === 'AO_EMAIL_FAIL'; else emailSuccess">
        <ion-col>
          <ion-icon name="checkmark-circle-outline" matTooltip="IQ Submitted"></ion-icon>
          <ion-icon name="checkmark-circle-outline" matTooltip="PDF Generated"></ion-icon>
          <ion-icon *ngIf="imapErrors[orderId]" name="alert-circle-outline" matTooltip="Email Sent But Not Copied To Outbox"
                    (click)="imapError()">
          ></ion-icon>
          <ion-icon *ngIf="!imapErrors[orderId]" name="close-circle-outline" matTooltip="Sending Email Failed"
                    (click)="errorMsg(orderId)">
            ></ion-icon>
        </ion-col>
      </ng-container>
      <ng-template #emailSuccess>
        <ng-container *ngIf="responses[orderId].pos === 'AO_REPEAT_SUPPLIER'; else unknown">
          <ion-col>
            <ion-icon name="today-outline" (click)="errorMsg(orderId)"
                      [matTooltip]="'Auto order already submitted for ' + responses[orderId].supplier + ' today.'"
            >
            </ion-icon>
          </ion-col>
        </ng-container>
        <ng-template #unknown>
          <ion-col>
            <ion-icon name="help-circle-outline" matTooltip="Unsure what happened, inform Techodactyl Support"></ion-icon>
          </ion-col>
        </ng-template>
      </ng-template>
    </ng-template>
  </ng-template>
</ion-row>
<ion-row *ngIf="!isEmbedded && failures > 0" >
  <ion-col class="reattempt-button">
    <ion-button size="small" color="light" [disabled]="numChecked <= 0 || creatingModal" (click)="reattempt()">Resolve
    </ion-button>
    <ion-button size="small" color="light" [disabled]="!reattemptInput"
                (click)="submit()">Submit
    </ion-button>
  </ion-col>

</ion-row>

<!--
<ion-icon name="close-circle-outline"></ion-icon>
-->
