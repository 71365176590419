/**
 * @class DateUtils
 * Utilities for manipulating and displaying dates and date related data.
 *
 * @member verifyArrayDataExists Verify if there is data provided in the array or return an empty array.
 */
export class DateUtils {

  /**
   * Convert the date to a string.
   * This converts a Date object to a string.
   *
   * @param date The date that needs to be displayed.
   * @returns If a proper Date object is provided it will be returned.
   * @returns If no date is provided an empty string is returned.
   *
   */
  static dateToString(date: Date): string | null {
    return date ? new Date(date).toDateString() : null;
  }

}
