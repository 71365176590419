import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as SharedActions from '../store-shared/shared.actions';
import {catchError, map, mergeMap, switchMap, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {selectSelectedUserStore, selectUser} from '../../../features-as-modules/feature-core/store/core.selectors';
import {select, Store} from '@ngrx/store';
import {
  CollectionSharedSuppliersService,
} from '../../../shared/shared-services/firebase/collection-shared-suppliers.service';
import {from, of, Subject} from 'rxjs';
import {IUser} from '../../../shared/shared-models/user-access/user';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IColleagues} from '../../../shared/shared-models/user-access/user-access';
import {
  CollectionUserAccessColleaguesService,
} from '../../../shared/shared-services/firebase/collection-user-access-colleagues.service';
import {StorageService} from '../../../shared/shared-services/firebase/storage.service';
import {ISupplier} from '../../../shared/shared-models/stock/suppliers';

@Injectable()
export class SharedEffects {

  // '[Core Shared][Suppliers] Get Store Suppliers By User Selected Store'
  getStoreSuppliersByUserSelectedStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getStoreSuppliersByUserSelectedStore),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      map(([_, selectedUserStores]) => {
        return SharedActions.getStoreSuppliersByStore({store: selectedUserStores});
      }),
    ),
  );

  // '[Core Shared][Suppliers] Get Store Suppliers By Store',
  getStoreSuppliersByStore$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.getStoreSuppliersByStore),
    mergeMap(({store}) =>
      this.collectionSharedSuppliersService.getSuppliersByStore(store)
        .pipe(
          map((suppliers: { [supplierId: string]: ISupplier }) =>
            SharedActions.getStoreSuppliersByStoreSuccess({suppliers, store})),
          catchError((error: IError) => of(SharedActions.getStoreSuppliersByStoreFailure({error}))),
        ),
    ),
  ));

  // ''[Core Shared][Storage] Get Download URL from Storage',
  getDownloadUrlFromStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getDownloadUrlFromStorage),
      mergeMap(({storage}) =>
        from(this.storageService.getDocumentDownloadUrl(storage))
          .pipe(
            map((documentUrl: string) => SharedActions.getDownloadUrlFromStorageSuccess({
              storage: {
                ...storage,
                url: documentUrl
              }
            })),
            catchError((error: IError) => of(SharedActions.getDownloadUrlFromStorageFailure({error})),
            ),
          )
      ),
    )
  );


  private stop$ = new Subject<void>();

  getColleagues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getColleagues),
      switchMap(() =>
        this.store.pipe(
          select(selectUser),
          switchMap((user: IUser) => {
            if (!user.id) {
              const error: IError = {
                status: 'No user ID found',
                httpResponseCode: 400,
                error: 'User ID is missing',
                response: null,
                request: null,
              };
              return of(SharedActions.getColleaguesFailure({error}));
            }

            return this.collectionUserAccessColleaguesService.getColleagues(user.id).pipe(
              takeUntil(this.stop$),
              map((users: IColleagues) => SharedActions.getColleaguesSuccess({users})),
              catchError((error: IError) => {
                const err: IError = {
                  status: error.status || 'Unknown Error',
                  httpResponseCode: error.httpResponseCode || 500,
                  error: error.error || error,
                  response: error.response || null,
                  request: error.request || null,
                };
                return of(SharedActions.getColleaguesFailure({error: err}));
              }),
            );
          }),
        ),
      ),
    ),
  );

  killColleaguesService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.killColleaguesService),
        tap(() => {
          this.stop$.next();
        }),
      ),
    {dispatch: false},
  );

  constructor(
    private actions$: Actions,
    private collectionSharedSuppliersService: CollectionSharedSuppliersService,
    private collectionUserAccessColleaguesService: CollectionUserAccessColleaguesService,
    private storageService: StorageService,
    private readonly store: Store,
  ) {
  }

  killSubscription(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
