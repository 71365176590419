<ion-content>
  <div class="user-info">
    <div class="user-name">
      {{ user.userName }}
    </div>
    <div *ngIf="pp[0] === 'picture'; else letter">
      <img
        [src]="pp[1]"
        alt="Profile Picture"
        class="profile-picture">
    </div>
    <ng-template #letter>
      <div class="profile-picture">
        {{ pp[1] }}
      </div>
    </ng-template>
  </div>
  <div class="options">
    <div class="item" (click)="onUserProfileClick()">
      <span>User Profile</span>
      <app-icon-person class="icon"></app-icon-person>
    </div>
    <div class="item" (click)="onLoginLogoutClick()">
      <span>Login/Sign Out</span>
      <app-icon-log-in class="icon"></app-icon-log-in>
    </div>
    <div class="item" (click)="onExplainManageItClick()">
      <span>How Does Manage It Work?</span>
      <app-icon-help-circle-outline class="icon"></app-icon-help-circle-outline>
    </div>
  </div>
</ion-content>
