import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {getDeepCopyOfObject} from 'src/app/shared/shared-utils/object/object.utils';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {IAdvancedFilterGroup, IFilterGroup,} from '../../../shared/shared-models/filter-groups/filter-groups';
import * as SharedActions from './shared-filter.actions';
import {
  deleteFilterOrGroupWithinAdvancedFilterGroup,
  findAndReplaceFilterWithinAdvancedFilterGroup,
  onActionAddFilterAndGroupToSelectedAdvancedFilterGroup,
  onActionAddFilterToSelectedAdvancedFilterGroup,
  onActionAddGroupToSelectedAdvancedFilterGroup,
  onCreateAdvancedFilterGroup,
  onGenerateCopyOfAdvancedFilterGroup,
  onGenerateNewAdvancedFilterGroup,
  onSetSelectedAdvancedFilterGroupOperator
} from './shared-filter.reducer.utils';

export interface SharedFilterState extends IInitialState {
  advancedFilterIDCounter: number;
  advancedFilterGroups: IAdvancedFilterGroup[];
  appliedAdvancedFilterGroup: IAdvancedFilterGroup;
  isAdvancedFilterGroupLoading: boolean;
  selectedAdvancedFilterGroup: IAdvancedFilterGroup;
  selectedAdvancedFilterGroupGrid: string;
}

export const initialSharedFilterState: SharedFilterState = {
  advancedFilterIDCounter: 0,
  advancedFilterGroups: [],
  appliedAdvancedFilterGroup: null,
  errors: [],
  isAdvancedFilterGroupLoading: false,
  selectedAdvancedFilterGroup: null,
  selectedAdvancedFilterGroupGrid: ''
};

const createSharedFilterReducer: ActionReducer<SharedFilterState> = createReducer(
  initialSharedFilterState,
  // ====================================================================================================
  // Create Advanced Filter Group
  // ====================================================================================================
  on(
    SharedActions.addFilterAndGroupToSelectedAdvancedFilterGroup,
    (state: SharedFilterState, {predecessorID, shouldAddFilter}) =>
      onActionAddFilterAndGroupToSelectedAdvancedFilterGroup(state, predecessorID, shouldAddFilter)
  ),
  on(
    SharedActions.addFilterToSelectedAdvancedFilterGroup,
    (state: SharedFilterState, {predecessorID}) =>
      onActionAddFilterToSelectedAdvancedFilterGroup(state, predecessorID)
  ),
  on(
    SharedActions.addGroupToSelectedAdvancedFilterGroup,
    (state: SharedFilterState, {predecessorID}) =>
      onActionAddGroupToSelectedAdvancedFilterGroup(state, predecessorID)
  ),
  on(
    SharedActions.applyAdvancedFilterGroupFilterToGrid,
    (state: SharedFilterState) => {
      return {
        ...state,
        appliedAdvancedFilterGroup: state.selectedAdvancedFilterGroup ? state.selectedAdvancedFilterGroup : null
      }
    }
  ),
  on(
    SharedActions.cancelEditingOfAdvancedFilterGroup,
    (state: SharedFilterState, {originalAdvancedFilterGroup}) => {
      const original = originalAdvancedFilterGroup;
      const advancedFilterGroups = getDeepCopyOfObject<IAdvancedFilterGroup[]>(state.advancedFilterGroups);
      const index = advancedFilterGroups.findIndex((afg: IAdvancedFilterGroup): boolean => afg.id === original.id);
      advancedFilterGroups[index] = original;
      return {
        ...state,
        advancedFilterGroups,
        selectedAdvancedFilterGroup: original
      }
    }
  ),
  on(
    SharedActions.removeAdvancedFilterGroupFilterFromGrid,
    (state: SharedFilterState) => {
      return {
        ...state,
        appliedAdvancedFilterGroup: undefined
      }
    }
  ),
  on(
    SharedActions.cloneSelectedAdvancedFilterGroup,
    (state: SharedFilterState) => {
      const copy = onGenerateCopyOfAdvancedFilterGroup(state.selectedAdvancedFilterGroup, state.advancedFilterIDCounter);
      const advancedFilterGroups = [...state.advancedFilterGroups];
      advancedFilterGroups.unshift(copy);
      return {
        ...state,
        advancedFilterIDCounter: state.advancedFilterGroups.length + 1,
        advancedFilterGroups,
        selectedAdvancedFilterGroup: copy
      }
    }
  ),
  on(
    SharedActions.createAdvancedFilterGroup,
    (state: SharedFilterState, {grid}) => {
      const newAdvancedFilterGroup = onGenerateNewAdvancedFilterGroup(state.advancedFilterGroups.length, state.advancedFilterIDCounter, grid)
      const advancedFilterGroups = onCreateAdvancedFilterGroup(state.advancedFilterGroups, newAdvancedFilterGroup);
      return {
        ...state,
        advancedFilterIDCounter: state.advancedFilterIDCounter + 2,
        advancedFilterGroups,
        selectedAdvancedFilterGroup: newAdvancedFilterGroup
      }
    }
  ),
  on(
    SharedActions.clearAdvancedFilterGroupsFromGrid,
    (state: SharedFilterState) => {
      return {
        ...state,
        appliedAdvancedFilterGroup: undefined
      }
    }
  ),
  on(
    SharedActions.deleteSelectedAdvancedFilterGroup,
    (state: SharedFilterState) => {
      const appliedAdvancedFilterGroup =
        state.selectedAdvancedFilterGroup.id === state.appliedAdvancedFilterGroup.id ? undefined : state.appliedAdvancedFilterGroup;
      return {
        ...state,
        isAdvancedFilterGroupLoading: true,
        appliedAdvancedFilterGroup
      }
    }
  ),
  on(
    SharedActions.deleteSelectedAdvancedFilterGroupSuccess,
    (state: SharedFilterState, {id}) => {
      const advancedFilterGroups = [...state.advancedFilterGroups].filter((item): boolean => item.id !== id) || [];
      return {
        ...state,
        advancedFilterGroups,
        isAdvancedFilterGroupLoading: false,
        selectedAdvancedFilterGroup: advancedFilterGroups.length > 0 ? advancedFilterGroups[0] : null,
      }
    }
  ),
  on(
    SharedActions.deleteSelectedAdvancedFilterGroupFailure,
    (state: SharedFilterState, {error}) => {
      return {
        ...state,
        isAdvancedFilterGroupLoading: false,
        errors: [...state.errors, error]
      }
    }
  ),
  on(
    SharedActions.deleteAdvancedFilterGroupFilter,
    (state: SharedFilterState, {filter}) => {
      const selectedAdvancedFilterGroup = deleteFilterOrGroupWithinAdvancedFilterGroup(getDeepCopyOfObject(state.selectedAdvancedFilterGroup), filter.id);
      const advancedFilterGroups = [...state.advancedFilterGroups];
      const index = advancedFilterGroups.findIndex((item: IAdvancedFilterGroup): boolean => item.id === selectedAdvancedFilterGroup.id);
      advancedFilterGroups[index] = selectedAdvancedFilterGroup;
      return {
        ...state,
        advancedFilterGroups,
        isAdvancedFilterGroupLoading: true,
        selectedAdvancedFilterGroup
      }
    }
  ),
  on(
    SharedActions.deleteAdvancedFilterGroupGroup,
    (state: SharedFilterState, {filterGroup}) => {
      let selectedAdvancedFilterGroup = getDeepCopyOfObject(state.selectedAdvancedFilterGroup);
      if (filterGroup.id === state.selectedAdvancedFilterGroup.filterGroup.id) {
        selectedAdvancedFilterGroup.filterGroup = {
          id: state.advancedFilterIDCounter + 1,
          operator: 'AND',
          filtersAndGroups: []
        } as IFilterGroup;
      } else {
        selectedAdvancedFilterGroup = deleteFilterOrGroupWithinAdvancedFilterGroup(selectedAdvancedFilterGroup, filterGroup.id);
      }
      const advancedFilterGroups = [...state.advancedFilterGroups];
      const index = advancedFilterGroups.findIndex((afg: IAdvancedFilterGroup): boolean => afg.id === selectedAdvancedFilterGroup.id);
      advancedFilterGroups[index] = selectedAdvancedFilterGroup;
      return {
        ...state,
        advancedFilterIDCounter: state.advancedFilterIDCounter + 1,
        advancedFilterGroups,
        isAdvancedFilterGroupLoading: true,
        selectedAdvancedFilterGroup
      }
    }
  ),
  on(
    SharedActions.getAdvancedFilterGroupById,
    (state: SharedFilterState) => {
      return {
        ...state,
        isAdvancedFilterGroupLoading: true,
      }
    }
  ),
  on(
    SharedActions.getAdvancedFilterGroupByIdSuccess,
    (state: SharedFilterState, {advancedFilterGroup}) => {
      const advancedFilterGroups = getDeepCopyOfObject(state.advancedFilterGroups);
      const index = advancedFilterGroups.findIndex((afg: IAdvancedFilterGroup): boolean => afg.id === advancedFilterGroup.id);
      if (index > -1) {
        advancedFilterGroups[index] = advancedFilterGroup
      }
      return {
        ...state,
        advancedFilterGroups,
        isAdvancedFilterGroupLoading: false,
        selectedAdvancedFilterGroup: index > -1 ? advancedFilterGroups[index] : state.selectedAdvancedFilterGroup
      }
    }
  ),
  on(
    SharedActions.getAdvancedFilterGroupByIdFailure,
    (state: SharedFilterState, {error}) => {
      return {
        ...state,
        errors: [...state.errors, error],
        isAdvancedFilterGroupLoading: false,
        selectedAdvancedFilterGroup: state.advancedFilterGroups?.length > 0 ? state.advancedFilterGroups[0] : undefined
      }
    }
  ),
  on(
    SharedActions.getAdvancedFilterGroups,
    (state: SharedFilterState) => {
      return {
        ...state,
        advancedFilterIDCounter: (new Date()).getTime(),
        advancedFilterGroups: [],
        selectedAdvancedFilterGroup: undefined,
        isAdvancedFilterGroupLoading: true,
      }
    }
  ),
  on(
    SharedActions.getAdvancedFilterGroupsSuccess,
    (state: SharedFilterState, {advancedFilterGroups}) => {
      return {
        ...state,
        advancedFilterGroups: advancedFilterGroups || [],
        isAdvancedFilterGroupLoading: false,
        selectedAdvancedFilterGroup: advancedFilterGroups.length > 0 ? advancedFilterGroups[0] : undefined
      }
    }
  ),
  on(
    SharedActions.getAdvancedFilterGroupsFailure,
    (state: SharedFilterState, {error}) => {
      const advancedFilterGroups = getDeepCopyOfObject(state.advancedFilterGroups);
      const foundAdvancedFilterGroup = advancedFilterGroups.find((afg: IAdvancedFilterGroup): boolean => afg.id === state.selectedAdvancedFilterGroup.id);
      if (foundAdvancedFilterGroup) {
        foundAdvancedFilterGroup.saved = false;
      }
      return {
        ...state,
        advancedFilterGroups,
        errors: [...state.errors, error],
        isAdvancedFilterGroupLoading: false,
        selectedAdvancedFilterGroup: foundAdvancedFilterGroup ? foundAdvancedFilterGroup :
          advancedFilterGroups.length > 0 ? advancedFilterGroups[0] : undefined
      }
    }
  ),
  on(
    SharedActions.saveSelectedAdvancedFilterGroup,
    (state: SharedFilterState) => {
      return {
        ...state,
        isAdvancedFilterGroupLoading: true
      }
    }
  ),
  on(
    SharedActions.saveSelectedAdvancedFilterGroupSuccess,
    (state: SharedFilterState) => {
      const advancedFilterGroups = getDeepCopyOfObject(state.advancedFilterGroups);
      const foundAdvancedFilterGroup = advancedFilterGroups.find((afg: IAdvancedFilterGroup): boolean => afg.id === state.selectedAdvancedFilterGroup.id);
      if (foundAdvancedFilterGroup) {
        foundAdvancedFilterGroup.saved = true;
        foundAdvancedFilterGroup.lastModified = new Date(new Date().setMonth(new Date().getMonth() + 1));
      }
      let appliedAdvancedFilterGroup = state.appliedAdvancedFilterGroup ? getDeepCopyOfObject(state.appliedAdvancedFilterGroup) : undefined;
      if (appliedAdvancedFilterGroup && foundAdvancedFilterGroup.id === appliedAdvancedFilterGroup.id) {
        appliedAdvancedFilterGroup = foundAdvancedFilterGroup;
      }
      return {
        ...state,
        advancedFilterGroups,
        appliedAdvancedFilterGroup,
        isAdvancedFilterGroupLoading: true,
        selectedAdvancedFilterGroup: foundAdvancedFilterGroup ? foundAdvancedFilterGroup : undefined
      }
    }
  ),
  on(
    SharedActions.saveSelectedAdvancedFilterGroupFailure,
    (state: SharedFilterState, {error}) => {
      return {
        ...state,
        isAdvancedFilterGroupLoading: false,
        errors: [...state.errors, error]
      }
    }
  ),
  on(
    SharedActions.saveAdvancedFilterGroup,
    (state: SharedFilterState, {advancedFilterGroup}) => {
      const advancedFilterGroups = getDeepCopyOfObject(state.advancedFilterGroups);
      let index = advancedFilterGroups.findIndex((afg: IAdvancedFilterGroup): boolean => afg.id === advancedFilterGroup.id);
      if (index > -1) {
        advancedFilterGroups[index] = {
          ...advancedFilterGroup,
          saved: false,
          lastModified: new Date(new Date().setMonth(new Date().getMonth() + 1))
        };
      }
      return {
        ...state,
        advancedFilterGroups,
        selectedAdvancedFilterGroup: index > -1 ? advancedFilterGroups[index] : undefined
      }
    }
  ),
  on(
    SharedActions.setSelectedAdvancedFilterGroup,
    (state: SharedFilterState, {advancedFilterGroup}) => {
      const advancedFilterGroups = getDeepCopyOfObject(state.advancedFilterGroups);
      const selectedAdvancedFilterGroup = advancedFilterGroups.find((afg: IAdvancedFilterGroup): boolean => afg.id === advancedFilterGroup.id);
      return {
        ...state,
        selectedAdvancedFilterGroup
      }
    }
  ),
  on(
    SharedActions.setSelectedAdvancedFilterGroupOperator,
    (state: SharedFilterState, {operator, filterGroupId}) =>
      onSetSelectedAdvancedFilterGroupOperator(state, filterGroupId, operator)
  ),
  on(
    SharedActions.setAdvancedFilterGroupGrid,
    (state: SharedFilterState, {grid}) => {
      return {
        ...state,
        selectedAdvancedFilterGroupGrid: grid
      }
    }
  ),
  on(
    SharedActions.setFilterAfterValueChange,
    (state: SharedFilterState, {filter}) => {
      const selectedAdvancedFilterGroup = findAndReplaceFilterWithinAdvancedFilterGroup(getDeepCopyOfObject(state.selectedAdvancedFilterGroup), filter);
      selectedAdvancedFilterGroup.saved = false;
      selectedAdvancedFilterGroup.lastModified = new Date()
      const advancedFilterGroups = [...state.advancedFilterGroups];
      const index = advancedFilterGroups.findIndex((afg: IAdvancedFilterGroup): boolean => afg.id === selectedAdvancedFilterGroup.id);
      advancedFilterGroups[index] = selectedAdvancedFilterGroup;
      return {
        ...state,
        advancedFilterGroups,
        selectedAdvancedFilterGroup
      }
    }
  ),
);

export const sharedFilterReducer = (state: SharedFilterState, action: Action): SharedFilterState => createSharedFilterReducer(state, action);
