<app-modal-header title="Failed Auto Orders" [close]="close"></app-modal-header>

<ion-content>
<!--  useValue.alreadyChecked?.[orderId]-->
  <ion-grid id="messages" *ngIf="storesInfo && !creatingModal; else spinner">
    <ng-container *ngIf="failedAOMsgs && failedAOMsgs.length">
      <ng-container *ngFor="let message of failedAOMsgs; let i = index">
        <h5 *ngIf="i === 0 || message.sender !== failedAOMsgs[i - 1].sender"
          style="display: flex; justify-content: center; align-items: center"
        >
          {{ storesInfo.stores[message.sender].name }}
        </h5>
        <ion-row style="padding-top: 10px">
          <ion-col size="auto"><span>{{ dateStrings[i].d }}</span></ion-col>
          <ion-col size="auto"><span>{{ dateStrings[i].t }}</span></ion-col>
        </ion-row>
        <ion-grid class="msg-expansion">

          <app-msg-ao-response (onCheck)="onCheckboxChange(i, $event)"
            [embeddedUseValue]="{msgID: message.id,
                                 message: message,
                                 getStoreInfo: getStoreInfo,
                                 timeStamp: dateStrings[i].d + dateStrings[i].t,
                                 restoreState: {input: aggregatedData?.[i], checked: checkedBatches[i]}}"
          ></app-msg-ao-response>

        </ion-grid>
      </ng-container>
    </ng-container>
  </ion-grid>

  <ion-row class="reattempt-button">
    <ion-col>
      <ion-button color="light" [disabled]="numChecked <= 0 || creatingModal" (click)="openReattemptModal()">Resolve</ion-button>
      <ion-button color="light" [disabled]="!aggregatedData" (click)="submit()">Submit</ion-button>
    </ion-col>
  </ion-row>
</ion-content>

<ng-template #spinner>
  <div class="spinner">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>
