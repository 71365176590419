export const AllKeyTypes: { [key: string]: string } = {
  barcode: 'string',
  binL: 'string',
  createdDate: 'date',
  cratedHasSupp1: 'boolean',
  daysDiff: 'number',
  dep: 'string',
  desc: 'string',
  disabled: 'boolean',
  error: 'boolean',
  gp1: 'number',
  isEdited: 'boolean',
  isSelected: 'boolean',
  lastPurchase: 'date',
  lastSold: 'date',
  latestCost: 'number',
  lineColour: 'string',
  lineColourCode: 'number',
  lineColourDescription: 'string',
  lineColourValue: 'string',
  manuallyAdded: 'date',
  newlyAdded: 'boolean',
  nominalGP: 'number',
  onHand: 'number',
  onHoldCode: 'number',
  ordLvl: 'number',
  originalValue: 'object',
  packSize: 'number',
  prevCostPrice: 'number',
  prevGP: 'number',
  prvSellingPri: 'number',
  prvSellingPriDate: 'date',
  purchaseOrder: 'number',
  recommendedGP: 'number',
  regularSuppCode: 'string',
  sellPriExcl1: 'number',
  sellPriIncl1: 'number',
  store: 'string',
  stockId: 'string',
  storeId: 'string',
  subDep: 'string',
  suppCode: 'string',
  suppName: 'string',
  suppUsedLastDate1: 'date',
  suppUsedLastDate2: 'date',
  unitsYear: 'number',
  vatR: 'number',
};

export function getKeyType(property: string): string {
  if (property && AllKeyTypes[property]) {
    return AllKeyTypes[property];
  }
  return 'number';
}
