<app-modal-header title="Save / Load: {{ what ? what : 'User Settings' }}" [close]="onClose"></app-modal-header>

<ion-content class="special-no-scroll">
  <ng-container *ngIf="savedEntries; else loading">
    <ion-grid id="body-grid">
      <ng-container>
        <ion-row id="saved-settings-h" *ngIf="desc">
          <ion-col class="orange-msg">
            <p >{{desc}}</p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>Saved Settings</ion-col>
        </ion-row>
        <ion-row id="saved-settings" class="special-scroller">
          <ion-grid *ngIf="getNumberOfSavedDocuments; else noSaves">
            <ion-row *ngFor="let save of savedEntriesOrder" [class.selected]="selectedSavedEntry === save"
                     (click)="onSelectChange(save)" (dblclick)="onSelectAndLoadChange(save)">
              <ion-col>{{ save }}</ion-col>
              <ion-col class="save-details">
                {{ getDisplayDate(savedEntries[save]) }}
                <button [disabled]="save !== selectedSavedEntry" (click)="onDelete(save)">
                  <ion-icon name="trash-outline"></ion-icon>
                </button>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ng-template #noSaves>
            <ion-grid>
              <ion-row>
                <ion-col class="no-saves">No Saved Settings</ion-col>
              </ion-row>
            </ion-grid>
          </ng-template>
        </ion-row>
      </ng-container>
      <ion-row class="actions-row">
        <ion-col>
          <ng-container *ngIf="getSaveObj">
            <ion-input [formControl]="saveForm" (ionChange)="onInputChange()"></ion-input>
            <button [disabled]="saveForm.invalid">
              <ion-icon name="save-outline" size="large" (click)="onSaveEntry()"></ion-icon>
            </button>
          </ng-container>
          <ion-button size="small" shape="round" color="primary" [disabled]="!selectedSavedEntry" (click)="onLoadEntry()">Load</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
</ion-content>

<ng-template #loading>
  <div id="loading">
    <span>Loading Saves...</span>
    <ion-spinner></ion-spinner>
  </div>
</ng-template>
