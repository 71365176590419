/**
 * Type for recursive step function used in various operations.
 *
 * @member {function} thisFunc The recursive function itself.
 * @member {object} o The object to process.
 * @member {object} kv Optional key-value pairs for additional processing.
 */

type RecursiveStepFunc = (thisFunc: RecursiveStepFunc, o: object, kv?: { [key: string]: unknown } ) => object | void;

/**
 * Type representing a string or a number.
 */
type StrOrNum = string | number;

/**
 * @interface ObjectCleanableFlags
 * A gross way of keeping track of which suppliers have been processed on the current day.
 *
 * @member {boolean} null  delete keys with the value null
 * @member {boolean} undefinedV delete keys with the value undefined
 * @member {boolean} emptyStr delete keys with the value empty string
 * @member {boolean | '0'} zero delete keys with the value values 0. Setting this to '0' will delete keys where the value is 0 or '0'
 * @member {boolean} emptyArray delete keys with the value empty array (so [])
 * @member {boolean} emptyObj delete keys with the value of an empty object (so {})
 * processed.
 */

interface ObjectCleanableFlags {
  /** @member {boolean} null  delete keys with the value null */
  null?: boolean;
  /** @member {boolean} undefinedV delete keys with the value undefined */
  undefinedV?: boolean;
  /** @member {boolean} emptyStr delete keys with the value empty string */
  emptyStr?: boolean;
  /** @member {boolean | '0'} zero delete keys with the value values 0. Setting this to '0' will delete keys where the value is 0 or '0' */
  zero?: boolean | '0';
  /** @member {boolean} emptyArray delete keys with the value empty array (so []) */
  emptyArray?: boolean;
  /** @member {boolean} emptyObj delete keys with the value of an empty object (so {}) */
  emptyObj?: boolean;
}

/**
 * Function to clean an object by deleting keys based on specified flags.
 *
 * @member {T & object} obj - The object to be cleaned.
 * @member {ObjectCleanableFlags | 'all'} [cleanedValues={null: true, undefinedV: true}] - The flags indicating which values to clean.
 * @returns - The number of keys removed.
 */
export const cleanObj = <T>(
  obj: T & object, cleanedValues: ObjectCleanableFlags | 'all' = {null: true, undefinedV: true}
): number => {
  const cleanChecks: ((v: any) => boolean)[] = [];
  let cv: ObjectCleanableFlags;

  if (cleanedValues === 'all') {
    cv = {null: true, undefinedV: true, emptyStr: true, zero: '0', emptyArray: true, emptyObj: true};
  } else {
    cv = cleanedValues;
  }

  if (cv.null) {
    cleanChecks.push((v) => v === null);
  }
  if (cv.undefinedV) {
    cleanChecks.push((v) => v === undefined);
  }
  if (cv.emptyStr) {
    cleanChecks.push((v) => v === '');
  }
  if (cv.zero) {
    cleanChecks.push((v) => {
      switch (typeof v) {
        case 'number':
        case 'string':
          return +v === 0;
      }
      return false;
    });
  }
  if (cv.emptyArray) {
    cleanChecks.push((v) => Array.isArray(v) && v.length === 0);
  }
  if (cv.emptyObj) {
    cleanChecks.push((v) =>
      v !== null && Object.prototype.toString.call(v).toLowerCase() === '[object object]' && Object.keys(v).length === 0
    );
  }

  if (cleanChecks.length === 0) {
    throw Error('No checks provided to cleanObj');
  }
  let cleans = 0;

  const recursiveStep: RecursiveStepFunc = (rs: RecursiveStepFunc, o: object): void => {
    for (const key of Object.keys(o)) {
      if (
        typeof o[key] === 'object' && o[key] !== null &&
        Object.prototype.toString.call(o[key]).toLowerCase() === '[object object]'
      ) {
        recursiveStep(recursiveStep, o[key]);
      }

      for (const check of cleanChecks) {
        if (check(o[key])) {
          delete o[key];
          cleans++;
          break;
        }
      }
    }
  };
  recursiveStep(recursiveStep, obj);
  return cleans;
};

// TODO: how do I define the return type to be the same as obj type???
/**
 *
 * Function to copy an object with optional cleaning.
 *
 * @member {T & object} obj - The object to be copied.
 * @member {ObjectCleanableFlags | 'all'} [cleanedValues] - The flags indicating which values to clean before copying.
 * @returns - The copied object.
 */
export const copyObj = <T>(obj: T & object, cleanedValues?: ObjectCleanableFlags | 'all'): T => {
  if (cleanedValues) {
    cleanObj(obj, cleanedValues);
  }

  const recursiveStep: RecursiveStepFunc = (rs: RecursiveStepFunc, o: object): object => {
    const copy = {};

    for (const key of Object.keys(o)) {
      copy[key] = valueCopy(rs, o[key]);
    }
    return copy;
  };
  return recursiveStep(recursiveStep, obj) as T;
};

/**
 * Function to update an object with new values.
 *
 * @member {T & object} obj - The original object.
 * @member {Partial<T> | U} update - The update values.
 * @member {boolean} [inPlace=false] - Whether to update the object in place.
 * @returns {T | T & U} - The updated object.
 */

export const updateObj = <T, U>(obj: T & object, update: Partial<T> | U, inPlace: boolean = false): T | T & U => {
  const value: T = inPlace ? obj : copyObj(obj);

  const recursiveStep = (rs: RecursiveStepFunc, o: object, updates: { [key: string]: unknown }): object => {
    for (const k of Object.keys(updates)) {
      o[k] = valueCopy(rs, updates[k], updates[k] as any);
    }
    return o;
  };
  recursiveStep(recursiveStep, value as object, update as { [key: string]: unknown });
  return value as T | T & U;
};

/**
 * Tired of checking if chains of nested keys have been assigned to an object?
 * No More! Either create a new object providing a list of keys, or you can create the chain on an existing object.
 * Existing values in the chain will not be overwritten.
 * Optionally provide a value to assign to the last key in the chain.
 *
 * @example
 * // usual way
 * if (!obj[key0]) { obj[key0] = {}; }
 * if (!obj[key0]) { obj[key0] = {}; }
 * ...
 * // now
 * initObj([key0, key1, ...], obj);
 *
 * @member {string[]} path list of keys to be nested into the object.
 * @member {unknown} value ? an optional value for the last key in the chain
 * @member {object} obj ? an existing object to be checked/nested
 * @member {type} type ? not yet implemented.
 * @returns  - The initialized object.
 */
export const initObj = (path: string[], value?: unknown, obj?: object, type?: any): object => {
  const workObj = obj ? obj : {};
  let o = workObj;

  for (let i = 0; i < path.length -1; i++) {
    const key = path[i];

    if (!o[key]) { o[key] = {}; }
    o = o[key];
  }
  const lastKey = path[path.length - 1];

  if (value !== null) {
    o[lastKey] = value;
  } else {
    if (!o[lastKey]) { o[lastKey] = {}; }
  }
  return workObj;
};

export const objLen = (obj: object): null | number => obj ? Object.keys(obj).length : null;

/**
 * Copy the value at key `oldKey` in an object to `newKey`. If `removeOldKeys` is true, the `oldKey` will also be
 * removed from the object.
 *
 * @note AKA The Object Key Switcheroony.
 *
 * @member {object} obj An object on which to preform the switch.
 * @member {StrOrNum} oldKey The key to be replaced or have its value duplicated.
 * @member {StrOrNum} newKey The new key at which to store the value from `oldKey`
 * @member {boolean} removeOldKey (default = true) If true, the old key is removed.
 */
export const objSwitchKey = (obj: object, oldKey: StrOrNum, newKey: StrOrNum, removeOldKey: boolean = true) => {
  obj[newKey] = obj[oldKey];
  if (removeOldKey) { delete obj[oldKey]; }
};

/**
 * Replace a number of keys in an object with new keys. The new keys can be.
 * * a key-value look up object, where the keys of the object are old keys and the values are the new keys.
 * * an array of pairs of old and new keys (`[oldKey, newKey][]`) such that for on of the pairs `P`, `p[0]` is the old
 * key and `P[1]` is the new key.
 * * a function which takes a key of the object and returns the new key. This is usefull where the desire is to modify
 * the keys of an object. If the function returns `null` no switch takes place.
 *
 * @example
 * const f = (k: string) => k + '-copy';
 * const obj = {a: 1, b: 2};
 * objReKey(obj, f);
 * console.warn(obj); // {a-copy: 1, b-copy: 2}
 *
 * @note This function makes use of {@link objSwitchKey} to switch each key. To switch a single key, use
 * {@link objSwitchKey}.
 *
 * @member {object} obj
 * @member {{ [k: StrOrNum]: StrOrNum } | [StrOrNum, StrOrNum][] | ((k: StrOrNum) => StrOrNum | null)} keyReplacements
 * @member {boolean} removeOldKeys (default = true)
 */
export const objReKey = (
  obj: object,
  keyReplacements: { [k: StrOrNum]: StrOrNum } | [StrOrNum, StrOrNum][] | ((k: StrOrNum) => StrOrNum | null),
  removeOldKeys: boolean = true
) => {
  if (Array.isArray(keyReplacements)) {
    keyReplacements.forEach((keys: [StrOrNum, StrOrNum]) => objSwitchKey(obj, keys[0], keys[1], removeOldKeys));
  } else {
    switch (typeof keyReplacements) {
      case 'object':
        Object.keys(removeOldKeys).forEach((oldKey) =>
          objSwitchKey(obj, oldKey, keyReplacements[oldKey], removeOldKeys));
        break;
      case 'function':
        const oldKeys = Object.keys(obj);

        for (const oldKey of oldKeys) {
          const newKey = keyReplacements(oldKey);

          if (newKey !== null && newKey !== oldKey) {
            objSwitchKey(obj, oldKey, newKey, removeOldKeys);
          }
        }
        break;
    }
  }
};

/**
 * Convert an array of filter functions into a single filter function.
 *
 * @member {((element: any) => boolean)[]} filters - The array of filter functions.
 * @member {boolean} [assertNotEmpty=true] - If true, an error is thrown if the filters array is empty.
 * @returns {(element: any) => boolean} - The combined filter function.
 */

export const filters2Filter = (filters: ((element: any) => boolean)[], assertNotEmpty: boolean = true): (element: any) => boolean => {
  if (assertNotEmpty) {
    if (!(filters && filters.length)) {
      throw new Error('No filters passed to filters2Filter. Expected (filters: ((element: any) => boolean)[], ' +
        `got ${typeof filters}`);}
  }

  return (element: any) => {
    for (const f of filters) {
      if (!f(element)) { return false; }
    }
    return true;
  };
};

/**\
 * Insert a value into an array at a specific index.
 * @param arr The array to modify.
 * @param index The index in which to insert the item.
 * @param value The value to insert.
 */
export const arrayInsert = <T>(arr: T[], index: number, value: T): T[] =>
  arr.slice(0, index).concat(value).concat(arr.slice(index));

/**
 * Remove an element from an array.
 *
 * @member {T[]} arr - The array to remove from.
 * @member {T | ((element: T) => boolean)} element - The element to remove.
 * @member {number} [fromIndex=0] - The index to start the search from.
 * @member {boolean} [reverse] - If true, search from the end of the array.
 * @member {(a: T, b: T) => boolean} [comp] - Custom comparison function (not yet implemented).
 * @member {boolean} [inPlace=true] - If true, modify the array in place.
 */

export const arrayRemove = <T>(
  arr: T[], element: T | ((element: T) => boolean), fromIndex: number = 0,
  reverse?: boolean,
  comp?: (a: T, b: T) => boolean,
  inPlace: boolean = true
) => {

  if (comp) {
    throw Error('Custom comparison function not yet implemented.');
  }

  if (!inPlace) {
    throw Error('Non in place remove not yet implemented.');
  }

  let idx = -1;
  const fi = reverse ? arr.length - 1 - fromIndex : fromIndex;

  if (typeof element === 'function') {
    const find = element as (element: T) => boolean;

    if (reverse) {
      for (let i = fi; i >= 0; i--) {
        if (find(arr[i])) {
          idx = i;
          break;
        }
      }
    } else {
      for (let i = fi; i < arr.length; i++) {
        if (find(arr[i])) {
          idx = i;
          break;
        }
      }
    }
  } else {
    if (reverse) {
      idx = arr.indexOf(element, fi);
    } else {
      idx = arr.lastIndexOf(element, fi);
    }
  }

  if (idx > -1) {
    arr.splice(idx, 1);
  }
  return idx;
};

/* -------------------------------------------- PRIVATE HELPER FUNCTIONS -------------------------------------------- */

/**
 * Copy the values in an array.
 *
 * @member {RecursiveStepFunc} rs - The recursive step function.
 * @member {unknown[]} a - The array to copy.
 * @member {{ [key: string]: unknown }} [kw] - Optional key-value pairs.
 * @returns {any[]} - The copied array.
 */

const arrayCopy = (rs: RecursiveStepFunc, a: unknown[], kw?: { [key: string]: unknown }): any[] =>
  a.map((v) => valueCopy(rs, v, kw));

// TODO: Copying of object within an array does not seem to work correctly

/**
 * Copy the value.
 *
 * @member {RecursiveStepFunc} rs - The recursive step function.
 * @member {unknown} v - The value to copy.
 * @member {{ [key: string]: unknown }} [kw] - Optional key-value pairs.
 * @returns - The copied value.
 */
const valueCopy = (rs: RecursiveStepFunc, v: unknown, kw?: { [key: string]: unknown }): any => {
  if (
    typeof v === 'object' && v !== null &&
    Object.prototype.toString.call(v).toLowerCase() === '[object object]'
  ) {
    return rs(rs, v, kw);
  } else if (Array.isArray(v)) {
    return arrayCopy(rs, v, kw);
  }
  return v;
};
