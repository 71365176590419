import {Component, Input} from '@angular/core';

/**
 * A component that renders an SVG icon for "Clipboard"
 *
 * @member {string} tooltip ? The tooltip that needs to be displayed on this icon.
 */

@Component({
  selector: 'app-icon-clipboard',
  templateUrl: './icon-clipboard.component.html',
  styleUrls: ['./icon-clipboard.component.scss'],
})
export class IconClipboardComponent {

  /**
   * Assign a tooltip that needs to be displayed on this icon as soon as the user hovers on it.
   *
   * @param tooltip ? The tooltip that needs to be displayed on this icon.
   */
  @Input() tooltip?: string;
}
