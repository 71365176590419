import {Component, Input, OnInit} from '@angular/core';
import {AlertController, IonNav, ModalController, PopoverController, ToastController} from '@ionic/angular';
import {Message, StoreInfo} from '../../../../shared-utilities/models-old/datastructures';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {MessagesService} from '../../../../shared-utilities/services-old/message-service/messages.service';
import {
  CollectionUsersSettingsService,
} from '../../../../shared/shared-services/firebase/collection-users-settings.service';
import {Store} from '@ngrx/store';
import {
  path_users_userId_settings_stores_storeId_mailer_settings,
} from '../../../../shared/shared-services/database-paths';
import {selectUser} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {IUser} from '../../../../shared/shared-models/user-access/user';
import {IEmailSettings, IEmailSettingsTest} from '../../../../features/settings/models/settings-models';
import {OverlayEventDetail} from '@ionic/core';
import {take} from 'rxjs/operators';
import {getAllUserEmailSettings} from '../../../../features/settings/store/settings.actions';
import {
  selectAllUsersEmailSettingForCurrentSelectedStore,
} from '../../../../features/settings/store/settings.selectors';
import {
  AnimationDefault,
  AnimationDirection,
  Animations,
  IAnimation,
} from '../../../../shared/shared-models/animations/animation';

@Component({
  selector: 'app-automation-settings-email',
  templateUrl: './automation-settings-email.component.html',
  styleUrls: ['./automation-settings-email.component.scss'],
})
export class AutomationSettingsEmailComponent implements OnInit {
  @Input() storeId: string;
  @Input() storeInfo?: StoreInfo;
  thisUser: string;
  settings: { [storeId: string]: { [userId: string]: IEmailSettings } } = {};

  storeName: string;
  saving: boolean;
  testing: string;

  hide: { [something: string]: boolean } = {mailer: true, iqLogin: true};

  settingsForm: FormGroup;
  formCache: { [storeId: string]: { [userId: string]: IEmailSettings } } = {};
  changed: { [storeId: string]: { [userId: string]: { [path: string]: boolean } } } = {};

  gmail: { [storeId: string]: { [userId: string]: boolean } } = {};
  defaultStatus: { [storeId: string]: { [userId: string]: boolean } } = {};
  copiedValue: IEmailSettings;
  default: { [storeId: string]: FormGroup } = {};

  readonly help = {
    mailer: {
      email: null,
      user: null,
      pwd: null,
      smtp: {port: null, server: null, sslOnly: null},
      imap: {port: null, server: null, sentBox: null, sslOnly: null},
    },
  };

  editableUsers: { [userId: string]: IEmailSettings } = {};
  user: IUser;
  userId: string;
  isUserEmailSettingsLoading: boolean;

  readonly animationLogo: IAnimation = {
    ...AnimationDefault,
    animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
    direction: AnimationDirection.REVERSE,
    fixedDimensions: {width: 1, height: 1},
    backgroundColour: 'var(--ion-color-white)',
    id: 'app-automation-settings-email-component-animation-logo',
  };

  constructor(
    private firebase: FirebaseService,
    private msgService: MessagesService,
    private modalController: ModalController,
    private nav: IonNav,
    private alertControl: AlertController,
    private toastControl: ToastController,
    private collectionUsersSettingsService: CollectionUsersSettingsService,
    private popControl: PopoverController,
    private readonly store: Store,
  ) {
  }

  private static astrixArise(length: number): string {
    return '*'.repeat(length);
  }

  async ngOnInit(): Promise<void> {
    this.isUserEmailSettingsLoading = true;
    this.defaultStatus[this.storeId] = {};
    this.settings[this.storeId] = {};
    this.gmail[this.storeId] = {};
    this.user = await this.store.select(selectUser).pipe(take(1)).toPromise();
    this.store.dispatch(getAllUserEmailSettings());
    this.store.select(selectAllUsersEmailSettingForCurrentSelectedStore).subscribe((settings) => {
      if (settings) {
        this.isUserEmailSettingsLoading = false;
      }
      this.settings[this.storeId] = {};
      this.defaultStatus[this.storeId] = {};
      this.changed[this.storeId] = {};
      this.formCache[this.storeId] = {};

      this.editableUsers = {...settings};

      for (const userId of Object.keys(this.editableUsers)) {
        this.changed[this.storeId][userId] = {};
        if (settings[userId]) {
          this.settings[this.storeId][userId] = settings[userId];

          if (settings[userId].email) {
            this.defaultStatus[this.storeId][userId] = false;
          } else {
            this.settings[this.storeId][userId] = {} as IEmailSettings;
          }
        } else {
          this.settings[this.storeId][userId] = {} as IEmailSettings;
          this.defaultStatus[this.storeId][userId] = true;
        }
        this.gmail[this.storeId][userId] = this.isGmail(
          this.settings[this.storeId][userId],
        );
      }
    });


  }

  close = (): void => {
    void this.modalController.dismiss().then();
  };

  accordionChange(event: Event): void {
    const customEvent = event as CustomEvent<{ value: string }>;
    if (this.settingsForm) {
      this.formCache[this.storeId][this.userId] = this.settingsForm.value;
    }
    this.userId = customEvent.detail.value;

    if (!this.userId) {
      return;
    }
    const s = this.editableUsers[this.userId];

    const req = Validators.required;
    this.settingsForm = new FormGroup({
      email: new FormControl(s.email, [req]),
      user: new FormControl(
        s.user,
        Validators.pattern(/^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
      ),
      pwd: new FormControl(
        AutomationSettingsEmailComponent.astrixArise(s.pwd?.length || 0),
      ),
      smtp: new FormGroup({
        port: new FormControl(s.smtp?.port),
        server: new FormControl(s.smtp?.server),
        sslOnly: new FormControl(s.smtp?.sslOnly),
      }),
      imap: new FormGroup({
        port: new FormControl(s.imap?.port),
        server: new FormControl(s.imap?.server),
        sentBox: new FormControl(s.imap?.sentBox),
        sslOnly: new FormControl(s.imap?.sslOnly),
      }),
    });
    this.gmail[this.storeId][this.userId] = this.isGmail(s);

    if (this.gmail[this.storeId][this.userId]) {
      this.settingsForm.get('imap.server').setValue('');
      this.settingsForm.get('imap.port').setValue('');
      this.settingsForm.get('imap.sentBox').setValue('');
    }
  }

  copy(): void {
    this.copiedValue = this.settingsForm.value as IEmailSettings;
    this.copiedValue.pwd = null;
  }

  paste(): void {
    if (this.copiedValue !== undefined) {
      this.settingsForm.setValue(this.copiedValue);
    }
  }

  trackByFunction(index: number): number {
    return index;
  }

  savingCB(saving: boolean = true): void {
    this.saving = saving;
    const elements = document.getElementsByClassName('saving-disable');
    const opacity = saving ? '50%' : '100%';

    for (let i = 0; i < elements.length; i++) {
      (elements.item(i) as HTMLElement).style.opacity = opacity;
    }
  }

  changeEvt(
    event: Event,
    path: string,
    userId: string = this.userId,
    storeId: string = this.storeId,
  ): void {
    event.stopPropagation();
    const customEvent = event as CustomEvent<{ value: string }>;
    if (path.endsWith('server')) {
      this.gmail[storeId][userId] = this.isGmail(
        this.settingsForm.value as IEmailSettings,
      );

      if (this.gmail[storeId][userId]) {
        this.settingsForm.get('imap.server').setValue('');
        this.settingsForm.get('imap.port').setValue('');
        this.settingsForm.get('imap.sentBox').setValue('');
      }
    }

    const newValue = customEvent.detail.value;
    let v: any = this.settings[storeId][userId];
    if (!this.changed[storeId][userId]) {
      this.changed[storeId][userId] = {};
    }

    for (const p of path.split('.')) {
      if (v?.[p]) {
        v = v[p];
      } else {
        this.changed[storeId][userId][path] = true;
        return;
      }
    }
    this.changed[storeId][userId][path] = newValue !== v;
  }

  save(storeId: string = this.storeId, userId: string = this.userId): void {
    if (this.settingsForm.invalid) {
      return;
    }
    const updates = this.settingsForm.value as IEmailSettings;

    if (!(updates.imap?.server && updates.imap.port)) {
      delete updates.imap;
    }

    if (updates.pwd) {
      let valid = false;

      for (const ch of updates.pwd) {
        if (ch !== '*') {
          valid = true;
          break;
        }
      }

      if (!valid) {
        delete updates.pwd;
      }
    }

    if (Object.keys(updates).length) {
      this.savingCB();
      this.saving = true;
      void this.collectionUsersSettingsService
        .setDocument<IEmailSettings>(
          path_users_userId_settings_stores_storeId_mailer_settings(userId, storeId),
          updates,
        )
        .then(() => {
          this.savingCB(false);
          this.saving = false;
        });
    }
  }

  async testEmail(): Promise<void> {
    let recipientEmail: string;

    while (!recipientEmail) {
      const alertControl = await this.alertControl.create({
        header: 'Test Email Account',
        subHeader: 'Please enter a recipient email below.',
        message:
          'The recipient will receive a test email to confirm your account is working.',
        cssClass: 'custom-alert',
        inputs: [{name: 'email', type: 'text', placeholder: 'Test recipient email'}],
        buttons: ['Cancel', {text: 'Test', role: 'y'}],
      });
      await alertControl.present();
      const response: OverlayEventDetail = await alertControl.onDidDismiss();

      if (response.role === 'y') {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(response.data.values.email)
        ) {
          recipientEmail = response.data.values.email;
        } else {
          const ac2 = await this.alertControl.create({
            header: 'Invalid Email Address',
            subHeader: 'The following is not a valid email address:',
            message:
              response.data.values.email !== ''
                ? response.data.values.email
                : 'empty string',
            buttons: ['ok'],
            cssClass: 'custom-alert',
          });
          await ac2.present();
          await ac2.onDidDismiss();
        }
      } else {
        return;
      }
    }
    this.testing = 'EMAIL';
    let failed = false;

    const handleFail = (msg: Message): void => {
      failed = true;
      void this.alertControl
        .create({
          header: 'Test Failed',
          subHeader:
            'The server was unable to send the test email using the details you provided.',
          message: msg.payload.body,
          cssClass: 'custom-alert',
          buttons: ['Ok'],
        })
        .then((hfc) =>
          hfc.present().then(() => hfc.onDidDismiss().then(() => (this.testing = null))),
        );
    };

    const data: IEmailSettingsTest = this.settingsForm.value as IEmailSettingsTest;

    if (!(data.imap?.server && data.imap.port)) {
      delete data.imap;
    }

    let valid = false;

    for (const ch of data.pwd) {
      if (ch !== '*') {
        valid = true;
        break;
      }
    }

    if (!valid) {
      const ac = await this.alertControl.create({
        header: 'Please reenter password for testing',
        subHeader:
          "Your password isn't actually here, it's encrypted and hidden away.",
        message: 'This step is temporary',
        cssClass: 'custom-alert',
        buttons: ['ok'],
      });
      await ac.present();
      this.saving = false;
      this.testing = null;
      return;
    }
    data.recipient = recipientEmail;

    this.msgService.testServer(this.storeId, 'EMAIL', data, handleFail).subscribe(
      (msg) => {
        void this.toastControl
          .create({
            header: 'Testing Email',
            message: msg,
            cssClass: 'custom-toast',
            duration: 3000,
          })
          .then((tc) => tc.present());
      },
      (e) => {
        this.testing = null;
        void this.alertControl
          .create({
            header: 'Test Error',
            subHeader: e.message,
            message: 'Inform Techodactyl Support',
            buttons: ['Ok'],
            cssClass: ['custom-alert', 'error-alert'],
          })
          .then((ec) => {
            void ec.present();
            console.error(e);
          });
      },
      () => {
        this.testing = null;

        if (!failed) {
          void this.alertControl
            .create({
              header: 'Email Test Successful',
              message: 'The server managed to use the set up email account successfully',
              cssClass: 'custom-alert',
              buttons: ['Ok'],
            })
            .then((ac) => ac.present());
        }
      },
    );
  }

  invalid(): void {
    const dig = (fg: FormGroup): void => {
      for (const key of Object.keys(fg.controls)) {
        const control = fg.controls[key];

        if (control instanceof FormGroup) {
          dig(control);
        } else {
          // Additional logic if needed
        }
      }
    };
    dig(this.settingsForm);
  }

  private isGmail(emailInfo: IEmailSettings): boolean {
    return (
      emailInfo &&
      ((emailInfo.imap?.server?.toLowerCase().includes('gmail.com')) ||
        (emailInfo.smtp?.server?.toLowerCase().includes('gmail.com')))
    );
  }
}
