
export enum GridHeaderMenuActionTypes {
  editing = 'editing',
  editStart = 'edit-start',
  editStop = 'edit-stop',
  hide = 'hide',
  mainButton = 'main-button',
  roar = 'roar',
  priceBandingStart = 'price-banding-start',
  priceBandingStop = 'price-banding-stop',
  sortButton = 'sort-main-button',
  sortAsc = 'sort-asc',
  sortDesc = 'sort-desc',
  sortUnSort = 'sort-unsort',
  unHide = 'un-hide'
}
