import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-successful-event-fab',
  templateUrl: './successful-event-fab.component.html',
  styleUrls: ['./successful-event-fab.component.scss'],
})
export class SuccessfulEventFabComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() Response: { count: number; type: 'AO' | 'API' };
  @Input() count: number;
  @Input() type: 'AO' | 'API';
  @Output() onFabClick: EventEmitter<void> = new EventEmitter();

  colour: string;

  constructor() { }z

  ngOnInit() {
    this.colour = this.type === 'AO' ? 'success' : 'secondary';
  }
}
