<app-modal-header title="Resolve Unlinked {{ typeText }}s" [close]="closeModal"></app-modal-header>
<div *ngIf="noLinks.length > 0" class="no-links">
  <div style="max-height: 190px">
    <h6 style="text-align: center"><b>The following {{ typeText }}s have no links ((<< OF STORE ? >>)), and their are now entries using the same IDs either.<br>
      They will not be copied<b></b><br><br>
      If you wish to create links for these {{ typeText }}s please do so in the Store Linkages tool in settings.</b>
    </h6>
    <ul>
      <li *ngFor="let dep of noLinks; let i = index">
        {{ dep }}
      </li>
    </ul>
  </div>
</div>
<div class="modal-body">
  <div *ngFor="let suggestion of suggestLinks; let i = index" class="clearfix">
    <span>{{ typeText }} <b><i>({{ suggestion.storeA.depId }}) - {{ suggestion.storeA.name }}</i></b> does not have any links for this store.<br>
      <b><i>({{ suggestion.storeB.depId }}) - {{ suggestion.storeB.name }}</i></b> has the same ID.<br>
      Do you want to use this {{ typeText }}
    </span>
    <div class="button-div">
      <ion-button (click)="addDepartment(i)">Yes</ion-button>
      <ion-button (click)="removeDepartmentFromView(i)">No</ion-button>
    </div>
  </div>
</div>

