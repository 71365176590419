import {createAction, props} from '@ngrx/store';
import {IStore} from '../../../shared/shared-models/store/store';
import {ISupplier} from '../../../shared/shared-models/stock/suppliers';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IColleagues} from '../../../shared/shared-models/user-access/user-access';
import {IStorage} from '../../../shared/shared-models/storage/storage';

// ====================================================================================================
// Get Store
// ====================================================================================================

export const getStoreSuppliersByUserSelectedStore = createAction(
  '[Core Shared][Suppliers] Get Store Suppliers By User Selected Store',
);

export const getStoreSuppliersByStore = createAction(
  '[Core Shared][Suppliers] Get Store Suppliers By Store',
  props<{ store: IStore }>(),
);

export const getStoreSuppliersByStoreSuccess = createAction(
  '[Core Shared][Suppliers] Get Store Suppliers By Store Success',
  props<{ suppliers: { [supplierId: string]: ISupplier }, store: IStore }>(),
);

export const getStoreSuppliersByStoreFailure = createAction(
  '[Core Shared][Suppliers] Get Store Suppliers By Store Failure',
  props<{ error: IError }>(),
);


// ====================================================================================================
// Get Colleagues
// ====================================================================================================
export const getColleagues = createAction(
  '[Core Shared][Colleagues] Get Colleagues',
);

export const getColleaguesSuccess = createAction(
  '[Core Shared][Colleagues] Get Colleagues Success',
  props<{ users: IColleagues }>(),
);

export const getColleaguesFailure = createAction(
  '[Core Shared][Colleagues] Get Colleagues Failure',
  props<{ error: IError }>(),
);


export const killColleaguesService = createAction(
  '[Core Shared][Colleagues] Kill Colleagues Service',
);

// ====================================================================================================
// Firestore Storage
// ====================================================================================================
export const getDownloadUrlFromStorage = createAction(
  '[Core Shared][Storage] Get Download URL from Storage',
  props<{ storage: IStorage }>(),
);

export const getDownloadUrlFromStorageSuccess = createAction(
  '[Core Shared][Storage] Get Download URL from Storage Success',
  props<{ storage: IStorage }>(),
);

export const getDownloadUrlFromStorageFailure = createAction(
  '[Core Shared][Storage] Get Download URL from Storage Failure',
  props<{ error: IError }>(),
);
