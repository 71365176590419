import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

export interface PaginationValues {
  nbHits: number;
  nbPages: number;
  page: number;
  hitsPerPage?: number;
}

export interface PaginationEmission {
  target: SimplePaginationComponent;
  value: PaginationValues;
}

@Component({
  selector: 'app-simple-pagination',
  templateUrl: './simple-pagination.component.html',
  styleUrls: ['./simple-pagination.component.scss'],
})
export class SimplePaginationComponent implements OnInit, OnDestroy {

  @Input() pagination: PaginationValues = {nbHits: 0, nbPages: 0, page: 0};

  @Output() onchange = new EventEmitter<PaginationEmission>();
  @Input() externalChanges?: Observable<PaginationValues>;

  page = 0;

  private subscription: Subscription;

  constructor() {}

  ngOnInit() {
    // this.page = this.pagination.nbPages === 0 ? 0 : this.pagination.page + 1;
    this.page = this.pagination.page +1;

    if (this.externalChanges) {
      this.subscription = this.externalChanges.subscribe((v) => {
        this.pagination = v;
        this.page = v.page + 1;
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  paginate(direction: 'forward' | 'backward', max: boolean = false) {
    if (max) {
      this.pagination.page = (direction === 'forward' ? this.pagination.nbPages -1 : 0);
    } else {
      this.pagination.page += (direction === 'forward' ? 1 : -1);

      if (this.pagination.page >= this.pagination.nbPages) {
        this.pagination.page = 0;
      } else if (this.pagination.page < 0) {
        this.pagination.page = this.pagination.nbPages -1;
      }
    }
    this.page = this.pagination.page +1;

    this.onchange.emit({target: this, value: this.pagination});
  }
}
