<div class="item-filter-container">

  <ion-item>
    <ion-select
      interface="popover"
      placeholder="Select A Column"
      (ionChange)="onColumnChange($event)"
      [value]="filter.column">
      <ion-select-option
        *ngFor="let col of colDefs; trackBy: trackByFunction"
        [value]="col.field">
        {{ col.headerName }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-select
      interface="popover"
      placeholder="( Blank )"
      (ionChange)="onFilterTypeChange($event)"
      [disabled]="!filter.column"
      [value]="filter.filterType">
      <ion-select-option
        *ngFor="let type of filterTypes; trackBy: trackByFunction"
        [value]="type.description">
        {{ type.description }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ng-container *ngIf="getFilterTypeInputCount === 'single'">
      <ion-input
        *ngIf="filter.columnType !== 'boolean'"
        placeholder="Enter a Value"
        (ionBlur)="onValueChange()"
        [type]="filter.columnType"
        [disabled]="!filter.column || !filter.filterType"
        [(ngModel)]="filter.value">
      </ion-input>
      <ion-checkbox *ngIf="filter.columnType === 'boolean'" [(ngModel)]="filter.value"></ion-checkbox>
    </ng-container>
    <ng-container *ngIf="getFilterTypeInputCount === 'multiple' && filter.columnType !== 'boolean' ">
      <!-- Todo: We have to look at Date inputs and Multi Inputs @Clayden -->
      <ion-input placeholder="Enter Values" [type]="filter.columnType"></ion-input>
    </ng-container>
  </ion-item>

  <ion-button fill="clear" (click)="onDeleteItemClick()">
    <app-icon-delete-outline
      [tooltip]="'Delete Filter'">
    </app-icon-delete-outline>
  </ion-button>

</div>

