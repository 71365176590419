import {DisabledRule, DisabledRules, StockItem} from '../../models-old/datastructures';
import {NumberUtils} from './number-utils';
import {StockUtils} from './stock-utils';
import {ObjectUtils} from './object-utils';
import {UiUtils} from './ui-utils';
import {blankNGPReport, NGPReport} from '../../models-old/ngp-reports/ngp-report';
import {StockFunctions} from '../../functions-old/stock-functions';


/**
 * @class ReportUtils
 * Utilities for manipulating and displaying reports.

 * @member convertStockItemsToNGPReports Converts a StockItem to an NGPReport.
 * @member calculateDayDifferenceCreatedDateAndSuppDates Calculates the difference in days between the createdDate and suppUsedLastDate1/suppUsedLastDate2.
 * @member calculateGPValues Calculate the GP Values for an NGP Report with a Stock Item.
 */
export class ReportUtils {


  static convertStockItemsToNGPReports(stockItems: StockItem[], storeId: string): NGPReport[] {
    if (stockItems?.length < 1) {
      return [];
    }
    const reports: NGPReport[] = [];
    stockItems.forEach((stock: StockItem) => {
      const item: NGPReport = ObjectUtils.deleteNonObjectProperties<NGPReport, StockItem>(stock, blankNGPReport);
      const itemCalculated: NGPReport = ReportUtils.doReportCalculations(stock, item, storeId);
      reports.push({
        ...itemCalculated,
        lineColour: UiUtils.convertLineColourCodeToClassString(item.lineColourCode, item.storeId, 'lc lc-'),
      });
    });
    return reports;
  }

  /**
   * Do report calculations on the NGP Report
   * Calculations done: [`createdDate`, `suppUsedLastDate1`, `suppUsedLastDate2`, `daysDiff`, `gpValues`, `costMiss`]
   * Functions used: [`calculateDayDifferenceCreatedDateAndSuppDates`, `calculateGPValues`]
   *
   * @param stockItem A stock item to be used to do calculations on an NGPReport
   * @param ngpReport The NGP Report to add the calculations to.
   * @returns The NGPReport with calculations.
   */
  static doReportCalculations(stockItem: StockItem, ngpReport: NGPReport, storeId: string): NGPReport {
    const createdDate = ngpReport?.created ? new Date(ngpReport.createdDate) : null;
    const suppUsedLastDate1 = ngpReport?.suppUsedLastDate1 ? new Date(ngpReport.suppUsedLastDate1) : null;
    const suppUsedLastDate2 = ngpReport?.suppUsedLastDate2 ? new Date(ngpReport.suppUsedLastDate2) : null;
    const daysDiff = this.calculateDayDifferenceCreatedDateAndSuppDates(createdDate, suppUsedLastDate1, suppUsedLastDate2);
    const gpValues = this.calculateGPValues(stockItem, ngpReport, 2, storeId);
    const costMiss = (Number(stockItem.suppUsedLastPrice1).toFixed(2) !== Number(stockItem.latestCost).toFixed(2));

    return {
      ...ngpReport,
      created: daysDiff.isCreated,
      createdDate,
      cratedHasSupp1: daysDiff.createdHasSupplier1,
      costMiss,
      daysDiff: daysDiff.diff,
      diffGP: gpValues.differenceGP,
      nominalGP: gpValues.nominalGP,
      prevCostPrice: gpValues.prevCostPrice,
      prevGP: gpValues.previousGP,
      suppUsedLastDate1,
      suppUsedLastDate2
    };
  }

  /**
   * Calculates the difference in days between the createdDate and suppUsedLastDate1/suppUsedLastDate2
   * This will most likely always be used on NGP Reports and Stock Items.
   *
   * @param createdDate The created date for the Stock Item.
   * @param suppUsedLastDate1 The supplier last used date 1.
   * @param suppUsedLastDate2 The supplier last used date 2.
   * @returns an object of `{ diff: number, isCreated: boolean, createdHasSupplier1: boolean }`.
   */
  static calculateDayDifferenceCreatedDateAndSuppDates(createdDate: Date, suppUsedLastDate1: Date, suppUsedLastDate2: Date): {
    diff: number;
    isCreated: boolean;
    createdHasSupplier1: boolean;
  } {
    const timeCalculation: number = 1000 * 60 * 60 * 24;
    let daysDiff = 0;
    let isCreated = false;
    let createdHasSupplier1 = false;
    if (suppUsedLastDate1) {
      if (createdDate && (!suppUsedLastDate2 || createdDate.getTime() > suppUsedLastDate2.getTime())) {
        if (suppUsedLastDate1.getTime() >= createdDate.getTime()) {
          createdDate.setHours(0, 0, 0, 0);
          daysDiff = Math.round((suppUsedLastDate1.getTime() - createdDate.getTime())) / timeCalculation;
        } else {
          createdHasSupplier1 = true;
        }
        isCreated = true;
      } else if (suppUsedLastDate2) {
        daysDiff = Math.round((suppUsedLastDate1.getTime() - suppUsedLastDate2.getTime()) / timeCalculation);
      }
    }
    return {
      diff: daysDiff,
      isCreated,
      createdHasSupplier1
    };
  }

  /**
   * Calculate the GP Values for an NGP Report with a Stock Item.
   * This will most likely always be used on NGP Reports and Stock Items.
   *
   * @param stockItem The stock item used to calculate the GP.
   * @param ngpReport The NGP Report used to calculate the GP.
   * @param decimalDigits To how many decimal points should the value be formatted.
   * @param storeId The id for the store.
   * @returns an object of `{ nominalGP: number, prevCostPrice: number, previousGP: number, differenceGP: number }`
   * Deprecated created calculateGPFromPRice to be used
   */
  static calculateGPValues(stockItem: StockItem, ngpReport: NGPReport, decimalDigits: number, storeId: string): {
    nominalGP: number;
    prevCostPrice: number;
    previousGP: number;
    differenceGP: number;
  } {
    const sULP1 = +stockItem.suppUsedLastPrice1;
    const sULP2 = +stockItem.suppUsedLastPrice2;

    const prevCostPrice = sULP2;
    const convertedVatR = StockFunctions.getVatRateForStore(storeId);
    const nominalGP = StockUtils.calculateGP(convertedVatR[stockItem.vatR].vatRate, stockItem.sellPriIncl1, stockItem.latestCost);

    const previousGP = sULP2 ? StockUtils.calculateGP(convertedVatR[stockItem.vatR].vatRate, stockItem.sellPriIncl1, sULP2) : 0;
    const differenceGP = sULP2
      ? ((ngpReport.sellPriExcl1 - sULP1) / ngpReport.sellPriExcl1 * 100 - (ngpReport.sellPriExcl1 - sULP2) / ngpReport.sellPriExcl1 * 100)
      : 0;

    const diffGP = nominalGP - previousGP;

    return {
      nominalGP: +NumberUtils.formatNumberToDecimals(nominalGP, decimalDigits),
      prevCostPrice: +NumberUtils.formatNumberToDecimals(prevCostPrice, decimalDigits),
      previousGP: +NumberUtils.formatNumberToDecimals(previousGP, decimalDigits),
      differenceGP: +NumberUtils.formatNumberToDecimals(diffGP, decimalDigits)
    };
  }

  static calculatePriceFromGP(storeId: string, ngpReport: NGPReport | StockItem, newGP?: number): number {
    const vatRateObject = StockFunctions.getVatRateForStore(storeId);
    const vatR = (vatRateObject[ngpReport.vatR].vatRate / 100);
    const gp = ![undefined, null].includes(newGP) ? newGP : (
      ![undefined, null].includes(ngpReport.nominalGP) ? ngpReport.nominalGP : ngpReport.gp1
    );
    const x = -((100 * ngpReport.latestCost) / (gp - 100));
    const price = x * (1 + vatR);
    return Math.round(price * 100) / 100;
  }

  static determineIfItemIsDisabled(stockItem: StockItem, rules: DisabledRules): boolean {
    return StockFunctions.isDisabled(stockItem, rules)
  }

  static applyDescPrefix = (
    report: NGPReport,
    rule: DisabledRule,
    enable: boolean,
    defaults: { onHoldCode: number; lineColourCode: string } = {
      onHoldCode: 0,
      lineColourCode: report.originalValue.lineColourCode.value as string,
    }
  ) => {
    if (enable) {
      if (rule.descPrefix !== undefined && rule.descPrefix !== null) {
        if (report.desc && report.desc.trim().startsWith(rule.descPrefix)) {
          report.desc = report.desc.substring(rule.descPrefix.length + 1);
        }
      }
    } else {
      if (rule.descPrefix !== undefined && rule.descPrefix !== null) {
        if (report.desc && !report.desc.trim().startsWith(rule.descPrefix)) {
          report.desc = rule.descPrefix + ' ' + report.desc;
        }
      }
    }
  };

  static applyDescSuffix = (
    report: NGPReport,
    rule: DisabledRule,
    enable: boolean,
    defaults: { onHoldCode: number; lineColourCode: string } = {
      onHoldCode: 0,
      lineColourCode: report.originalValue.lineColourCode.value as string,
    }
  ) => {
    if (enable) {
      if (rule.descSuffix !== undefined && rule.descSuffix !== null) {
        if (report.desc && report.desc.trim().endsWith(rule.descSuffix)) {
          report.desc = report.desc.substring(
            0,
            report.desc.length - (rule.descSuffix.length + 1)
          );
        }
      }
    } else {
      if (rule.descSuffix !== undefined && rule.descSuffix !== null) {
        if (report.desc && !report.desc.trim().endsWith(rule.descSuffix)) {
          report.desc = report.desc + ' ' + rule.descSuffix;
        }
      }
    }
  };

  static applyOnHoldCode = (
    report: NGPReport,
    rule: DisabledRule,
    enable: boolean,
    defaults: { onHoldCode: number; lineColourCode: string } = {
      onHoldCode: 0,
      lineColourCode: report.originalValue.lineColourCode.value as string,
    }
  ) => {
    if (enable) {
      if (rule.onHoldCode !== undefined && rule.onHoldCode !== null) {
        if (report.onHoldCode === rule.onHoldCode) {
          report.onHoldCode = defaults.onHoldCode;
        }
      }
    } else {
      if (rule.onHoldCode !== undefined && rule.onHoldCode !== null) {
        report.onHoldCode = rule.onHoldCode;
      }
    }
  };

  static applyLineColour = (
    report: NGPReport,
    rule: DisabledRule,
    enable: boolean,
    defaults: { onHoldCode: number; lineColourCode: string } = {
      onHoldCode: 0,
      lineColourCode: report.originalValue.lineColourCode.value as string,
    }
  ) => {
    if (enable) {
      if (rule.lineColour !== undefined && rule.lineColour !== null) {
        if (report.lineColourCode === rule.lineColour) {
          report.lineColourCode = Number(defaults.lineColourCode);
        }
      }
    } else {
      if (rule.lineColour !== undefined && rule.lineColour !== null) {
        report.lineColourCode = rule.lineColour;
      }
    }
  };

  static enableReport(
    report: NGPReport,
    disableRules: DisabledRules,
    enable: boolean,
    defaults: { onHoldCode: number; lineColourCode: string } = {
      onHoldCode: 0,
      lineColourCode: report.originalValue.lineColourCode.value as string,
    }
  ): boolean {
    const apply = (rule: DisabledRule) => {
      this.applyDescPrefix(report, rule, enable, defaults);
      this.applyDescSuffix(report, rule, enable, defaults);
      this.applyOnHoldCode(report, rule, enable, defaults);
      this.applyLineColour(report, rule, enable, defaults);
    };

    if (enable) {
      for (const ruleKey of Object.keys(disableRules)) {
        apply(disableRules[ruleKey]);
      }
      return true;
    } else {
      if (report.onHand === 0) {
        if (disableRules.onHandZero) {
          apply(disableRules.onHandZero);
          return true;
        }
      } else if (disableRules.onHandNotZero) {
        apply(disableRules.onHandNotZero);
        return true;
      }
    }
    return false;
  }


}
