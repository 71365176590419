import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {FormControl, FormGroup} from '@angular/forms';
import {StoreInfo, StoreIQInfo} from '../../../../shared-utilities/models-old/datastructures';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {StandardAlertsService} from '../../../../shared-utilities/services-old/standard-alerts.service';

const IQ_KEYS = ['name', 'adr1', 'adr2', 'adr3', 'adr4', 'province', 'tel1', 'fax', 'email', 'reg', 'vat',
  'tradingAs', 'physAdr1', 'physAdr2', 'physAdr3', 'post', 'logo'] as const;

const KEY_LABELS = {
  fbName: 'Sort Name',
  name: 'Name on IQ',
  adr1: 'Address Line 1',
  adr2: 'Address Line 2',
  adr3: 'Address Line 3',
  adr4: 'Address Line 4',
  province: 'Province',
  tel1: 'Telephone Number',
  fax: 'Fax Number',
  email: 'Email Address',
  reg: 'Registration',
  vat: 'Vat Code',
  logo: 'Logo',
  tradingAs: 'Trading AS',
  physAdr1: 'Physical Address Line 1',
  physAdr2: 'Physical Address Line 2',
  physAdr3: 'Physical Address Line 3',
  post: 'Postal Code',
  logoOverride: 'Logo Override',
} as const;

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss'],
})
export class StoreInfoComponent implements OnInit {

  @Input() storeId: string;
  @Input() storeInfo: StoreInfo;

  imgKeys = ['logo', 'logoOverride'] as const;

  storeIQInfo: StoreIQInfo;

  formGroup: FormGroup<{
    fbName: FormControl<string>;
    name: FormControl<string>;
    adr1: FormControl<string>;
    adr2: FormControl<string>;
    adr3: FormControl<string>;
    adr4: FormControl<string>;
    province: FormControl<string>;
    tel1: FormControl<string>;
    fax: FormControl<string>;
    email: FormControl<string>;
    reg: FormControl<string>;
    vat: FormControl<string>;
    logo: FormControl<string>;
    tradingAs: FormControl<string>;
    physAdr1: FormControl<string>;
    physAdr2: FormControl<string>;
    physAdr3: FormControl<string>;
    post: FormControl<number>;
    logoOverride: FormControl<string>;
  }>;

  readonly keyOrder: string[] = ['fbName'].concat(IQ_KEYS).concat('logoOverride');
  readonly keyOrderNoLogo: string[] = this.keyOrder.slice(0, this.keyOrder.length - 2);

  constructor(
    private firebase: FirebaseService,
    private stdAlert: StandardAlertsService,
    private alertControl: AlertController,
    private modalController: ModalController,
  ) {
  }

  get keyLabel(): typeof KEY_LABELS {
    return KEY_LABELS;
  }

  ngOnInit() {
    this.firebase.getStoreDataDoc('store_info', this.storeId).then((si: StoreIQInfo) => {
      this.storeIQInfo = si;
      this.formGroup = new FormGroup({
        fbName: new FormControl<string>(this.storeInfo.name),
        name: new FormControl<string>(this.storeIQInfo.name),
        adr1: new FormControl<string>(this.storeIQInfo.adr1),
        adr2: new FormControl<string>(this.storeIQInfo.adr2),
        adr3: new FormControl<string>(this.storeIQInfo.adr3),
        adr4: new FormControl<string>(this.storeIQInfo.adr4),
        province: new FormControl<string>(this.storeIQInfo.province),
        tel1: new FormControl<string>(this.storeIQInfo.tel1),
        fax: new FormControl<string>(this.storeIQInfo.fax),
        email: new FormControl<string>(this.storeIQInfo.email),
        reg: new FormControl<string>(this.storeIQInfo.reg),
        vat: new FormControl<string>(this.storeIQInfo.vat),
        logo: new FormControl<string>(this.storeIQInfo.logo),
        tradingAs: new FormControl<string>(this.storeIQInfo.tradingAs),
        physAdr1: new FormControl<string>(this.storeIQInfo.physAdr1),
        physAdr2: new FormControl<string>(this.storeIQInfo.physAdr2),
        physAdr3: new FormControl<string>(this.storeIQInfo.physAdr3),
        post: new FormControl<number>(this.storeIQInfo.post),
        logoOverride: new FormControl<string>(this.storeIQInfo.logoOverride)
      });
      this.formGroup.get('fbName').disable();

      for (const key of IQ_KEYS) {
        this.formGroup.get(key).disable();
      }
    });
  }

  close = () => {
    this.modalController.dismiss().then();
  };

  isIQKey(key): boolean {
    return IQ_KEYS.includes(key);
  }

  selectOverrideLogo(event) {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.formGroup.get('logoOverride').setValue(reader.result as string);
    };
    reader.readAsDataURL(file);
  }

  deleteOverrideLogo() {
    this.formGroup.get('logoOverride').reset();
  }

  async save() {
    if (this.formGroup.invalid) {
      return;
    }
    const si = this.formGroup.getRawValue();
    const update: { [key: string]: string | number } = {};

    for (const key of IQ_KEYS) {

      if (si[key] !== this.storeIQInfo[key]) {
        update[key] = si[key];
      }
    }

    if (si.logoOverride !== this.storeIQInfo.logoOverride) {
      update.logoOverride = si.logoOverride;
    }
    if (si.fbName !== this.storeInfo.name) {
      update.fbName = this.storeInfo.name;
    }

    if (Object.keys(update).length === 0) {
      return;
    }
    let list = '';
    for (const key of this.keyOrder.filter((k) => !!update[k])) {
      list += `<br>&nbsp;&nbsp;${KEY_LABELS[key]}`;
    }

    const ac = await this.alertControl.create({
      header: 'Update Store Info', subHeader: 'Are you want to update the ' +
        'store information?', message: 'This could cause confusion.<br><br>Changes to the following fields will be ' +
        'made:' + list, cssClass: 'custom-alert', buttons: ['Cancel', {text: 'Save', role: 'y'}]
    });
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role !== 'y') {
      return;
    }
    const complete = await this.stdAlert.indeterminateProgress({header: 'Saving', subHeader: 'Please wait'});
    await this.firebase.updateStoreDataDoc('store_info', this.storeId, update);
    complete();
  };
}
