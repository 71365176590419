import {Component, Input,} from '@angular/core';

/**
 * A component that renders an SVG icon for "Cancel"
 *
 * @member {string} tooltip ? The tooltip that needs to be displayed on this icon.
 */
@Component({
  selector: 'app-icon-cancel',
  templateUrl: './icon-cancel.component.html',
  styleUrls: ['./icon-cancel.component.scss'],
})
export class IconCancelComponent {
  /**
   * Assign a tooltip that needs to be displayed on this icon as soon as the user hovers on it.
   *
   * @param tooltip ? The tooltip that needs to be displayed on this icon.
   */
  @Input() tooltip?: string;

}
