<app-modal-header title="Reattempt Orders" [close]="close"></app-modal-header>

<ion-content class="special-no-scroll">
  <div class="special-scroller">
    <ion-accordion-group>
      <ng-container *ngFor="let batch of sortedContent">
        <h5 *ngIf="storeNames[batch.idx]" style="padding-left: 16px">
          {{ storeNames[batch.idx] }}
        </h5>

        <ion-accordion value="{{ batch.idx }}" [value]="batch.idx">
          <ion-item slot="header" color="light">
            <ion-label>{{ timeStamps[batch.idx]?.d }} {{ timeStamps[batch.idx]?.t }}</ion-label>
          </ion-item>
          <div slot="content">
            <div class="reattempt-item" *ngFor="let orderId of batch.orderIds">
              <div class="headings">
                <h3>{{ content[batch.idx][orderId].heading }}</h3>
                <h5>{{ content[batch.idx][orderId].subHeader }}</h5>
                <p>{{ content[batch.idx][orderId].message }}</p>
              </div>

              <div class="item-content">
                <div class="selection" *ngIf="content[batch.idx][orderId].selection">
                  <ng-container *ngIf="content[batch.idx][orderId].selection.embedded; else popUpSelectionTemp">
                    <div class="embedded">
                      <div *ngFor="let value of content[batch.idx][orderId].selection.valuesOrder; let i = index"
                           style="display: flex; width:100%">
                        <input type="checkbox" [value]="value" (change)="valueInputChange($event, batch.idx, orderId)">
                        {{ content[batch.idx][orderId].selection.values[value] }}
<!--                        <ng-container *ngIf="i === content[batch.idx][orderId].selection.valuesOrder.length -1">-->
<!--                          <ng-container *ngTemplateOutlet="needsInputTemplate; context: {orderId}"></ng-container>-->
<!--                        </ng-container>-->
                        <div style="justify-content: center; align-items: center; display: flex;">
                          <ng-container *ngTemplateOutlet="needsInputTemplate; context: {orderId}"></ng-container>
                        </div>
                      </div>

                    </div>
                  </ng-container>
                  <ng-template #popUpSelectionTemp>
                    <div class="pop-up-button">
                      <ion-button color="light" (click)="makeSelection(batch.idx, orderId, $event)">
                        {{ content[batch.idx][orderId].selection.btnLabel }}
                      </ion-button>
                      <ng-container *ngTemplateOutlet="needsInputTemplate; context: {orderId}"></ng-container>
                    </div>
                  </ng-template>
                </div>
                <div *ngIf="content[batch.idx][orderId].inputs" class="value-inputs">
                  <div *ngFor="let input of content[batch.idx][orderId].inputs">
                    <label>{{ input.label }}</label>
                    <ion-input [placeholder]=input.placeholder (ionChange)="valueInputChange($event, batch.idx, orderId)"
                               [class.text-invalid]="valueInvalid[orderId]" debounce="300">
                    </ion-input>
                    <ng-container *ngTemplateOutlet="needsInputTemplate; context: {orderId}"></ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-accordion>
      </ng-container>
    </ion-accordion-group>
  </div>
  <div class="button-container">
    <ion-button color="light" (click)="close()" class="close-button">Cancel</ion-button>
    <ion-button color="light" (click)="submit()" [disabled]="numInputValues !== numRequired || submitting">Confirm
      Changes</ion-button>
  </div>

</ion-content>

<ng-template #needsInputTemplate let-orderId=orderId>
  <ion-icon name="checkmark-circle-outline" *ngIf="inputValues[orderId]; else notDone"></ion-icon>
  <ng-template #notDone>
    <ion-icon name="ellipse-outline"></ion-icon>
  </ng-template>
</ng-template>
