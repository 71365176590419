<div id="freshness-container">
  <ion-grid *ngIf="currentStatus; else loadingTemplate">
    <ng-container *ngIf="keyOrder?.length > 0; else NoData">
      <ion-row class="freshness" *ngFor="let key of keyOrder; trackBy: trackByFunction">
        <ion-col>
          {{ keyOrder }}
          <ion-icon
            name="eye-outline"
            class="eye-position"
            (click)="onFreshnessExpClick()">
          </ion-icon>
        </ion-col>
        <ion-col>
          {{ ageStrings[key] }}
        </ion-col>
        <ion-icon
          name="ellipse"
          [class]="currentStatuses[key]">
        </ion-icon>

      </ion-row>
    </ng-container>
    <ng-template #NoData>
      No Data Available
    </ng-template>
  </ion-grid>
  <ng-template #loadingTemplate>
    <div id="loading-text">
      probing data
    </div>
  </ng-template>
</div>
