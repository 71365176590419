<ion-toolbar class="shared-page-header-segment-container multi-line-tabs">
  <ion-segment
    color="dark"
    [ngClass]="{'shared-page-header-segments-modal': isModal, 'shared-page-header-segments': !isModal}"
    [value]="stores[0]"
    (ionChange)="onStoreChange($event)">

    <ng-container *ngIf="stores.length > 0">
      <ng-container *ngFor="let store of stores">
        <div
          class="tab"
          [matTooltip]="store.name"
          [style.max-width]="isModal ? getTabMaxWidth(): ''">
          <ion-segment-button
            [value]="store"
            [ngClass]="{'segment-button-modal': isModal, 'segment-button': !isModal}">
            <ion-label>
              {{ store.name }}
            </ion-label>
          </ion-segment-button>
          <app-data-freshness-indicator
            *ngIf="showFreshnessIndicator"
            [id]="'freshness-' + store.storeId"
            class="freshness-button"
            [storeId]="store.storeId"
            [dataKeys]="getDataKey">
          </app-data-freshness-indicator>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="stores.length < 1">
      <ion-segment-button disabled>
        <ion-label>No Stores Available</ion-label>
      </ion-segment-button>
    </ng-container>
  </ion-segment>

</ion-toolbar>
