import { Component } from '@angular/core';

/**
 * A component that renders an SVG of the full Gallix Logo
 */
@Component({
  selector: 'app-logo-full',
  templateUrl: './logo-full.component.html',
  styleUrls: ['./logo-full.component.scss'],
})
export class LogoFullComponent {
}
