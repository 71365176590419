<div [ngStyle]="{'width': 400 + 'px'}" class="supp-selector-div">

  <input
    #input
    (keydown.enter)="selectBestMatch($event)"
    [formControl]="searchControl"
    class="input"
    placeholder="Search Suppliers..."/>

  <select
    (change)="onSelectionChange($event)"
    [(ngModel)]="value"
    class="select-supp-list">
    <option *ngIf="filteredOptions.length === 0" disabled>No matching suppliers</option>
    <option (click)="onSelectionChange($event)"
            *ngFor="let option of filteredOptions; trackBy: trackByFunction"
            [value]="option.key">
      {{ option.key }} - {{ option.description }}
    </option>
  </select>

  <app-display-icon
    (click)="onUndoChangesClick()"
    *ngIf="params.data.originalValue[params.colDef.field].value !== value"
    [icon]="icons.backSpaceOutline"
    class="backspace">
  </app-display-icon>

</div>
