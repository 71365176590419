import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SHARED_ICONS} from './components';
import {DisplayIconComponent} from './components/display-icon/display-icon.component';

@NgModule({
  declarations: [
    ...SHARED_ICONS,
    DisplayIconComponent,
  ],
  exports: [
    ...SHARED_ICONS,
    DisplayIconComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class SharedIconsModule {
}
