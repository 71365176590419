<div
  class="cell-renderer-currency"
  [ngClass]="+params.data.originalValue?.[params.colDef.field]?.value !== +params.data[params.colDef.field]? 'edited': ''">
  <div>{{price}}</div>
  <app-icon-backspace-outline
    *ngIf="+params.data.originalValue?.[params.colDef.field]?.value !== +params.data[params.colDef.field] && params.colDef.field !== 'diffGP'"
    (click)="onUndoButtonClick()"
    [tooltip]="'Previous Value = ' +params.data.originalValue[params.colDef.field].value">
  </app-icon-backspace-outline>
</div>
