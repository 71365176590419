import {Component} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {Store} from '@ngrx/store';
import {LineColour} from '../../../../shared-utilities/models-old/datastructures';
import {Icons} from '../../../shared-icons/icons';
import {
  selectLineColours as ngpLineColour,
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {Observable} from 'rxjs';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {
  selectLineColours as stockManagerLineColour,
} from '../../../../features/stock-manager/store/stock-manager.selectors';

@Component({
  selector: 'app-cell-renderer-line-color',
  templateUrl: './cell-renderer-line-color.component.html',
  styleUrls: ['./cell-renderer-line-color.component.scss'],
})
export class CellRendererLineColorComponent implements ICellEditorAngularComp {
  value: string;
  params: ICellEditorParams;
  width: number;
  colDefField: string;
  lineColourObj: any;
  currentPage$: Observable<string>;
  currentPage: string;
  protected readonly icons = Icons;

  constructor(
    private readonly store: Store,
  ) {
  }

  agInit(params: ICellEditorParams): void {
    this.currentPage$ = this.store.select(selectCurrentPage);
    this.currentPage$.subscribe((page: string) => {
      this.currentPage = page;
    });
    this.params = params;
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
    this.width = this.params.colDef.width;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
    if (this.currentPage === 'ngp-report') {
      this.store.select(ngpLineColour).subscribe((lineColours: LineColour): void => {
        this.lineColourObj = {...lineColours};
      });
    } else {
      this.store.select(stockManagerLineColour).subscribe((lineColours: LineColour): void => {
        this.lineColourObj = {...lineColours};
      });
    }

  }

  getValue(): string {
    return this.value;
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.params.data.originalValue[this.colDefField].value;
    GridUtils.removeIsEditingFlagAndDispatch(this.params.data, this.params.colDef.field, this.store, this.currentPage);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

}
