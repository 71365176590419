import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {environment} from '../../../../environments/environment';
import {IStorage} from '../../shared-models/storage/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: AngularFireStorage) {
  }

  async getDocumentDownloadUrl(storage: IStorage): Promise<string> {
    try {
      const url = `${environment.storageUrl}/${storage.document}.${storage.extension}`;
      const storageRef = this.storage.refFromURL(url);
      const downloadUrl = storageRef.getDownloadURL().toPromise();
      return await downloadUrl as string;
    } catch (error) {
      console.error('Error fetching document download URL:', error);
      throw new Error('Failed to get document download URL');
    }
  }

}
