<app-modal-header title="Possible Errors" [close]="onCancelClick"></app-modal-header>
<ion-content>
  <div class="table-div">
    <ag-grid-angular
      class="ag-theme-custom"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (cellValueChanged)="onCellValueChanged()">
    </ag-grid-angular>
    <ng-container>
      <button
        type="button"
        mat-raised-button
        (click)="onCancelClick()"
        class="ignore-button">
        Ignore Warnings
      </button>
      <button
        type="button"
        mat-raised-button
        (click)="onChangeButtonClick()"
        class="submit-button">
        Submit Changes
      </button>
    </ng-container>
  </div>
</ion-content>
