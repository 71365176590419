import {RouterEvent} from '@angular/router';

/**
 * Extracts and formats the module name from a router event URL.
 *
 * This function takes a `RouterEvent`, extracts the URL, and processes it to return a formatted module name.
 * The URL is cleaned of query parameters and leading slashes, then split by hyphens (`-`).
 * Each segment of the URL is capitalized (first letter uppercase, remaining letters lowercase), and the segments are concatenated without hyphens to form the final module name.
 *
 * @param {RouterEvent} event - The router event containing the URL to be processed.
 * @returns {string} - The formatted module name derived from the URL.
 *
 * @example
 * // Assuming the URL is '/user-profile/edit?param=value'
 * const event = { url: '/user-profile/edit' } as RouterEvent;
 * console.warn(moduleName); // Output: 'UserProfileEdit'
 */
export function getModuleNameFromRouterModule(event: RouterEvent): string {
  const url = event.url;
  const indexQuestionMark = url.indexOf('?');
  const indexEndOfString = indexQuestionMark > -1 ? indexQuestionMark : url.length;
  let urlModule = url?.[0] === '/' ? url.substring(1, indexEndOfString) : url.substring(0, indexEndOfString);
  urlModule = urlModule
    .split('-')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');
  return urlModule;
}
