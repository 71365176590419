import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {FireAuthService} from '../../services-old/fire-auth.service';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuardGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private fireAuth: FireAuthService,
    private router: Router,
  ) {}

  private get isAdmin(): Promise<boolean> {
    return new Promise<boolean>(resolve =>
      this.fireAuth.userIdSub.pipe(take(1)).toPromise().then(uID =>
        resolve(uID === 'claydenburger@gmail.com')));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.isAdmin ? true : this.router.createUrlTree(['']);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.isAdmin ? true : this.router.createUrlTree(['']);
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.isAdmin ? true : this.router.createUrlTree(['']);
  }
}
