import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {IStore} from '../../shared-models/store/store';
import {path_shared_store_data_suppliers_GET} from '../database-paths';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ISupplier} from '../../shared-models/stock/suppliers';

@Injectable({
  providedIn: 'root',
})
export class CollectionSharedSuppliersService {

  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  // ====================================================================================================
  // Suppliers
  // ====================================================================================================

  getSuppliersByStore(store: IStore): Observable<{ [account: string]: ISupplier }> {
    return this.angularFirestore
      .collection(path_shared_store_data_suppliers_GET(store.storeId))
      .snapshotChanges()
      .pipe(
        map((changes) => {
          const suppliers: { [account: string]: ISupplier } = {};
          changes.forEach((change): void => {
            const doc = change.payload.doc;
            suppliers[doc.id] = doc.data() as ISupplier;
            suppliers[doc.id].supplierId = doc.id;
            suppliers[doc.id].isSupplierSelected = false;
          });
          return suppliers;
        })
      );
  }

}
