import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import {select, Store} from '@ngrx/store';
import {forkJoin, from, Observable, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {selectSelectedUserStore, selectUser} from '../../../features-as-modules/feature-core/store/core.selectors';
import * as StoreSettingsActions from './settings.actions';
import * as StockManagerActions from '../../stock-manager/store/stock-manager.actions';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {DisabledRules, LineColour, TableNavSettings} from '../../../shared-utilities/models-old/datastructures';
import {
  CollectionUsersSettingsService,
} from '../../../shared/shared-services/firebase/collection-users-settings.service';
import {
  path_users_userId_settings_fit_to_grid,
  path_users_userId_settings_ngp_edits_preview,
  path_users_userId_settings_stock_edits_preview,
  path_users_userId_settings_stores_storeId_mailer_settings,
  path_users_userId_settings_table_navigation,
} from '../../../shared/shared-services/database-paths';
import {IEmailSettings} from '../models/settings-models';

@Injectable()
export class SettingsEffects {

  // ===========================================================================
  // get NGP Report Options
  // ===========================================================================
  getNGPReportOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.getNGPReportColPreviewSettings),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectUser)),
      ),
      mergeMap(([action, selectedStore, {id}]) => {
        const colPreviews$: Observable<{
          data: { [p: number]: string };
          error: IError
        }> = from(this.collectionUsersSettingsService.getDocument<{
          [key: number]: string
        } | undefined>(String(path_users_userId_settings_ngp_edits_preview(id))))
          .pipe(
            take(1),
            map((data: { [key: number]: string }) => ({data, error: null})),
            catchError((error: IError) => of({data: null, error})),
          );

        return forkJoin({
          colPreview: colPreviews$,
        }).pipe(
          map(({colPreview}) => {
            const errors: IError[] = [];
            if (colPreview.error) {
              errors.push(colPreview.error);
            }
            if (errors.length > 0) {
              return StoreSettingsActions.getNGPReportColPreviewFailure({
                errors,
                store: selectedStore,
              });
            }

            return StoreSettingsActions.getNGPReportColPreviewSuccess({
              store: selectedStore,
              colPreview: colPreview.data,
            });
          }),
          catchError((error: IError) => {
            return of(StoreSettingsActions.getNGPReportColPreviewFailure({
              errors: [error],
              store: selectedStore,
            }));
          }),
        );
      }),
    ),
  );


  // ===========================================================================
  // set NGP Report Preview Columns
  // ===========================================================================
  setNgpReportOptionsPreviewColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.setNGPReportColPreviewSettings),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectUser))),
      switchMap(([action, userSelectedStore, {id}]) => {
        return from(this.collectionUsersSettingsService.setDocument<{
          [key: number]: string
        }>(String(path_users_userId_settings_ngp_edits_preview(id)), action.columnData))
          .pipe(
            map(() => StoreSettingsActions.setNGPReportColPreviewSettingsSuccess({
              columnData: action.columnData,
              store: userSelectedStore,
            })),
            catchError((error: IError) => of(StoreSettingsActions.setNGPReportColPreviewSettingsFailure({
              error,
              store: userSelectedStore,
            }))),
          );
      }),
    ),
  );

  // ===========================================================================
  // set NGP Report Table Navigation Settings
  // ===========================================================================
  setTableNavSettingsInFirebase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.setTableNavSettings),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectUser))),
      mergeMap(([settings, userSelectedStore, {id}]) => {
        return from(this.collectionUsersSettingsService
          .setDocument<TableNavSettings>(String(path_users_userId_settings_table_navigation(id)), settings.settings))
          .pipe(
            map(() => StoreSettingsActions.setTableNavSettingsSuccess({
                settings: settings.settings,
                store: userSelectedStore,
              }),
            ),
            catchError((error: IError) => of(StoreSettingsActions.setTableNavSettingsFailure({
              error,
              store: userSelectedStore,
            }))),
          );
      }),
    ),
  );

  // ===========================================================================
  // get Table Navigation Settings
  // ===========================================================================
  getTableSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.getTableSettings),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectUser)),
      ),
      mergeMap(([_, userSelectedStore, {id}]) =>
        from(this.collectionUsersSettingsService.getDocument<{
          value: boolean
        }>(path_users_userId_settings_fit_to_grid(id))).pipe(
          mergeMap((fitToGrid: { value: boolean }) =>
            from(this.collectionUsersSettingsService.getDocument<TableNavSettings>(path_users_userId_settings_table_navigation(id))).pipe(
              take(1),
              map((data: TableNavSettings) => {
                return StoreSettingsActions.getTableSettingsSuccess({
                  tableNavSettings: data,
                  fitToGrid: fitToGrid.value,
                  store: userSelectedStore,
                });
              }),
              catchError((error: IError) => {
                return of(StoreSettingsActions.getTableSettingsFailure({
                  error,
                  store: userSelectedStore,
                }));
              }),
            ),
          ),
          catchError((error: IError) => {
            console.error(`Error in getOrCreateFitToGridDocument:`, error);
            return of(StoreSettingsActions.getTableSettingsFailure({
              error,
              store: userSelectedStore,
            }));
          }),
        ),
      ),
    ),
  );


  getDisabledRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.getItemDisablingRules),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      mergeMap(([action, store]) =>
        this.firebaseService.subStoreDataDoc('stock_item_deletion_rules', store.storeId, 'operational').pipe(
          map((rulesDoc: DisabledRules) => StoreSettingsActions.getItemDisablingRulesSuccess({rulesDoc, store})),
          catchError((error: IError) => of(StoreSettingsActions.getItemDisablingRulesFailure({error, store}))),
        ),
      ),
    ),
  );


  getLineColour$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.getLineColour),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      mergeMap(([action, store]) =>
        from(this.firebaseService.getStoreDataDoc('line_colours', store.storeId)).pipe(
          map((colour: LineColour) =>
            StoreSettingsActions.getLineColourSuccess({colour, store}),
          ),
          catchError((error: IError) =>
            of(StoreSettingsActions.getLineColourFailure({error, store})),
          ),
        ),
      ),
    ),
  );


  getStockManagerColPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.getStockManagerColPreviewSettings),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectUser)),
      ),
      mergeMap(([_, store, {id}]) =>
        from(this.collectionUsersSettingsService.getDocument<{
          [p: number]: string
        }>(path_users_userId_settings_stock_edits_preview(id))).pipe(
          map((colPreview: { [p: number]: string }) =>
            StoreSettingsActions.getStockManagerColPreviewSettingsSuccess({colPreview, store}),
          ),
          catchError((errors: IError) =>
            of(StoreSettingsActions.getStockManagerColPreviewSettingsFailure({errors})),
          ),
        ),
      ),
    ),
  );

  setStockManagerColPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.setStockManagerPreviewColumns),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectUser)),
      ),
      switchMap(([action, userSelectedStore, {id}]) =>
        from(this.collectionUsersSettingsService.setDocument<{
          [p: number]: string
        }>(path_users_userId_settings_stock_edits_preview(id), action.columnData)).pipe(
          mergeMap(() => [
            StoreSettingsActions.setStockManagerPreviewColumnsSuccess({
              columnData: action.columnData,
              store: userSelectedStore,
            }),
            StockManagerActions.setStockManagerPreviewColumnsSuccess({
              columnData: action.columnData,
            }),
          ]),
          catchError((error: IError) =>
            of(StoreSettingsActions.setStockManagerPreviewColumnsFailure({
              error,
              store: userSelectedStore,
            })),
          ),
        ),
      ),
    ),
  );

  setFitToGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.setFitToGrid),
      withLatestFrom(this.store.pipe(select(selectUser))),
      mergeMap(([{fitToGrid}, {id}]) =>
        from(this.collectionUsersSettingsService.setDocument<{
          value: boolean
        }>(path_users_userId_settings_fit_to_grid(id), {value: fitToGrid})).pipe(
          map(() =>
            StoreSettingsActions.setFitToGridSuccess({
              fitToGrid,
            }),
          ),
          catchError((error: IError) =>
            of(
              StoreSettingsActions.setFitToGridFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getAllUserEmailSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.getAllUserEmailSettings),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectUser)),
      ),
      mergeMap(([_, selectedStore, user]) => {
        const storeId = selectedStore.storeId;
        const userId = user.id;

        return from(
          this.collectionUsersSettingsService.getDocument<IEmailSettings>(
            path_users_userId_settings_stores_storeId_mailer_settings(userId, storeId),
          ),
        ).pipe(
          mergeMap((userSettings: IEmailSettings) => {
            const currentUser = {[userId]: userSettings || ({} as IEmailSettings)};

            return this.collectionUsersSettingsService.getAllMailerSettings(storeId).pipe(
              map((settings) => {
                return StoreSettingsActions.getAllUserEmailSettingsSuccess({
                  usersEmailSettings: {...currentUser, ...settings},
                  store: selectedStore,
                });
              }),
            );
          }),
          catchError((error: IError) => {
            console.error('Error fetching user email settings:', error);
            return of(StoreSettingsActions.getAllUserEmailSettingsFailure({error}));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private collectionUsersSettingsService: CollectionUsersSettingsService,
    private readonly store: Store,
  ) {
  }
  
}
