import {Component, Input} from '@angular/core';

/**
 * A component that renders a SVG used for styling
 */
@Component({
  selector: 'app-styling-svg',
  templateUrl: './styling-svg.component.html',
  styleUrls: ['./styling-svg.component.scss'],
})
export class StylingSvgComponent {
  @Input() isSidebar: boolean = true;
}
