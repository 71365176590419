<div class="line-color-div">

  <p
    *ngIf="params.data['lineColourCode'] !== 0 && lineColourObj?.[value];
    else noLineColor" [ngStyle]="{'width':width-40 + 'px'}"
    class="line-color-desc">
    {{value}} - {{lineColourObj?.[value]?.description}}
  </p>

  <ng-template #noLineColor>
    <p
      *ngIf="params.data['lineColourCode'] === 0 || params.data['lineColourCode'] === '0' ; else lineColourDoesNotExist"
      [ngStyle]="{'width': width-40 + 'px'}"
      class="line-color-desc">
      0 - No Line Colour
    </p>
  </ng-template>

  <ng-template #lineColourDoesNotExist>
    <p [ngStyle]="{'width': width-40 + 'px'}" class="line-color-desc does-not-exist">
      the selected line colour does not exist
    </p>
  </ng-template>

  <ng-container *ngIf="params.data.originalValue[params.colDef.field].value !== value  && !params.data.disabled">
    <app-icon-backspace-outline
      (click)="onUndoChangesClick()"
      [tooltip]="'Previous Value = ' +params.data.originalValue[params.colDef.field].value"
      class="backspace">
    </app-icon-backspace-outline>
  </ng-container>

</div>

