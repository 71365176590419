import {Component, Input} from '@angular/core';
import {IAnimation} from '../../../shared-models/animations/animation';

@Component({
  selector: 'app-shared-loading-indicator',
  templateUrl: './shared-loading-indicator.component.html',
  styleUrls: ['./shared-loading-indicator.component.scss'],
})
export class SharedLoadingIndicatorComponent {
  @Input() animation: IAnimation;
}
