import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonAccordionGroup, ModalController, PopoverController} from '@ionic/angular';
import {TableNavSettings} from '../../../../../shared-utilities/models-old/datastructures';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  getNGPReportColPreviewSettings,
  getStockManagerColPreviewSettings,
  getTableSettings,
  setTableNavSettings,
} from '../../../store/settings.actions';
import {
  selectFitToGrid,
  selectNavSettings,
  selectNGPReportColPreviewSettings,
  selectStockManagerColPreviewSettings,
} from '../../../store/settings.selectors';
import { ngpReportGridColDefs } from 'src/app/shared-utilities/models-old/ngp-report-grid/defaults/ngp-report/ngp-report-grid-col-defs';
import { stockManagerGridColDefs } from 'src/app/features/stock-manager/models/stock-manager-grid-col-def';

@Component({
  selector: 'app-user-settings',
  templateUrl: './settings-user-container.component.html',
  styleUrls: ['./settings-user-container.component.scss'],
})
export class SettingsUserContainerComponent implements OnInit {

  @Input() openAccordion?: string;

  tableNavigationSettings$: Observable<TableNavSettings>;
  ngpColPreviewSettings$: Observable<{ [key: number]: string }>;
  stockManagerColPreviewSettings$: Observable<{ [key: number]: string }>;
  fitToGrid$: Observable<boolean>;

  navSettings: TableNavSettings;

  protected readonly ngpReportGridColDefs = [...ngpReportGridColDefs];
  protected readonly stockManagerGridColDefs = [...stockManagerGridColDefs];

  @ViewChild('accordionGroup', {static: true}) accordionGroup: IonAccordionGroup;


  constructor(
    private modalController: ModalController,
    private readonly store: Store,
    private popoverController: PopoverController,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(getTableSettings());
    this.store.dispatch(getNGPReportColPreviewSettings());
    this.store.dispatch(getStockManagerColPreviewSettings());
    this.tableNavigationSettings$ = this.store.select(selectNavSettings);
    this.fitToGrid$ = this.store.select(selectFitToGrid);
    this.ngpColPreviewSettings$ = this.store.select(selectNGPReportColPreviewSettings);
    this.stockManagerColPreviewSettings$ = this.store.select(selectStockManagerColPreviewSettings);
    if (this.openAccordion) {
      this.accordionGroup.value = this.openAccordion;
    }
  }

  close = (): void => {
    void this.modalController.dismiss().then();
  };

  async setTableNave(data: TableNavSettings): Promise<void> {
    if (data) {
      this.navSettings = data;
      this.store.dispatch(setTableNavSettings({settings: data}));
    }
  }
}
