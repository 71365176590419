import { Injectable } from '@angular/core';
import {
  CanLoad,
  Route,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {take} from 'rxjs/operators';

import {FireAuthService} from '../../services-old/fire-auth.service';

@Injectable({
  providedIn: 'root'
})
export class SignupGuard implements CanLoad {

  constructor(
    private auth: FireAuthService,
  ) {
  }

  canLoad(
    route: Route, segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.notSignedIn();
  }

  private async notSignedIn(): Promise<boolean> {
    const uID = await this.auth.userIdSub.pipe(take(1)).toPromise();
    return uID === null;
  }
}
