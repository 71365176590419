import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {CORE_COMPONENTS} from './components';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {coreReducer} from './store/core.reducer';
import {CoreEffects} from './store/core.effects';
import {environment} from '../../../environments/environment';
import {PushModule} from '@ngrx/component';
import {SharedModule} from '../../shared-modules/shared-module/shared-module.module';
import {SharedIconsModule} from '../../shared-modules/shared-icons/shared-icons.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MatTooltipModule,
    MatButtonModule,
    MatRippleModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    PushModule,
    SharedIconsModule,
    SharedModule,
    StoreModule.forFeature('core', coreReducer),
    EffectsModule.forFeature([CoreEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production
    }),
  ],
  declarations: [
    CORE_COMPONENTS
  ],
  exports: [
    ...CORE_COMPONENTS
  ],
})
export class FeatureCoreModule {
}
