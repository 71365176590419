import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import * as SharedGridActions from './shared-grid.actions';
import {TableNavSettings, VatRates} from '../../../shared-utilities/models-old/datastructures';
import {DEFAULT_AG_GRID_SETTINGS, IAgGridExportSettings} from '../models/ag-grid-export-settings';
import {IPriceBandPerStore} from '../../../shared/shared-models/price-banding/price-band';

export interface SharedGridState extends IInitialState {
  // Exporting Data -------------------------
  exportToCSVSettings: IAgGridExportSettings;
  // Table Navigation Settings --------------
  tableNavSettings: TableNavSettings;
  // Price Banding --------------------------
  isPriceBandingPerStoreLoading: boolean;
  priceBandsPerStore: IPriceBandPerStore;
  vatRates: { [storeId: string]: VatRates };
}

export const initialSharedGridState: SharedGridState = {
  errors: [],
  // Exporting Data -------------------------
  exportToCSVSettings: DEFAULT_AG_GRID_SETTINGS,
  // Table Navigation Settings --------------
  tableNavSettings: {tabEnd: null, enterEnd: null},
  // Price Banding --------------------------,
  isPriceBandingPerStoreLoading: false,
  priceBandsPerStore: {},
  vatRates: {},
};

const createSharedGridReducer: ActionReducer<SharedGridState> = createReducer(
  initialSharedGridState,
  // ====================================================================================================
  // Export to CSV
  // ====================================================================================================
  on(SharedGridActions.onExportDataToCSV, (state, {settings}) => ({
    ...state,
    exportToCSVSettings: settings,
  })),
  // ====================================================================================================
  // Get Table NavigationSettings
  // ====================================================================================================
  on(SharedGridActions.getTableNavSettingsSuccess, (state, {settings}) => ({
    ...state,
    tableNavSettings: settings,
  })),
  on(SharedGridActions.getTableNavSettingsFailure, (state, {error}) => ({
    ...state,
    error: [...state.errors, error],
  })),
  //===============================================================================================================
  // Price Banding
  //===============================================================================================================
  on(SharedGridActions.setIsGetPriceBandingForStoresLoading, (state, {isLoading}) => ({
    ...state,
    isPriceBandingPerStoreLoading: isLoading,
  })),
  on(SharedGridActions.getPriceBandingForStores, (state) => ({
    ...state,
    isPriceBandingPerStoreLoading: true,
  })),
  on(SharedGridActions.getPriceBandingForStoresSuccess, (state, {priceBandsPerStore}) => ({
    ...state,
    isPriceBandingPerStoreLoading: false,
    priceBandsPerStore,
  })),
  on(SharedGridActions.getPriceBandingForStoresFailure, (state, {error}) => ({
    ...state,
    isPriceBandingPerStoreLoading: false,
    priceBandsPerStore: {},
    error: [...state.errors, error],
  })),
  on(SharedGridActions.getVatRateConversionSuccess, (state, {vatRates}) => ({
    ...state,
    vatRates,
  })),
  on(SharedGridActions.getVatRateConversionFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
);

export const sharedGridReducer = (state: SharedGridState, action: Action): SharedGridState => createSharedGridReducer(state, action);
