import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {DisabledRules, LineColour, StockItem} from '../../../shared-utilities/models-old/datastructures';
import * as StockManagerActions from '../store/stock-manager.actions';
import {addOriginalValueFieldToItems, checkForDisabledItems} from './stock-manager.reducer.utils';
import {IPaginationData} from '../../../shared/shared-models/pagination/pagination-data';
import {ISearchableFields} from '../../../shared/shared-models/type-sense/default-searchable-fields';
import {IFacetCounts} from '../../../shared/shared-models/type-sense/type-sense-types';
import {IDepartment} from '../../../shared/shared-models/stock/departments';
import {StoreHeaderMenuData} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import * as NGPReportUtils from '../../../features-as-modules/feature-ngp-report/store/ngp-report.reducer.utils';
import {PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS} from '../../../shared/shared-utils/price/price-banding.utils';
import {GridUtils} from '../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {ColDef} from 'ag-grid-community';
import {StockFunctions} from '../../../shared-utilities/functions-old/stock-functions';
import {ReportUtils} from '../../../shared-utilities/utils-old/shared-utils-old/report-utils';

export interface StockManagerState extends IInitialState {
  departments: { [storeId: string]: IDepartment[] };
  facetCounts: { [storeId: string]: IFacetCounts };
  filters: { [storeId: string]: { [key: string]: ISearchableFields } };
  isDepartmentsLoading: boolean;
  isStockItemFilterLoading: boolean;
  isStockItemsLoading: boolean;
  isStockManagerLoading: boolean;
  isPaginationLoading: boolean;
  isSuppliersLoading: boolean;
  paginationData: { [storeId: string]: IPaginationData };
  searchableFields: ISearchableFields;
  stockItems: { [storeId: string]: StockItem[] };
  nextStockItemsPage: { [storeId: string]: StockItem[] };
  editedItems: { [storeId: string]: { [itemCode: string]: StockItem } };
  storeSuppliers: { [storeId: string]: ISearchableFields };
  disabledRules: { [storeId: string]: DisabledRules };
  headerMenuData: StoreHeaderMenuData;
  visibleFields: { [key: string]: boolean };
  lineColours: { [storeId: string]: LineColour };
  isPriceBanding: boolean;
  editedItemsFromTypesense: StockItem[];
  previewColumnData: { [key: number]: boolean },
}

export const initialStockUpdatesState: StockManagerState = {
  departments: {},
  errors: [],
  facetCounts: {},
  filters: {},
  isDepartmentsLoading: false,
  isStockItemFilterLoading: false,
  isStockItemsLoading: false,
  isStockManagerLoading: false,
  isPaginationLoading: false,
  isSuppliersLoading: false,
  paginationData: {},
  stockItems: {},
  nextStockItemsPage: {},
  editedItems: {},
  searchableFields: {},
  storeSuppliers: {},
  disabledRules: {},
  headerMenuData: {},
  lineColours: {},
  visibleFields: {},
  isPriceBanding: false,
  editedItemsFromTypesense: [],
  previewColumnData: {},
};

const createStockManagerReducer: ActionReducer<StockManagerState> = createReducer(
  initialStockUpdatesState,
  // =======================================================================
  // Stock Item
  // =======================================================================
  on(StockManagerActions.getAllStockItems, (state: StockManagerState) => ({
    ...state,
    isStockManagerLoading: true,
    isStockItemsLoading: true,
  })),
  on(StockManagerActions.getAllStockItemsSuccess, (state: StockManagerState, {
    stockItems,
    store,
    paginationData,
    facetCounts,
  }) => {
    stockItems = GridUtils.applyLineColoursToStockManager(
      state.lineColours[store.storeId],
      stockItems,
    );
    stockItems = addOriginalValueFieldToItems(stockItems);
    stockItems = checkForDisabledItems(stockItems, state.disabledRules, true);

    return {
      ...state,
      stockItems: {
        ...state.stockItems,
        [store.storeId]: stockItems,
      },
      paginationData: {
        ...state.paginationData,
        [store.storeId]: paginationData,
      },
      facetCounts: {
        ...state.facetCounts,
        [store.storeId]: facetCounts,
      },
      isStockManagerLoading: false,
      isStockItemsLoading: false,
    };
  }),
  on(StockManagerActions.getAllStockItemsFailure, (state: StockManagerState, {error, store}) => ({
    ...state,
    stockItems: {
      ...state.stockItems,
      [store.storeId]: [],
    },
    paginationData: {
      ...state.paginationData,
      [store.storeId]: {
        currentPage: 1,
        pageSize: 20,
        totalItems: 0,
      },
    },
    facetCounts: {
      ...state.facetCounts,
      [store.storeId]: {},
    },
    isStockManagerLoading: false,
    isStockItemsLoading: false,
    errors: [...state.errors, error],
  })),
  // =======================================================================
  // Search Results
  // =======================================================================
  on(StockManagerActions.setSearchableFields, (state: StockManagerState, {searchableFields}) => ({
    ...state,
    searchableFields,
  })),
  on(StockManagerActions.getSearchResultsForStockItems, (state: StockManagerState) => ({
    ...state,
    isStockItemsLoading: true,
  })),
  on(StockManagerActions.getSearchResultsForStockItemsSuccess, (state: StockManagerState, {
    stockItems,
    store,
    paginationData,
    facetCounts
  }) => {
    stockItems = GridUtils.applyLineColoursToStockManager(
      state.lineColours[store.storeId],
      stockItems,
    );
    stockItems = checkForDisabledItems(stockItems, state.disabledRules);
    stockItems = addOriginalValueFieldToItems(stockItems);
    return {
      ...state,
      stockItems: {
        ...state.stockItems,
        [store.storeId]: stockItems,
      },
      paginationData: {
        ...state.paginationData,
        [store.storeId]: paginationData,
      },
      facetCounts: {
        ...state.facetCounts,
        [store.storeId]: facetCounts,
      },
      isStockManagerLoading: false,
      isStockItemsLoading: false,
    };
  }),
  on(StockManagerActions.getSearchResultsForStockItemsFailure, (state: StockManagerState, {error, store}) => ({
    ...state,
    stockItems: {
      ...state.stockItems,
      [store.storeId]: [],
    },
    isStockItemsLoading: false,
    isStockManagerLoading: false,
    errors: [...state.errors, error],
  })),
  // =======================================================================
  // Pagination
  // =======================================================================
  on(StockManagerActions.getPaginationResultsForStockItemsSuccess, (state: StockManagerState, {
    stockItems,
    store,
    paginationData,
    facetCounts,
  }) => {
    stockItems = GridUtils.applyLineColoursToStockManager(
      state.lineColours[store.storeId],
      stockItems,
    );
    stockItems = checkForDisabledItems(stockItems, state.disabledRules);
    stockItems = addOriginalValueFieldToItems(stockItems);
    return {
      ...state,
      stockItems: {
        ...state.stockItems,
        [store.storeId]: stockItems,
      },
      paginationData: {
        ...state.paginationData,
        [store.storeId]: paginationData,
      },
      facetCounts: {
        ...state.facetCounts,
        [store.storeId]: facetCounts,
      },
      isStockManagerLoading: false,
      isPaginationLoading: false,
    };
  }),
  on(StockManagerActions.getPaginationResultsForStockItemsFailure, (state: StockManagerState, {error, store}) => ({
    ...state,
    isStockManagerLoading: false,
    isPaginationLoading: false,
    stockItems: {
      ...state.stockItems,
      [store.storeId]: [],
    },
    errors: [...state.errors, error],
  })),
  on(StockManagerActions.setPageSizeForSharedGridAtStoreId, (state: StockManagerState, {pageSize, store}) => ({
    ...state,
    paginationData: {
      ...state.paginationData,
      [store.storeId]: {
        ...state.paginationData[store.storeId],
        pageSize,
      },
    },
  })),
  on(StockManagerActions.setNextPageForSharedGridAtStoreId, (state: StockManagerState, {paginationData, store}) => {
    return {
      ...state,
      paginationData: {
        ...state.paginationData,
        [store.storeId]: paginationData,
      },
      stockItems: {
        ...state.stockItems,
        [store.storeId]: [...state.nextStockItemsPage[store.storeId]],
      },
    };
  }),
  on(StockManagerActions.getNextPageForSharedGridSuccess, (state: StockManagerState, {store, stockItems}) => {
    stockItems = checkForDisabledItems(stockItems, state.disabledRules[store.storeId]);
    stockItems = addOriginalValueFieldToItems(stockItems);
    return {
      ...state,
      nextStockItemsPage: {
        ...state.nextStockItemsPage,
        [store.storeId]: stockItems,
      },
    };
  }),

  on(StockManagerActions.getNextPageForStockItemsSuccess, (state: StockManagerState, {
      stockItems,
      store,
      nextPaginationData,
    }) => {
      stockItems = checkForDisabledItems(stockItems, state.disabledRules[store.storeId]);
      stockItems = addOriginalValueFieldToItems(stockItems);

      return {
        ...state,
        nextStockItemsPage: {
          ...state.nextStockItemsPage,
          [store.storeId]: stockItems,
        },
        paginationData: {
          ...state.paginationData,
          [store.storeId]: nextPaginationData,
        },
      };
    },
  ),
  // =======================================================================
  // Suppliers
  // =======================================================================
  on(StockManagerActions.getPaginationResultsForStockItems, (state: StockManagerState) => ({
    ...state,
    isStockManagerLoading: true,
    isSuppliersLoading: true,
  })),
  on(StockManagerActions.getSuppliersForStockManagerSuccess, (state: StockManagerState, {suppliers, store}) => ({
    ...state,
    storeSuppliers: {
      ...state.storeSuppliers,
      [store.storeId]: suppliers,
    },
    isStockManagerLoading: false,
    isSuppliersLoading: false,
  })),
  on(StockManagerActions.getSuppliersForStockManagerFailure, (state: StockManagerState, {error, store}) => ({
    ...state,
    storeSuppliers: {
      ...state.storeSuppliers,
      [store.storeId]: {},
    },
    errors: [...state.errors, error],
    isStockManagerLoading: false,
    isSuppliersLoading: false,
  })),
  on(StockManagerActions.setSuppliersForStockManager, (state: StockManagerState) => ({
    ...state,
    isStockManagerLoading: true,
    isSuppliersLoading: true,
  })),
  on(StockManagerActions.setSuppliersForStockManagerSuccess, (state: StockManagerState, {
    stockItems,
    store,
    paginationData,
    facetCounts,
    suppliers,
  }) => ({
    ...state,
    stockItems: {
      ...state.stockItems,
      [store.storeId]: stockItems,
    },
    paginationData: {
      ...state.paginationData,
      [store.storeId]: paginationData,
    },
    facetCounts: {
      ...state.facetCounts,
      [store.storeId]: facetCounts,
    },
    storeSuppliers: {
      ...state.storeSuppliers,
      [store.storeId]: suppliers['40'],
    },
    isStockManagerLoading: false,
    isSuppliersLoading: false,
  })),
  on(StockManagerActions.setSuppliersForStockManagerFailure, (state: StockManagerState, {error, store}) => ({
    ...state,
    storeSuppliers: {
      ...state.storeSuppliers,
      [store.storeId]: {},
    },
    errors: [...state.errors, error],
    isStockManagerLoading: false,
    isSuppliersLoading: false,
  })),
  // =======================================================================
  // Departments and SubDepartments
  // =======================================================================
  on(StockManagerActions.getStoreDepartmentsForStockManager, (state: StockManagerState) => ({
    ...state,
    isDepartmentsLoading: true,
    isStockManagerLoading: true,
  })),
  on(StockManagerActions.getStoreDepartmentsForStockManagerSuccess, (state, {store, departments}) => {
    return {
      ...state,
      isDepartmentsLoading: false,
      isStockManagerLoading: true,
      departments: {
        ...state.departments,
        [store.storeId]: departments,
      },
    };
  }),
  on(StockManagerActions.getStoreDepartmentsForStockManagerFailure, (state, {error, store}) => ({
    ...state,
    departments: {
      ...state.departments,
      [store.storeId]: [],
    },
    isDepartmentsLoading: false,
    isStockManagerLoading: true,
    errors: [...state.errors, error],
  })),
  // =======================================================================
  // Filters
  // =======================================================================
  on(StockManagerActions.setStockItemFilters, (state: StockManagerState) => ({
    ...state,
    isStockItemFilterLoading: true,
    isStockManagerLoading: true,
  })),
  on(StockManagerActions.setStockItemFiltersSuccess, (state: StockManagerState, {
    stockItems,
    store,
    paginationData,
    facetCounts,
    filters,
  }) => {
    stockItems = GridUtils.applyLineColoursToStockManager(
      state.lineColours[store.storeId],
      stockItems,
    );
    stockItems = checkForDisabledItems(stockItems, state.disabledRules);
    stockItems = addOriginalValueFieldToItems(stockItems);
    return {
      ...state,
      stockItems: {
        ...state.stockItems,
        [store.storeId]: stockItems,
      },
      paginationData: {
        ...state.paginationData,
        [store.storeId]: paginationData,
      },
      facetCounts: {
        ...state.facetCounts,
        [store.storeId]: facetCounts,
      },
      filters: {
        ...state.filters,
        [store.storeId]: filters,
      },
      isStockItemFilterLoading: false,
      isStockManagerLoading: false,
    };
  }),
  on(StockManagerActions.setStockItemFiltersFailure, (state, {error, store}) => ({
    ...state,
    isStockItemFilterLoading: false,
    isStockManagerLoading: true,
    filters: {
      ...state.filters,
      [store.storeId]: {},
    },
    errors: [...state.errors, error],
  })),
  // =======================================================================
  // Item Disabling
  // =======================================================================
  on(StockManagerActions.getStockItemDisablingRulesSuccess, (state: StockManagerState, {store, rulesDoc}) => ({
    ...state,
    disabledRules: {
      ...state.disabledRules,
      [store.storeId]: rulesDoc,
    },
  })),
  on(StockManagerActions.getStockItemDisablingRulesFailure, (state: StockManagerState, {errors}) => ({
    ...state,
    errors: [...state.errors, errors],
  })),
  on(StockManagerActions.setItemDisabledWithStoreID, (state: StockManagerState, {stockItem, store}) => {
    const stockItemsForStore = state.stockItems[store.storeId] || [];

    const index = stockItemsForStore.findIndex((item: StockItem) => item.code === stockItem.code);
    const updatedStockItemsForStore = [...stockItemsForStore];
    if (index !== -1) {
      updatedStockItemsForStore[index] = {...stockItemsForStore[index], ...stockItem};
    }
    return {
      ...state,
      stockItems: {
        ...state.stockItems,
        [store.storeId]: updatedStockItemsForStore,
      },
      editedItems: {
        ...state.editedItems,
        [store.storeId]: {
          ...state.editedItems[store.storeId],
          [stockItem.code]: {
            ...stockItem,
          },
        },
      },
    };
  }),
  on(StockManagerActions.updateStockItemsWithEditedSuccess, (state: StockManagerState, {
    stockItems,
    store,
    editedItems,
  }) => {
    const updatedStockItems = stockItems.map((item) => {
      const editedItem = editedItems.find((edit) => edit.code === item.code);
      return editedItem ? {...item, ...editedItem} : item;
    });

    return {
      ...state,
      stockItems: {
        ...state.stockItems,
        [store.storeId]: stockItems,
      },
    };
  }),

  // ====================================================================================================
  // Set Editing and Sorting for Column
  // ====================================================================================================
  on(StockManagerActions.setStockManagerMenuActionsWithStore, (state: StockManagerState, {store, gridHeaderMenu}) => {
    const headerMenuData: StoreHeaderMenuData = NGPReportUtils.setNGPReportStoreHeaderMenuActions(
      store,
      state.headerMenuData,
      PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
      gridHeaderMenu,
    );
    return {
      ...state,
      headerMenuData,
    };
  }),

  on(StockManagerActions.setEditedItemAdd, (state: StockManagerState, {stockItem, store}) => {
    const stockItemsForStore = state.stockItems[store.storeId] || [];
    const index = stockItemsForStore.findIndex((item: StockItem) => item.code === stockItem.code);
    const updatedStockItemsForStore = [...stockItemsForStore];
    if (index !== -1) {
      updatedStockItemsForStore[index] = {...stockItemsForStore[index], ...stockItem};
    }
    return {
      ...state,
      editedItems: {
        ...state.editedItems,
        [store.storeId]: {
          ...state.editedItems[store.storeId],
          [stockItem.code]: {
            ...stockItem,
          },
        },
      },
    };
  }),

  on(StockManagerActions.setEditedItemRemove, (state: StockManagerState, {stockItem, store}) => {
    const editedItemsForStore = state.editedItems[store.storeId] || {};
    if (stockItem.code in editedItemsForStore) {
      const {[stockItem.code]: removedItem, ...updatedEditedItemsForStore} = editedItemsForStore;
      const updatedEditedItems = Object.keys(updatedEditedItemsForStore).length > 0
        ? {
          ...state.editedItems,
          [store.storeId]: updatedEditedItemsForStore,
        }
        : Object.keys(state.editedItems).reduce((acc, key) => {
          if (key !== store.storeId) {
            acc[key] = state.editedItems[key];
          }
          return acc;
        }, {});

      return {
        ...state,
        editedItems: updatedEditedItems,
      };
    } else {
      return state;
    }
  }),

  // ====================================================================================================
  // Set VisibleFields
  // ====================================================================================================
  on(StockManagerActions.setStockManagerVisibility, (state: StockManagerState, {colDef, menuData}) => {
    const updatedVisibleFields = {...state.visibleFields};
    colDef?.forEach((item: ColDef) => {
      const fieldIndex = menuData?.findIndex((index: string) => index === item.field);
      if (
        item.field === 'icons' ||
        item.field === 'code' ||
        item.field === 'desc'
      ) {
        updatedVisibleFields[item.field] = true;
      } else {
        updatedVisibleFields[item.field] = !!menuData?.[fieldIndex];
      }
    });
    return {
      ...state,
      visibleFields: updatedVisibleFields,
    };
  }),
  on(StockManagerActions.updateStockManagerSingleVisibleField, (state: StockManagerState, {colId, value}) => ({
    ...state,
    visibleFields: {
      ...state.visibleFields,
      [colId]: value,
    },
  })),
  // ====================================================================================================
  // Set Line Colour
  // ====================================================================================================
  on(StockManagerActions.getLineColoursSuccess, (state: StockManagerState, {lineColours, selectedStore}) => {

    return {
      ...state,
      lineColours: {
        ...state.lineColours,
        [selectedStore.storeId]: lineColours,
      },
    };
  }),
  on(StockManagerActions.getLineColoursFailure, (state: StockManagerState, {error, store}) => ({
    ...state,
    lineColours: {
      ...state.lineColours,
      [store.storeId]: null,
    },
    errors: [...state.errors, error],
  })),

  // ====================================================================================================
  // Update Price, Nom Gp And GD Diff By StoreID
  // ====================================================================================================
  on(StockManagerActions.setPriceGpForStockMangerWithStoreID, (state: StockManagerState, {
    stockItem,
    field,
    storeId,
  }) => {
    const nomGP = +StockFunctions.calcGP(storeId, stockItem, stockItem.sellPriIncl1).toFixed(2);
    const copiedStockItem = {...stockItem};

    if (field === 'sellPriIncl1') {
      copiedStockItem.nominalGP = nomGP;
    } else if (field === 'nominalGP') {
      const newPrice = ReportUtils.calculatePriceFromGP(storeId, stockItem);
      copiedStockItem.sellPriIncl1 = newPrice;
      copiedStockItem.nominalGP = +StockFunctions.calcGP(storeId, stockItem, newPrice).toFixed(2);
    }

    const updatedStockItemsForStore = (state.stockItems[storeId] || []).map((item: StockItem) =>
      item.code === stockItem.code ? copiedStockItem : item,
    );

    return {
      ...state,
      stockItems: {
        ...state.stockItems,
        [storeId]: updatedStockItemsForStore,
      },
    };
  }),
  on(StockManagerActions.togglePriceBanding, (state: StockManagerState) => ({
    ...state,
    isPriceBanding: !state.isPriceBanding,
  })),
  on(StockManagerActions.getEditedItemsFromTypesense, (state, {store}) => ({
    ...state,
    isStockManagerLoading: true,
    stockItems: {
      ...state.stockItems,
      [store.storeId]: [],
    },
  })),
  on(StockManagerActions.getEditedItemsFromTypesenseSuccess, (state, {
    editedItemsFromTypesense,
    store,
    priceBandedItems,
  }) => ({
    ...state,
    isStockManagerLoading: false,
    editedItemsFromTypesense,
    stockItems: {
      ...state.stockItems,
      [store.storeId]: priceBandedItems,
    },
    paginationData: {
      ...state.paginationData,
      [store.storeId]: {
        ...state.paginationData[store.storeId],
        pageSize: editedItemsFromTypesense.length,
        currentPage: 1,
      },
    },
  })),
  on(StockManagerActions.getEditedItemsFromTypesenseFailure, (state) => ({
    ...state,
    isStockManagerLoading: false,
    editedItemsFromTypesense: [],
  })),
  on(StockManagerActions.removeEditedItemsAfterStockUpdate, (state, {store}) => ({
    ...state,
    editedItems: {
      ...state.editedItems,
      [store.storeId]: {},
    },
    editedItemsFromTypesense: [],
  })),

//===============================================================================================================
// Set the Stock Manager Preview Column Data
//===============================================================================================================

  on(StockManagerActions.setStockManagerPreviewColumnsSuccess, (state, {columnData}) => {
    const newVisibleFields = {...state.visibleFields};
    const columnFields = Object.values(columnData);
    Object.keys(newVisibleFields).forEach((field: string) => {
      newVisibleFields[field] = columnFields.includes(field);
    });
    return {
      ...state,
      previewColumnData: newVisibleFields,
    };
  }),
  on(StockManagerActions.setStockManagerPreviewColumnsFailure, (state, {error}) => ({
    ...state,
    errors: [...state.errors, error],
  })),
);

export const stockManagerReducer = (state: StockManagerState, action: Action): StockManagerState => createStockManagerReducer(state, action);
