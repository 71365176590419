/**
 * An object containing various column types that can be applied to various columns.
 *
 * @example
 * // Defined Types:
 * - alignRight: Aligns text on the right.
 * - cellEdit: displays the cell as editing.
 * - cellNormal: displays the cell as normal.
 */
export const gridColumnTypes = {
  alignRight: {
    cellStyle: {
      textAlign: 'right'
    }
  },
  cellEdit: {
    cellStyle: {
      borderRadius: '5px',
      background: 'rgba(250, 250, 250, 0.1)',
      border: '1px solid #0b79d1',
      borderBottom: 'none',
    }
  },
  cellNormal: {
    cellStyle: {
      borderRadius: 'initial',
      background: 'initial',
      border: 'initial',
      outline: 'initial',
    }
  }
};

export interface ICellStyles {
  cellStyle: {
    textAlign?: string;
    borderRadius?: string;
    background?: string;
    border?: string;
    borderBottom?: string;
    outline?: string;
  };
}

export interface IGridColumnTypes {
  alignRight: ICellStyles;
  cellEdit: ICellStyles;
  cellNormal: ICellStyles;
}
