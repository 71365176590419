<ion-content>
  <div class="options">
    <div (click)="onAdvancedFiltersClick()" *ngIf="currentSelectedPage === 'stock-manager'" class="item">
      <span>Advanced Filters</span>
      <app-display-icon [icon]="icons.filterVariant" class="icon"></app-display-icon>
    </div>

    <div (click)="onEditTabNavClick()" class="item">
      <span>Edit Table Settings</span>
      <app-display-icon [icon]="icons.settings" class="icon"></app-display-icon>
    </div>

    <div class="item toggle">
      <span>Fit To Table</span>
      <ion-toggle (click)="onToggleFitToTableClick()" [(ngModel)]="showAllColDef"></ion-toggle>
    </div>

    <div class="item toggle">
      <span>Price Banding</span>
      <ion-toggle (click)="onTogglePriceBandingClick($event)" [checked]="isCurrentlyPriceBanding"></ion-toggle>
    </div>

    <div (click)="onSetPreviewColumnsClick($event)" class="item">
      <span>Set Preview Columns</span>
      <app-display-icon [icon]="icons.eyeOffOutline" class="icon"></app-display-icon>
    </div>

    <div (click)="onVisibleFieldsClick($event)" class="item">
      <span>Visible Fields</span>
      <app-display-icon [icon]="icons.eyeOutline" class="icon"></app-display-icon>
    </div>

  </div>
</ion-content>
