<div class="shared-modal-basic-container">

  <ion-header>
    <ion-toolbar>
      <ion-title>
        <div>
          <div>{{ modalTitle }}</div>
          <span></span>
          <ion-button *ngIf="buttonCloseCross" fill="clear" (click)="onButtonPress('button-close')">
            <app-display-icon *ngIf="buttonCloseCross" [icon]="icons.closeBoxOutline"></app-display-icon>
          </ion-button>
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div class="context-header-container" *ngIf="modalTitleIcon || contextHeading">
      <ng-container *ngIf="modalTitleIcon">
        <app-display-icon
          class="title-icon"
          *ngIf="modalTitleIcon" [icon]="modalTitleIcon" [colour]="modalTitleIconColor">
        </app-display-icon>
      </ng-container>
      <ng-container *ngIf="contextHeading">
        <div>{{ contextHeading }}</div>
      </ng-container>
    </div>

    <div class="context-description-container" *ngIf="contextDescription">
      <ng-container *ngFor="let contextItem of contextDescription; trackBy: trackByFunction">
        <div>{{ contextItem }}</div>
      </ng-container>
      <ng-container *ngIf="isImageVisible">
        <div class="image-container">
          <img
            *ngIf="imageUrl"
            [ngSrc]="imageUrl"
            alt="Gallix Freshness Indicator Flow Chart"
            width="785"
            height="416"/>
        </div>
      </ng-container>
    </div>

  </ion-content>

  <ng-container *ngIf="buttonClose || buttonAccept">
    <ion-footer>
      <ion-button *ngIf="buttonClose" fill="outline" (click)="onButtonPress('button-close')">{{ buttonCloseText }}</ion-button>
      <ion-button *ngIf="buttonSecondary" (click)="onButtonPress('button-secondary')">{{ buttonSecondaryText }}</ion-button>
      <ion-button *ngIf="buttonAccept" (click)="onButtonPress('button-accept')">{{ buttonAcceptText }}</ion-button>
    </ion-footer>
  </ng-container>

</div>
