import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import * as SharedGridActions from './shared-grid.actions';
import * as StockManagerActions from '../../../../app/features/stock-manager/store/stock-manager.actions';
import {forkJoin, from, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {StockItem, TableNavSettings, VatRates} from '../../../shared-utilities/models-old/datastructures';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IPriceBandPerStore} from '../../../shared/shared-models/price-banding/price-band';
import * as NGPReportActions from '../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {Action, select, Store} from '@ngrx/store';
import {
  selectCurrentPageAndTabObject,
  selectUser,
  selectUserStores,
} from '../../../features-as-modules/feature-core/store/core.selectors';
import {selectAllVatRates} from '../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {IStore} from '../../../shared/shared-models/store/store';
import {path_users_userId_settings_table_navigation} from '../../../shared/shared-services/database-paths';
import {
  CollectionUsersSettingsService,
} from '../../../shared/shared-services/firebase/collection-users-settings.service';

@Injectable()
export class SharedGridEffects {

//===============================================================================================================
// Get Table Nav Settings
//===============================================================================================================

  // '[Shared Grid][Table Nav] Get Table Nav Settings'
  getTableNavSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedGridActions.getTableNavSettings),
      withLatestFrom(this.store.pipe(select(selectUser))),
      mergeMap(([_, {id}]) =>
        from(this.collectionUsersSettingsService.getDocument<TableNavSettings>(path_users_userId_settings_table_navigation(id))).pipe(
          map((data: TableNavSettings) => {
            if (!data.cellDebounce) {
              data.cellDebounce = 1500;
            }
            return SharedGridActions.getTableNavSettingsSuccess({settings: data});
          }),
          catchError((error: IError) => of(SharedGridActions.getTableNavSettingsFailure({error}))),
        ),
      ),
    ),
  );
  // '[Shared Grid][Price Banding] Get Price Banding for Stores'
  getPriceBandingForStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedGridActions.getPriceBandingForStores),
      mergeMap(() =>
        from(this.firebaseService.getPriceBandingForStores()).pipe(
          map((priceBandsPerStore: IPriceBandPerStore) => {
            return SharedGridActions.getPriceBandingForStoresSuccess({priceBandsPerStore});
          }),
          catchError((error: IError) => of(SharedGridActions.getPriceBandingForStoresFailure({error}))),
        ),
      ),
    ),
  );

//===============================================================================================================
// Price Banding
//===============================================================================================================
  //===============================================================================================================
  setItemDisabledStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedGridActions.setItemDisabledStatus),
      withLatestFrom(this.store.pipe(select(selectCurrentPageAndTabObject))),
      map(([{item, enable}, currentPage]) => {
        switch (currentPage.currentSelectedPage.currentPage) {
          case 'ngp-report':
            return NGPReportActions.setItemDisabledStatus({ngpReport: item as NGPReport, enable});
          case 'stock-manager':
            return StockManagerActions.setItemDisabledStatus({stockItem: item as StockItem, enable});
        }
      }),
    ),
  );

  //===============================================================================================================
  // set Item Disabled
  getVatRateConversion$ = createEffect(() => this.actions$.pipe(
    ofType(SharedGridActions.getVatRateConversion),
    withLatestFrom(
      this.store.pipe(select(selectUserStores)),
      this.store.pipe(select(selectAllVatRates)),
    ),
    switchMap(([action, userStores, vatRates]) => {
      const actions: Action[] = [];
      if (Object.keys(vatRates).length > 0) {
        actions.push(SharedGridActions.getVatRateConversionWithExistingVatRates({
          userStores,
          vatRates: vatRates,
        }));
      } else {
        actions.push(SharedGridActions.getVatRateConversionWithoutExistingVatRates({
          userStores,
        }));
      }
      return actions;
    }),
  ));
  //===============================================================================================================
  // Get Vat Rate Conversion
  //===============================================================================================================
  getVatRateConversionWithExistingVatRates$ = createEffect(() => this.actions$.pipe(
    ofType(SharedGridActions.getVatRateConversionWithExistingVatRates),
    map(({userStores, vatRates}) => {
      const vatRatesResult = userStores.reduce((acc, userStore) => {
        acc[userStore.storeId] = vatRates[userStore.storeId];
        return acc;
      }, {});
      return SharedGridActions.getVatRateConversionSuccess({vatRates: vatRatesResult});
    }),
  ));
  getVatRateConversionWithoutExistingVatRates$ = createEffect(() => this.actions$.pipe(
    ofType(SharedGridActions.getVatRateConversionWithoutExistingVatRates),
    switchMap(({userStores}) => {
      const vatRatesObservables = userStores.map((userStore: IStore) =>
        this.firebaseService.subStoreDoc('data/singular_documents/vat_rates', userStore.storeId)
          .pipe(
            take(1),
            map((vr: VatRates) => ({storeId: userStore.storeId, vatRate: vr})),
          ),
      );
      return forkJoin(vatRatesObservables).pipe(
        map((vatRatesArray) => {
          const vatRates = vatRatesArray.reduce((acc, {storeId, vatRate}) => {
            acc[storeId] = vatRate;
            return acc;
          }, {});
          return SharedGridActions.getVatRateConversionSuccess({vatRates});
        }),
        catchError((error: IError) => of(SharedGridActions.getVatRateConversionFailure({error}))),
      );
    }),
  ));
  hasUpdatedFirebase = false;
  // '[Shared Grid][Table Nav] Get Table Nav Settings Success',
  setTableNavSettingsInFirebase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedGridActions.getTableNavSettingsSuccess),
      withLatestFrom(this.store.pipe(select(selectUser))),
      mergeMap(([{settings}, {id}]) => {
        if (this.hasUpdatedFirebase) {
          return from(this.collectionUsersSettingsService.setDocument<TableNavSettings>(path_users_userId_settings_table_navigation(id), settings)).pipe(
            map(() => {
              return {type: '[Shared Grid][Table Nav] Table Nav Settings Set on Firebase'};
            }),
            catchError((error: IError) => {
              return of(SharedGridActions.getTableNavSettingsFailure({error}));
            }),
          );
        } else {
          this.hasUpdatedFirebase = true;
          return of({type: 'Settings Fetched From Firebase '});
        }
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly store: Store,
    private firebaseService: FirebaseService,
    private collectionUsersSettingsService: CollectionUsersSettingsService,
  ) {
  }
}
