<ng-container *ngIf="isPopOver; else componentContentTemplate">
  <ion-header id="pop-title">
    <ion-toolbar>
      <ion-title>{{ title ? title : 'Date Selector' }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ng-container *ngTemplateOutlet="componentContentTemplate"></ng-container>
  </ion-content>

  <div class="buttons" *ngIf="this.visibleButtons">
    <button *ngIf="visibleButtons.reset" class="reset-button" (click)="reset()">RESET</button>
    <button *ngIf="visibleButtons.ok" class="ok-button" (click)="ok()">OK</button>
    <button *ngIf="visibleButtons.cancel" class="cancel-button" (click)="cancel()">CANCEL</button>
  </div>
</ng-container>

<ng-template #componentContentTemplate>
  <div class="content">
    <div class="container">
      <div id="label">Only show items </div>
      <button mat-raised-button (click)="toggleDateFilter()">
        {{ buttonText }}
      </button>
    </div>
    <div class="result date-range" *ngIf="filterDateValues">
      <div class="date start" *ngIf="filterDateValues.hasOwnProperty('start')">
        <ion-datetime-button datetime="start-datetime"></ion-datetime-button>

        <ion-modal [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime [showDefaultButtons]="true" id="start-datetime" presentation="date"  [(ngModel)]="filterDateValues.start"
                          [max]="filterDateValues.end ? filterDateValues.end : (maxDateISO ? maxDateISO : todayISO)"
                          [min]="minDateISO"
            >
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </div>
      <div class="date end" *ngIf="filterDateValues.hasOwnProperty('end')">
        <ion-datetime-button datetime="end-datetime"></ion-datetime-button>

        <ion-modal [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime [showDefaultButtons]="true" id="end-datetime" presentation="date" [(ngModel)]="filterDateValues.end"
                          [max]="(maxDateISO ? maxDateISO : todayISO)"
                          [min]="filterDateValues.start ? filterDateValues.start : minDateISO"
            >
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </div>
    </div>
  </div>
</ng-template>


