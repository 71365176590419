import {Component, Input} from '@angular/core';

/**
 * A component that renders an SVG icon for "content-copy"
 *
 * @member {string} tooltip ? The tooltip that needs to be displayed on this icon.
 */
@Component({
  selector: 'app-icon-content-copy',
  templateUrl: './icon-content-copy.component.html',
  styleUrls: ['./icon-content-copy.component.scss'],
})
export class IconContentCopyComponent {
  /**
   * Assign a tooltip that needs to be displayed on this icon as soon as the user hovers on it.
   *
   * @param tooltip ? The tooltip that needs to be displayed on this icon.
   */
  @Input() tooltip?: string;
}
