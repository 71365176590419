import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  selectCurrentPageAndTabObject,
  selectSelectedUserStore,
} from '../../../features-as-modules/feature-core/store/core.selectors';
import {
  selectFiltersAndToolsProperty,
  selectNGPReportsFiltered,
  selectNGPReportsMenuDataByStoreId,
  selectNGPVisibleFields,
  selectVatRateBySelectedStore,
} from '../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {INavigationPage} from '../../../shared-utilities/models-old/page/page';
import {SharedGridState} from './shared-grid.reducer';
import {IStore} from '../../../shared/shared-models/store/store';
import {IPriceBand, IPriceBandPerStore} from '../../../shared/shared-models/price-banding/price-band';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {
  applyPriceBanding,
  PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
} from '../../../shared/shared-utils/price/price-banding.utils';
import {StockItem, VatRates} from '../../../shared-utilities/models-old/datastructures';
import {
  selectAllStockItemsForCurrentSelectedStore,
  selectEnablePriceBandingForStockManager,
  selectStockManagerMenuDataByStoreId,
} from '../../../features/stock-manager/store/stock-manager.selectors';

export const selectSharedGridState = createFeatureSelector<SharedGridState>('shared-grid');

//===============================================================================================================
// Export to CSV
//===============================================================================================================
export const selectExportToCSVSettings = createSelector(
  selectSharedGridState,
  (state: SharedGridState) => state.exportToCSVSettings,
);

//===============================================================================================================
// Get Table Nav Settings
//===============================================================================================================
export const selectGridHeaderMenuDataByCurrentPage = createSelector(
  selectCurrentPageAndTabObject,
  selectNGPReportsMenuDataByStoreId,
  selectStockManagerMenuDataByStoreId,
  (
    navigation: { currentSelectedPage: INavigationPage },
  ) => {
    const currentPage = navigation.currentSelectedPage.currentPage;
    switch (currentPage) {
      case 'ngp-report':
        return selectNGPReportsMenuDataByStoreId;
      case 'stock-manager':
        return selectStockManagerMenuDataByStoreId;
      default:
        return {};
    }
  },
);

export const selectSharedGridVisibleFieldsByCurrentPage = createSelector(
  selectCurrentPageAndTabObject,
  (navigation: { currentSelectedPage: INavigationPage }) => {
    const currentPage = navigation.currentSelectedPage.currentPage;
    switch (currentPage) {
      case 'ngp-report':
        return selectNGPVisibleFields;
      case 'stock-manager':
        return {};
      default:
        return {};
    }
  },
);

export const selectTableNavSettings = createSelector(
  selectSharedGridState,
  (state: SharedGridState) => state.tableNavSettings,
);

//===============================================================================================================
// Price Banding
//===============================================================================================================

export const selectSharedGridVatRateBySelectedStore = createSelector(
  selectSharedGridState,
  selectSelectedUserStore,
  (state: SharedGridState, selectedUserStore: IStore) => state.vatRates[selectedUserStore.storeId] || {},
);
export const selectIsGetPriceBandingPerStoreLoading = createSelector(
  selectSharedGridState,
  (state: SharedGridState) => state.isPriceBandingPerStoreLoading,
);

export const selectPriceBandingForAllStores = createSelector(
  selectSharedGridState,
  (state: SharedGridState) => state.priceBandsPerStore,
);

export const selectPriceBandingForUserSelectedStore = createSelector(
  selectPriceBandingForAllStores,
  selectSelectedUserStore,
  (priceBands: IPriceBandPerStore, userStore: IStore): IPriceBand[] => {
    return priceBands[userStore.storeId];
  },
);

export const selectNGPReportsPriceBanded = createSelector(
  selectPriceBandingForUserSelectedStore,
  selectNGPReportsFiltered,
  selectFiltersAndToolsProperty<boolean>('isApplyPriceBandingOn'),
  selectSelectedUserStore,
  selectVatRateBySelectedStore,
  (priceBands: IPriceBand[], ngpReports: NGPReport[], isPriceBanding, userStore, vatRates: VatRates): NGPReport[] => {
    if (isPriceBanding) {
      return applyPriceBanding<NGPReport[]>(ngpReports, priceBands, PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS, userStore, vatRates);
    } else {
      return ngpReports;
    }
  },
);

export const selectStockManagerPriceBanded = createSelector(
  selectPriceBandingForUserSelectedStore,
  selectAllStockItemsForCurrentSelectedStore,
  selectEnablePriceBandingForStockManager,
  selectSelectedUserStore,
  selectSharedGridVatRateBySelectedStore,
  (
    priceBands: IPriceBand[],
    stockItems: StockItem[],
    isPriceBanding: boolean,
    userStore: IStore,
    vatRates: VatRates
  ): StockItem[] => {
    if (isPriceBanding) {
      return applyPriceBanding(
        stockItems,
        priceBands,
        PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
        userStore,
        vatRates
      );
    } else {
      return stockItems;
    }
  }
);



