import * as CryptoJS from 'crypto-js';

export const genUUID = (length: number = 20) => {
  const chars = [];
  for (let i = 48; i <= 57; i++) {
    chars.push(String.fromCharCode(i));
  }
  for (let i = 65; i <= 90; i++) {
    chars.push(String.fromCharCode(i), String.fromCharCode(i).toLowerCase());
  }
  let uid = '';
  const random = window.crypto.getRandomValues(new Uint8Array(length));

  for (const x of random) {
    uid += chars[x % chars.length];
  }
  return uid;
};
