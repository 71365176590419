import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-multiple-error-popover',
  templateUrl: './multiple-error-popover.component.html',
  styleUrls: ['./multiple-error-popover.component.scss'],
})
export class MultipleErrorPopoverComponent implements OnInit {
  @Input() description: string[];

  constructor() { }

  ngOnInit() {}

}
