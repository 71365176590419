<div class="modal-container">

  <ion-header>
    <ion-toolbar>
      <ion-title>
        <div>
          <div>Freshness Indicator Explanation</div>
          <span></span>
          <ion-button fill="clear" (click)="onButtonPress()">
            <app-display-icon [icon]="icons.close"></app-display-icon>
          </ion-button>
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div>
      <p>
      <span
        class="header">How does Gallix work and what do the different coloured dots next to the store name mean?</span>
        <br>
        This flow chart shows how Gallix interacts with your server and explains what the different colors of the
        data freshness indicators mean.
        <br>
        Remember, a red light doesn't mean your server is disconnected from Gallix. It simply means the data you are
        viewing is old. You can still use Gallix, and all actions will be processed when it is online.
        <br>
        If the light is red and you need the latest stock information, refresh the page to force an update. If the light
        stays red for more than two minutes, please contact us.
        <br>
        If the server is offline, your actions will be queued and processed as soon as the connection is restored.
      </p>
    </div>



    <div *ngIf="isImageLoading" class="loading-indicator">
      <ion-spinner name="crescent" color="primary"></ion-spinner>
    </div>
    <div class="img-div">
      <img
        *ngIf="imageUrl"
        [ngSrc]="imageUrl"
        alt="Gallix Flow Chart"
        width="785"
        height="416"
        (load)="onImageLoad()"/>
    </div>
  </ion-content>

</div>
