export enum Icons {
  arrowDown = 'arrowDown',
  arrowLeft = 'arrowLeft',
  arrowRight = 'arrowRight',
  arrowUp = 'arrowUp',
  backSpaceOutline = 'backSpaceOutline',
  bandage = 'bandage',
  barcode = 'barcode',
  barcodeScan = 'barcodeScan',
  bellOutline = 'bellOutline',
  bug = 'bug',
  bugOutline = 'bugOutline',
  calendar = 'calendar',
  calendarClear = 'calendarClear',
  calendarMultiSelect = 'calendarMultiSelect',
  calendarMultiSelectOutline = 'calendarMultiSelectOutline',
  cancel = 'cancel',
  cart = 'cart',
  chevronDown = 'chevronDown',
  chevronLeft = 'chevronLeft',
  chevronRight = 'chevronRight',
  chevronUp = 'chevronUp',
  clipboard = 'clipboard',
  close = 'close',
  closeOutline = 'closeOutline',
  closeBox = 'closeBox',
  closeBoxOutline = 'closeBoxOutline',
  closeCircle = 'closeCircle',
  closeCircleOutline = 'closeCircleOutline',
  closeOctagon = 'closeOctagon',
  closeOctagonOutline = 'closeOctagonOutline',
  closeThick = 'closeThick',
  contentCopy = 'contentCopy',
  contentSave = 'contentSave',
  contentSaveOutline = 'contentSaveOutline',
  create = 'create',
  customSortUpDown = 'customSortUpDown',
  delete = 'delete',
  deleteClock = 'deleteClock',
  deleteClockOutline = 'deleteClockOutline',
  deleteOutline = 'deleteOutline',
  download = 'download',
  downloadOutline = 'downloadOutline',
  emailAlertOutline = 'emailAlertOutline',
  emailCheckOutline = 'emailCheckOutline',
  emailFastOutline = 'emailFastOutline',
  emailSyncOutline = 'emailSyncOutline',
  emailVariant = 'emailVariant',
  fileTrayStacked = 'fileTrayStacked',
  filterCogOutline = 'filterCogOutline',
  filterVariant = 'filterVariant',
  filterVariantMinus = 'filterVariantMinus',
  filterVariantPlus = 'filterVariantPlus',
  filterVariantRemove = 'filterVariantRemove',
  eyeOffOutline = 'eyeOffOutline',
  eyeOutline = 'eyeOutline',
  googleDinosaur = 'googleDinosaur',
  helpCircleOutline = 'helpCircleOutline',
  infoSlabCircleOutline = 'infoSlabCircleOutline',
  home = 'home',
  key = 'key',
  linkVariant = 'linkVariant',
  logIn = 'logIn',
  logoIQ = 'logoIQ',
  lockOpenOutline = 'lockOpenOutline',
  lockClosedOutline = 'lockClosedOutline',
  mail = 'mail',
  menu = 'menu',
  newBox = 'newBox',
  notEqualVariant = 'notEqualVariant',
  openInNew = 'openInNew',
  pageFirst = 'pageFirst',
  pageLast = 'pageLast',
  pdfBox = 'pdfBox',
  pencil = 'pencil',
  pencilOff = 'pencilOff',
  person = 'person',
  personPlusOutline = 'personPlusOutline',
  pieChart = 'pieChart',
  pint = 'pint',
  planet = 'planet',
  plus = 'plus',
  progressPencil = 'progressPencil',
  priceTags = 'priceTags',
  radioBoxBlank = 'radioBoxBlank',
  radioBoxMarked = 'radioBoxMarked',
  receiptOutline = 'receiptOutline',
  receiptTextCheckOutline = 'receiptTextCheckOutline',
  receiptTextEditOutline = 'receiptTextEditOutline',
  receiptTextOutline = 'receiptTextOutline',
  receiptTextRemoveOutline = 'receiptTextRemoveOutline',
  receiptTextSendOutline = 'receiptTextSendOutline',
  settings = 'settings',
  sort = 'sort',
  tableCog = 'tableCog',
  terminal = 'terminal',
  today = 'today',
}
