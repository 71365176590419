import {Component, ElementRef, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {Icons} from '../../../shared-icons/icons';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {
  selectSuppliersByUserSelectedStore,
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {selectSuppliersForUserSelectedStore} from '../../../../features/stock-manager/store/stock-manager.selectors';
import {ISearchableFields} from '../../../../shared/shared-models/type-sense/default-searchable-fields';
import {StockItem, Supplier} from '../../../../shared-utilities/models-old/datastructures';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';

@Component({
  selector: 'app-cell-editor-supplier',
  templateUrl: './cell-editor-supplier.component.html',
  styleUrls: ['./cell-editor-supplier.component.scss'],
})
export class CellEditorSupplierComponent implements ICellEditorAngularComp {

  @ViewChild('input', {static: true}) input: ElementRef;

  value: string | number;
  params: ICellEditorParams;
  width: number;
  colDefField: string;
  departments = {};
  unSortedSuppliers: { key: string; description: string; }[];
  currentPage: string;
  searchControl = new FormControl('');
  filteredOptions: { key: string; description: string; }[] = [];

  currentPage$: Observable<string>;
  unSortedSuppliers$: Observable<{ [key: string]: Supplier } | ISearchableFields>;

  protected readonly icons: typeof Icons = Icons;
  protected readonly KeyboardEvent = KeyboardEvent;

  constructor(
    private readonly store: Store,
  ) {
  }

  agInit(params: ICellEditorParams): void {
    this.currentPage$ = this.store.select(selectCurrentPage);
    this.currentPage$.subscribe((page: string) => {
      this.currentPage = page;
    });
    if (this.currentPage === 'ngp-report') {
      this.unSortedSuppliers$ = this.store.select(selectSuppliersByUserSelectedStore);
    } else {
      this.unSortedSuppliers$ = this.store.select(selectSuppliersForUserSelectedStore);
    }
    this.unSortedSuppliers$.subscribe((suppliers: ISearchableFields | { [key: string]: Supplier }): void => {
      this.unSortedSuppliers = this.objectToArray(suppliers);
      this.filteredOptions = this.unSortedSuppliers;
    });

    this.searchControl.valueChanges.pipe(
      debounceTime(200),
    ).subscribe((searchTerm: string): void => {
      this.filterOptions(searchTerm);
    });

    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
    this.width = this.params.colDef.width;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
    setTimeout(() => {
      this.params.api.setColumnWidths([{key: this.colDefField, newWidth: 400}]);
    }, 0);

  }

  objectToArray(obj: ISearchableFields | { [key: string]: Supplier }): { key: string; description: string; }[] {
    return Object.keys(obj).map((key: string) => ({key, description: obj[key].name}));
  }

  filterOptions(searchTerm: string): void {
    if (!searchTerm) {
      this.filteredOptions = this.unSortedSuppliers;
      return;
    }
    const lowerSearchTerm = searchTerm.toLowerCase();
    this.filteredOptions = this.unSortedSuppliers.filter((option: { key: string; description: string }) => {
      const keyMatches = option.key.toLowerCase().includes(lowerSearchTerm);
      const descriptionMatches = option.description?.toLowerCase().includes(lowerSearchTerm);
      return keyMatches || descriptionMatches;
    });
  }

  onSelectionChange(event: Event): void {
    this.value = (event.target as HTMLSelectElement).value;
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.value;
    GridUtils.removeIsEditingFlagAndDispatch(this.params.data as StockItem | NGPReport, this.colDefField, this.store, this.currentPage);
  }

  selectBestMatch(event: Event): void {
    const keyBoardEvent = event as KeyboardEvent;
    const inputElement = keyBoardEvent.target as HTMLInputElement;
    if (this.filteredOptions.length > 0) {
      if (inputElement.value.length > 0) {
        this.value = this.filteredOptions[0].key;
        this.params.data[this.colDefField] = this.value;
      } else {
        this.value = this.params.data[this.colDefField];
        this.params.data[this.colDefField] = this.value;
      }
      GridUtils.updateIsEditedAndForceRefresh(this.params);
    }
  }

  getValue(): string | number {
    const type = this.params.colDef.cellDataType;
    this.params.data[this.colDefField] = this.value;
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    GridUtils.updateSingleItem(this.params.data as StockItem | NGPReport, this.colDefField, this.value, this.store, this.currentPage);
    if (type === 'text') {
      return this.params.data[this.colDefField] === '' ? this.params.data.originalValue[this.colDefField].value : this.value as string;
    } else if (type === 'number') {
      return this.params.data[this.colDefField] === 0 ? this.params.data.originalValue[this.colDefField].value : +this.value;
    }
  }

  refresh(params: ICellEditorParams): boolean {
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    return false;
  }

  afterGuiAttached(): void {
    this.input.nativeElement.focus();
  }

  trackByFunction(index: number): number {
    return index;
  }
}
