import {ISearchableFields} from '../../shared-models/type-sense/default-searchable-fields';

export function searchStockItemsCreateFilterString(obj: { [key: string]: ISearchableFields }): string {
  return Object.entries(obj)
    .map(([outerKey, innerObj]) => {
      return Object.keys(innerObj)
        .map((innerKey: string): string => {
          return `${outerKey}:=${innerKey}`;
        }).join(' && ');
    }).join(' && ');
}
