import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {SaveUserSettingsPageRoutingModule} from './save-user-settings-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SaveUserSettingsPageRoutingModule
  ],
  declarations: []
})
export class SaveUserSettingsPageModule {
}
