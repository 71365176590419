import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectSelectedUserStore} from '../../../../../features-as-modules/feature-core/store/core.selectors';
import {Observable} from 'rxjs';
import {IStore} from '../../../../../shared/shared-models/store/store';
import {StoreInfo} from '../../../../../shared-utilities/models-old/datastructures';

@Component({
  selector: 'app-settings-ngp-reports-container',
  templateUrl: './settings-ngp-reports-container.component.html',
  styleUrls: ['./settings-ngp-reports-container.component.scss'],
})
export class SettingsNgpReportsContainerComponent implements OnInit {
  @Input() storeInfo: StoreInfo;
  userStore$: Observable<IStore>;


  constructor(
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    this.userStore$ = this.store.select(selectSelectedUserStore);
  }

}
