import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import * as SharedActions from './shared-filter.actions';
import {select, Store} from '@ngrx/store';
import {selectSelectedAdvancedFilterGroup} from './shared-filter.selectors';
import {IAdvancedFilterGroup} from '../../../shared/shared-models/filter-groups/filter-groups';
import {selectSelectedUserStore} from '../../../features-as-modules/feature-core/store/core.selectors';
import {from, of} from 'rxjs';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {
  CollectionStoresSettingsService,
} from '../../../shared/shared-services/firebase/collection-stores-settings.service';
import {
  path_stores_storeId_settings_advanced_filter,
  path_stores_storeId_settings_advanced_filter_group,
} from '../../../shared/shared-services/database-paths';


@Injectable()
export class SharedFilterEffects {

  // ====================================================================================================
  // Advanced Filter Group/s
  // ====================================================================================================

  // '[Shared][Advanced Filter Groups] Delete Advanced Filter Group Filter By Id',
  deleteAdvancedFilterGroupFilterById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SharedActions.deleteSelectedAdvancedFilterGroup,
      ),
      withLatestFrom(
        this.store.pipe(select(selectSelectedAdvancedFilterGroup)),
        this.store.pipe(select(selectSelectedUserStore))
      ),
      mergeMap(([_, selectedAdvancedFilterGroup, store]) => {
        return from(this.collectionStoresSettingsService.deleteDocument(path_stores_storeId_settings_advanced_filter_group(store.storeId, `${selectedAdvancedFilterGroup.id}`))).pipe(
          map(() => {
            return SharedActions.deleteSelectedAdvancedFilterGroupSuccess({id: selectedAdvancedFilterGroup.id});
          }),
          catchError((error: IError) => of(SharedActions.deleteSelectedAdvancedFilterGroupFailure({error}))),
        );
      }),
    ),
  );

  // '[Shared][Advanced Filter Groups] Delete Advanced Filter Group Filter',
  deleteAdvancedFilterGroupFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SharedActions.deleteAdvancedFilterGroupFilter,
        SharedActions.deleteAdvancedFilterGroupGroup,
      ),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectSelectedAdvancedFilterGroup))
      ),
      mergeMap(([_, store, selectedAdvancedFilterGroup]) => {
        const advancedFilterGroup: IAdvancedFilterGroup = {
          ...selectedAdvancedFilterGroup,
          saved: true,
          lastModified: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        };
        return from(this.collectionStoresSettingsService.setDocument<IAdvancedFilterGroup>(
          path_stores_storeId_settings_advanced_filter(store.storeId, advancedFilterGroup.id.toString()), advancedFilterGroup),
        ).pipe(
          map(() => {
            return SharedActions.saveSelectedAdvancedFilterGroupSuccess({id: advancedFilterGroup.id});
          }),
          catchError((error: IError) => of(SharedActions.saveSelectedAdvancedFilterGroupFailure({error}))),
        );
      }),
    ),
  );

  // '[Shared][Advanced Filter Groups] Get Advanced Filter Group By Id',
  getAdvancedFilterGroupById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getAdvancedFilterGroupById),
      mergeMap(({id, store}) => {
        return from(this.collectionStoresSettingsService.getDocument<IAdvancedFilterGroup>(
          path_stores_storeId_settings_advanced_filter_group(store.storeId, id.toString())),
        ).pipe(
          map((advancedFilterGroup: IAdvancedFilterGroup) => {
            return SharedActions.getAdvancedFilterGroupByIdSuccess({advancedFilterGroup});
          }),
          catchError((error: IError) => of(SharedActions.getAdvancedFilterGroupByIdFailure({error}))),
        );
      }),
    ),
  );

  // '[Shared][Advanced Filter Groups] Get Advanced Filter Groups'
  getAdvancedFilterGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getAdvancedFilterGroups),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore))
      ),
      mergeMap(([action, store]) => {
        return from(this.collectionStoresSettingsService.getStoreSettingsAdvancedFilterGroups(store)).pipe(
          map((advancedFilterGroups: IAdvancedFilterGroup[]) => {
            return SharedActions.getAdvancedFilterGroupsSuccess({advancedFilterGroups});
          }),
          catchError((error: IError) => of(SharedActions.getAdvancedFilterGroupsFailure({error}))),
        );
      }),
    ),
  );

  // '[Shared][Advanced Filter Groups] Save Selected Advanced Filter Group'
  saveSelectedAdvancedFilterGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SharedActions.saveSelectedAdvancedFilterGroup,
      ),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStore)),
        this.store.pipe(select(selectSelectedAdvancedFilterGroup))
      ),
      mergeMap(([_, store, selectedAdvancedFilterGroup]) => {
        const advancedFilterGroup: IAdvancedFilterGroup = {
          ...selectedAdvancedFilterGroup,
          saved: true,
          lastModified: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        };
        return from(this.collectionStoresSettingsService.setDocument<IAdvancedFilterGroup>(
          path_stores_storeId_settings_advanced_filter(store.storeId, advancedFilterGroup.id.toString()), advancedFilterGroup)).pipe(
          map(() => {
            return SharedActions.saveSelectedAdvancedFilterGroupSuccess({id: advancedFilterGroup.id});
          }),
          catchError((error: IError) => of(SharedActions.saveSelectedAdvancedFilterGroupFailure({error}))),
        );
      }),
    ),
  );

  // '[Shared][Advanced Filter Groups] Save Selected Advanced Filter Group Success',
  saveSelectedAdvancedFilterGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.saveSelectedAdvancedFilterGroupSuccess),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStore))),
      mergeMap(([{id}, store]) => {
        return of(SharedActions.getAdvancedFilterGroupById({id, store}));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private collectionStoresSettingsService: CollectionStoresSettingsService,
    private readonly store: Store,
  ) {
  }
}
