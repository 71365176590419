<div class="cell-div">
  <div (click)="onLockIconClick()">
    <div *ngIf="params.data.disabled">
      <app-icon-lock-closed-outline></app-icon-lock-closed-outline>
    </div>
    <div *ngIf="!params.data.disabled">
      <app-icon-lock-open-outline></app-icon-lock-open-outline>
    </div>
  </div>
  <div>
    {{params.value}}
  </div>
</div>

