import {KeyboardBinding} from '../../models-old/general/keyboard-binding';


export enum KeyboardBindingsAvailable {
  cancel = 'cancel',
  backspace = 'backspace',
  tab = 'tab',
  clear = 'clear',
  enter = 'enter',
  shiftLeft = 'shiftLeft',
  shiftRight = 'shiftRight',
  controlLeft = 'controlLeft',
  controlRight = 'controlRight',
  altLeft = 'altLeft',
  altRight = 'altRight',
  pause = 'pause',
  capsLock = 'capsLock',
  escape = 'escape',
  space = 'space',
  pageUp = 'pageUp',
  pageDown = 'pageDown',
  end = 'end',
  home = 'home',
  leftArrow = 'leftArrow',
  upArrow = 'upArrow',
  rightArrow = 'rightArrow',
  downArrow = 'downArrow',
  insert = 'insert',
  delete = 'delete',
  digit0 = 'digit0',
  digit1 = 'digit1',
  digit2 = 'digit2',
  digit3 = 'digit3',
  digit4 = 'digit4',
  digit5 = 'digit5',
  digit6 = 'digit6',
  digit7 = 'digit7',
  digit8 = 'digit8',
  digit9 = 'digit9',
  a = 'a',
  b = 'b',
  c = 'c',
  d = 'd',
  e = 'e',
  f = 'f',
  g = 'g',
  h = 'h',
  i = 'i',
  j = 'j',
  k = 'k',
  l = 'l',
  m = 'm',
  n = 'n',
  o = 'o',
  p = 'p',
  q = 'q',
  r = 'r',
  s = 's',
  t = 't',
  u = 'u',
  v = 'v',
  w = 'w',
  x = 'x',
  y = 'y',
  z = 'z',
  leftWindowKey = 'leftWindowKey',
  rightWindowKey = 'rightWindowKey',
  selectKey = 'selectKey',
  numpad0 = 'numpad0',
  numpad1 = 'numpad1',
  numpad2 = 'numpad2',
  numpad3 = 'numpad3',
  numpad4 = 'numpad4',
  numpad5 = 'numpad5',
  numpad6 = 'numpad6',
  numpad7 = 'numpad7',
  numpad8 = 'numpad8',
  numpad9 = 'numpad9',
  multiply = 'multiply',
  add = 'add',
  subtract = 'subtract',
  decimalPoint = 'decimalPoint',
  divide = 'divide',
  f1 = 'f1',
  f2 = 'f2',
  f3 = 'f3',
  f4 = 'f4',
  f5 = 'f5',
  f6 = 'f6',
  f7 = 'f7',
  f8 = 'f8',
  f9 = 'f9',
  f10 = 'f10',
  f11 = 'f11',
  f12 = 'f12',
  f13 = 'f13',
  f14 = 'f14',
  f15 = 'f15',
  f16 = 'f16',
  f17 = 'f17',
  f18 = 'f18',
  f19 = 'f19',
  f20 = 'f20',
  f21 = 'f21',
  f22 = 'f22',
  f23 = 'f23',
  f24 = 'f24',
  semicolon = 'semicolon',
  equalSign = 'equalSign',
  comma = 'comma',
  minus = 'minus',
  period = 'period',
  slash = 'slash',
  backQuote = 'backQuote',
  bracketLeft = 'bracketLeft',
  backSlash = 'backSlash',
  bracketRight = 'bracketRight',
  quote = 'quote',
  altGraph = 'altGraph',
  props = 'props',
  numLock = 'numLock',
  scrollLock = 'scrollLock'
}

/**
 * An object containing usable keyboard bindings.
 *
 * @example
 * keyboardBindings: {
 *   backspace: {
 *    key: 'Backspace',
 *    code: 'Backspace',
 *    keyCode: 8,
 *    unicode: '⌫',
 *    desc: 'Backspace / delete'
 *   };
 * }
 *
 */
export const keyboardBindings: {
  [key: string]: KeyboardBinding;
} = {
  cancel: {key: 'Cancel', code: 'Pause', keyCode: 3, desc: 'Break operation'},
  backspace: {key: 'Backspace', code: 'Backspace', keyCode: 8, unicode: '⌫', desc: 'Backspace / delete'},
  tab: {key: 'Tab', code: 'Tab', keyCode: 9, unicode: '↹', desc: 'Tab key'},
  clear: {key: 'Clear', code: 'NumLock', keyCode: 12, desc: 'Clear input field'},
  enter: {key: 'Enter', code: 'Enter', keyCode: 13, unicode: '↵', desc: 'Enter / Return'},
  shiftLeft: {key: 'Shift', code: 'ShiftLeft', keyCode: 16, desc: 'Shift (left)'},
  shiftRight: {key: 'Shift', code: 'ShiftRight', keyCode: 16, desc: 'Shift (right)'},
  controlLeft: {key: 'Control', code: 'ControlLeft', keyCode: 17, desc: 'Control (left)'},
  controlRight: {key: 'Control', code: 'ControlRight', keyCode: 17, desc: 'Control (right)'},
  altLeft: {key: 'Alt', code: 'AltLeft', keyCode: 18, unicode: '⎇ / ⌥', desc: 'Alt / Option (left)'},
  altRight: {key: 'Alt', code: 'AltRight', keyCode: 18, unicode: '⎇ / ⌥', desc: 'Alt / Option (right)'},
  pause: {key: 'Pause', code: 'Pause', keyCode: 19, desc: 'Pause / Break'},
  capsLock: {key: 'CapsLock', code: 'CapsLock', keyCode: 20, unicode: '⇪', desc: 'Caps lock'},
  escape: {key: 'Escape', code: 'Escape', keyCode: 27, unicode: '⎋', desc: 'Escape key'},
  space: {key: 'Space', code: 'Space', keyCode: 32, desc: 'Space bar'},
  pageUp: {key: 'PageUp', code: 'PageUp', keyCode: 33, desc: 'Page up'},
  pageDown: {key: 'PageDown', code: 'PageDown', keyCode: 34, desc: 'Page down'},
  end: {key: 'End', code: 'End', keyCode: 35, desc: 'End key'},
  home: {key: 'Home', code: 'Home', keyCode: 36, desc: 'Home key'},
  leftArrow: {key: 'ArrowLeft', code: 'ArrowLeft', keyCode: 37, unicode: '←', desc: 'Left arrow'},
  upArrow: {key: 'ArrowUp', code: 'ArrowUp', keyCode: 38, unicode: '↑', desc: 'Up arrow'},
  rightArrow: {key: 'ArrowRight', code: 'ArrowRight', keyCode: 39, unicode: '→', desc: 'Right arrow'},
  downArrow: {key: 'ArrowDown', code: 'ArrowDown', keyCode: 40, unicode: '↓', desc: 'Down arrow'},
  insert: {key: 'Insert', code: 'Insert', keyCode: 45, desc: 'Insert key'},
  delete: {key: 'Delete', code: 'Delete', keyCode: 46, desc: 'Delete key'},
  digit0: {key: '0', code: 'Digit0', keyCode: 48, desc: '0 key'},
  digit1: {key: '1', code: 'Digit1', keyCode: 49, desc: '1 key'},
  digit2: {key: '2', code: 'Digit2', keyCode: 50, desc: '2 key'},
  digit3: {key: '3', code: 'Digit3', keyCode: 51, desc: '3 key'},
  digit4: {key: '4', code: 'Digit4', keyCode: 52, desc: '4 key'},
  digit5: {key: '5', code: 'Digit5', keyCode: 53, desc: '5 key'},
  digit6: {key: '6', code: 'Digit6', keyCode: 54, desc: '6 key'},
  digit7: {key: '7', code: 'Digit7', keyCode: 55, desc: '7 key'},
  digit8: {key: '8', code: 'Digit8', keyCode: 56, desc: '8 key'},
  digit9: {key: '9', code: 'Digit9', keyCode: 57, desc: '9 key'},
  a: {key: 'A', code: 'KeyA', keyCode: 65, desc: 'A key'},
  b: {key: 'B', code: 'KeyB', keyCode: 66, desc: 'B key'},
  c: {key: 'C', code: 'KeyC', keyCode: 67, desc: 'C key'},
  d: {key: 'D', code: 'KeyD', keyCode: 68, desc: 'D key'},
  e: {key: 'E', code: 'KeyE', keyCode: 69, desc: 'E key'},
  f: {key: 'F', code: 'KeyF', keyCode: 70, desc: 'F key'},
  g: {key: 'G', code: 'KeyG', keyCode: 71, desc: 'G key'},
  h: {key: 'H', code: 'KeyH', keyCode: 72, desc: 'H key'},
  i: {key: 'I', code: 'KeyI', keyCode: 73, desc: 'I key'},
  j: {key: 'J', code: 'KeyJ', keyCode: 74, desc: 'J key'},
  k: {key: 'K', code: 'KeyK', keyCode: 75, desc: 'K key'},
  l: {key: 'L', code: 'KeyL', keyCode: 76, desc: 'L key'},
  m: {key: 'M', code: 'KeyM', keyCode: 77, desc: 'M key'},
  n: {key: 'N', code: 'KeyN', keyCode: 78, desc: 'N key'},
  o: {key: 'O', code: 'KeyO', keyCode: 79, desc: 'O key'},
  p: {key: 'P', code: 'KeyP', keyCode: 80, desc: 'P key'},
  q: {key: 'Q', code: 'KeyQ', keyCode: 81, desc: 'Q key'},
  r: {key: 'R', code: 'KeyR', keyCode: 82, desc: 'R key'},
  s: {key: 'S', code: 'KeyS', keyCode: 83, desc: 'S key'},
  t: {key: 'T', code: 'KeyT', keyCode: 84, desc: 'T key'},
  u: {key: 'U', code: 'KeyU', keyCode: 85, desc: 'U key'},
  v: {key: 'V', code: 'KeyV', keyCode: 86, desc: 'V key'},
  w: {key: 'W', code: 'KeyW', keyCode: 87, desc: 'W key'},
  x: {key: 'X', code: 'KeyX', keyCode: 88, desc: 'X key'},
  y: {key: 'Y', code: 'KeyY', keyCode: 89, desc: 'Y key'},
  z: {key: 'Z', code: 'KeyZ', keyCode: 90, desc: 'Z key'},
  leftWindowKey: {key: 'Meta', code: 'MetaLeft', keyCode: 91, desc: 'Left Windows key'},
  rightWindowKey: {key: 'Meta', code: 'MetaRight', keyCode: 92, desc: 'Right Windows key'},
  selectKey: {key: 'Select', code: 'Select', keyCode: 93, desc: 'Select key'},
  numpad0: {key: '0', code: 'Numpad0', keyCode: 96, desc: 'Numpad 0'},
  numpad1: {key: '1', code: 'Numpad1', keyCode: 97, desc: 'Numpad 1'},
  numpad2: {key: '2', code: 'Numpad2', keyCode: 98, desc: 'Numpad 2'},
  numpad3: {key: '3', code: 'Numpad3', keyCode: 99, desc: 'Numpad 3'},
  numpad4: {key: '4', code: 'Numpad4', keyCode: 100, desc: 'Numpad 4'},
  numpad5: {key: '5', code: 'Numpad5', keyCode: 101, desc: 'Numpad 5'},
  numpad6: {key: '6', code: 'Numpad6', keyCode: 102, desc: 'Numpad 6'},
  numpad7: {key: '7', code: 'Numpad7', keyCode: 103, desc: 'Numpad 7'},
  numpad8: {key: '8', code: 'Numpad8', keyCode: 104, desc: 'Numpad 8'},
  numpad9: {key: '9', code: 'Numpad9', keyCode: 105, desc: 'Numpad 9'},
  multiply: {key: '*', code: 'NumpadMultiply', keyCode: 106, desc: 'Multiply'},
  add: {key: '+', code: 'NumpadAdd', keyCode: 107, desc: 'Add'},
  subtract: {key: '-', code: 'NumpadSubtract', keyCode: 109, desc: 'Subtract'},
  decimalPoint: {key: '.', code: 'NumpadDecimal', keyCode: 110, desc: 'Decimal point'},
  divide: {key: '/', code: 'NumpadDivide', keyCode: 111, desc: 'Divide'},
  f1: {key: 'F1', code: 'F1', keyCode: 112, desc: 'F1'},
  f2: {key: 'F2', code: 'F2', keyCode: 113, desc: 'F2'},
  f3: {key: 'F3', code: 'F3', keyCode: 114, desc: 'F3'},
  f4: {key: 'F4', code: 'F4', keyCode: 115, desc: 'F4'},
  f5: {key: 'F5', code: 'F5', keyCode: 116, desc: 'F5'},
  f6: {key: 'F6', code: 'F6', keyCode: 117, desc: 'F6'},
  f7: {key: 'F7', code: 'F7', keyCode: 118, desc: 'F7'},
  f8: {key: 'F8', code: 'F8', keyCode: 119, desc: 'F8'},
  f9: {key: 'F9', code: 'F9', keyCode: 120, desc: 'F9'},
  f10: {key: 'F10', code: 'F10', keyCode: 121, desc: 'F10'},
  f11: {key: 'F11', code: 'F11', keyCode: 122, desc: 'F11'},
  f12: {key: 'F12', code: 'F12', keyCode: 123, desc: 'F12'},
  f13: {key: 'F13', code: 'F13', keyCode: 124, desc: 'F13'},
  f14: {key: 'F14', code: 'F14', keyCode: 125, desc: 'F14'},
  f15: {key: 'F15', code: 'F15', keyCode: 126, desc: 'F15'},
  f16: {key: 'F16', code: 'F16', keyCode: 127, desc: 'F16'},
  f17: {key: 'F17', code: 'F17', keyCode: 128, desc: 'F17'},
  f18: {key: 'F18', code: 'F18', keyCode: 129, desc: 'F18'},
  f19: {key: 'F19', code: 'F19', keyCode: 130, desc: 'F19'},
  f20: {key: 'F20', code: 'F20', keyCode: 131, desc: 'F20'},
  f21: {key: 'F21', code: 'F21', keyCode: 132, desc: 'F21'},
  f22: {key: 'F22', code: 'F22', keyCode: 133, desc: 'F22'},
  f23: {key: 'F23', code: 'F23', keyCode: 134, desc: 'F23'},
  f24: {key: 'F24', code: 'F24', keyCode: 135, desc: 'F24'},
  semicolon: {key: ';', code: 'Semicolon', keyCode: 186, desc: 'Semicolon'},
  equalSign: {key: '=', code: 'Equal', keyCode: 187, desc: 'Equal sign'},
  comma: {key: ',', code: 'Comma', keyCode: 188, desc: 'Comma'},
  minus: {key: '-', code: 'Minus', keyCode: 189, desc: 'Minus'},
  period: {key: '.', code: 'Period', keyCode: 190, desc: 'Period'},
  slash: {key: '/', code: 'Slash', keyCode: 191, desc: 'Forward slash'},
  backQuote: {key: '`', code: 'Backquote', keyCode: 192, desc: 'Grave accent'},
  bracketLeft: {key: '[', code: 'BracketLeft', keyCode: 219, desc: 'Open bracket'},
  backSlash: {key: '\\', code: 'Backslash', keyCode: 220, desc: 'Backslash'},
  bracketRight: {key: ']', code: 'BracketRight', keyCode: 221, desc: 'Close bracket'},
  quote: {key: '\'', code: 'Quote', keyCode: 222, desc: 'Single quote'},
  numLock: {key: 'NumLock', code: 'NumLock', keyCode: 144, desc: 'Num Lock'},
  scrollLock: {key: 'ScrollLock', code: 'ScrollLock', keyCode: 145, desc: 'Scroll Lock'},
};
