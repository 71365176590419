<svg
  width="1481"
  height="1174"
  viewBox="0 0 1481 1174"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [ngClass]="isSidebar ? 'sidebar-svg-class' : 'non-sidebar-svg-class'">
  <g>
    <path
      d="M1020.51 541.085L555.221 1172H1230.46C1292.72 1172 1351.01 1141.4 1386.36 1090.16L1818.28 464.101H1172.94C1112.79 464.101 1056.21 492.674 1020.51 541.085Z"
      fill="url(#paint0_linear_5476_45929)"
      fill-opacity="0.15"/>
    <path
      d="M1020.51 541.085L555.221 1172H1230.46C1292.72 1172 1351.01 1141.4 1386.36 1090.16L1818.28 464.101H1172.94C1112.79 464.101 1056.21 492.674 1020.51 541.085Z"
      stroke="url(#paint1_linear_5476_45929)"
      stroke-width="2.93734"/>
  </g>
  <g>
    <path
      d="M618.833 76.868L3 910.577H901.728C963.937 910.577 1022.18 880.028 1057.55 828.848L1630.29 0H771.177C711.076 0 654.542 28.5253 618.833 76.868Z"
      fill="url(#paint2_linear_5476_45929)"
      fill-opacity="0.1"/>
    <path
      d="M618.833 76.868L3 910.577H901.728C963.937 910.577 1022.18 880.028 1057.55 828.848L1630.29 0H771.177C711.076 0 654.542 28.5253 618.833 76.868Z"
      stroke="url(#paint3_linear_5476_45929)"
      stroke-width="2.93734"/>
  </g>
  <defs>
    <linearGradient
      id="paint0_linear_5476_45929"
      x1="1512.79" y1="283.454" x2="853.361" y2="1343.83"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    <linearGradient
      id="paint1_linear_5476_45929"
      x1="1729.47" y1="343.481" x2="717.446" y2="1170.07"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient
      id="paint2_linear_5476_45929"
      x1="1039.88" y1="32.3109" x2="490.599" y2="910.576"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    <linearGradient
      id="paint3_linear_5476_45929"
      x1="1515.87" y1="-155.154" x2="213.681" y2="910.139"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white" stop-opacity="0.1"/>
    </linearGradient>
  </defs>
</svg>
