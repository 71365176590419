<app-modal-header title="User Profile" [close]="onModalClose"></app-modal-header>
<ion-content>
  <div class="wrapper">
    <div class="user-card">
      <div class="username-container">
        <div class="current-username" *ngIf="!isEditingUsername">
          <h3>{{ username }}</h3>
          <ion-icon
            name="pencil-outline"
            (click)="onEditUserNameClick()"
            [matTooltip]="'Change Username'">
          </ion-icon>
        </div>
        <form
          *ngIf="isEditingUsername"
          [formGroup]="usernameFormGroup"
          (ngSubmit)="saveUsername()"
          class="edit-form">
          <ion-item>
            <ion-label position="floating">New Username</ion-label>
            <ion-input formControlName="username" type="text"></ion-input>
          </ion-item>
          <div class="button-group">
            <ion-icon
              name="checkmark-done-outline"
              (click)="onChangeUserNameClick()"
              [hidden]="usernameFormGroup.invalid"
              [matTooltip]="'Save'">
            </ion-icon>
            <ion-icon
              name="close-circle-outline"
              (click)="isEditingUsername = false"
              [matTooltip]="'Cancel'">
            </ion-icon>
          </div>
        </form>
      </div>
      <div class="profile-picture-container">
        <div class="picture-wrapper">
          <ng-container *ngIf="profilePictureUrl; else noPicture">
            <img
              [src]="profilePictureUrl"
              alt="Profile Picture"
              class="profile-picture">
          </ng-container>
          <ng-template #noPicture>
            <div class="initial-avatar">{{ getUserNameInitials() }}</div>
          </ng-template>
          <ion-icon
            name="pencil-outline"
            (click)="onEditProfilePictureClick()"
            [matTooltip]="'Change Profile Picture'">
          </ion-icon>
          <input
            type="file"
            id="profilePictureInput"
            (change)="onProfilePictureChange($event)"
            hidden>
        </div>
      </div>
      <div class="reset-password">
        <ion-button
          expand="block"
          color="primary"
          (click)="onResetPwdClick()">
          Reset Password
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
